import React from 'react';
import {useDrop} from "react-dnd";
import {Link} from "react-router-dom";
import DraggableTag from "./DraggableTag";

const DroppableCategory = ({category, displayModal, selected, setSelected, rerender, setLoading}) => {

  const [{canDrop, isOver}, drop] = useDrop({
    accept: "keyWord",
    drop: () => ({dropCategoryId: category.id}),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = canDrop && isOver
  let backgroundColor = '#fff'
  if (isActive) {
    backgroundColor = '#ddd'
  } else if (canDrop) {
    backgroundColor = '#eee'
  }

  return (
    <div
      ref={drop}
      style={{backgroundColor, position: "relative", padding: '20px 5px', margin: '10px 0'}}
    >
      {
        category.geoLoc ? <Link to={`/category/${category.id}`}>afficher page</Link>
          :
          !!category.keyWords.length ?
            category.keyWords.map(k => (
              <DraggableTag
                key={k.id}
                keyWord={k}
                displayModal={keyWord => displayModal(category, keyWord)}
                selected={selected}
                setSelected={setSelected}
                rerender={rerender}
                setLoading={setLoading}
              />
            ))
            : <em>Vide</em>
      }
    </div>
  );
};

export default DroppableCategory;

export const billingInfoList = ["Pas de SEO annuel", "4 pages par an", "6 pages par an", "8 pages par an", "MEP - pas de SEO annuel", "VIP", "Autre"]

export function stringDate(date) {
  let toStr = new Date(date)
  return toStr.toLocaleString('fr-FR');
}

export function stringDateDMY(inputFormat) {
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}

export function filterStringDate(inputFormat) {
  if (inputFormat)
    var d = new Date(inputFormat)
  else
    var d = new Date()

  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

export function stringDateDMonthY(inputFormat) {
  const month = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
  var d = new Date(inputFormat)
  return [pad(d.getDate()), month[d.getMonth()], d.getFullYear()].join(' ')
}

function pad(s) {
  return (s < 10) ? '0' + s : s;
}

export function stringDateMY(inputFormat) {
  const month = ['Jan', 'Févr', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'];
  var d = new Date(inputFormat)
  return [month[d.getMonth()], d.getFullYear()].join('-')
}

export function copy(txt) {
  var targetId = "_hiddenCopyText_";

  if (!target) {
    var target = document.createElement("textarea");
    target.style.position = "absolute";
    target.style.left = "-9999px";
    target.style.top = "0";
    target.id = targetId;
    document.body.appendChild(target);
  }
  target.textContent = txt
  // select the content
  var currentFocus = document.activeElement;
  target.focus();
  target.setSelectionRange(0, target.value.length);

  // copy the selection
  var succeed;
  try {
    succeed = document.execCommand("copy");
  } catch (e) {
    succeed = false;
  }
  // restore original focus
  if (currentFocus && typeof currentFocus.focus === "function") {
    currentFocus.focus();
  }

  // clear temporary content
  target.textContent = "";

  return succeed;
}

export function encryptId(id) {
  return Number(id) * 7831;
}

export function decryptId(enc) {
  return Number(enc) / 7831;
}

export function sluggify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

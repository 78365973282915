import React, {useEffect, useState} from "react";
import FavoriteCard from "./FavoriteCard";
import {useDrop} from "react-dnd";
import ModalAddCombination from "../Modal/ModalAddCombination";
import {useSEOContext} from "../../providers/SEOProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {message, Space, Tooltip} from "antd";
import {BarChartOutlined} from "@ant-design/icons";
import {useAccountContext} from "../../providers/AccountProvider";

export default function FavoriteBox({favorites, status, bulkEditing}) {

  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource, apiPostSerp} = apiDispatch;
  const [seoState, seoDispatch] = useSEOContext();
  const {reload} = seoDispatch;
  const [accountState] = useAccountContext();
  const {account} = accountState;
  const params = useParams();

  const [tasks, setTasks] = useState([]);
  const title = getTitle();

  useEffect(() => {
    if (status === 'nextToUse')
      fetchTask();
  }, [])

  function fetchTask() {
    const data = {
      id: params.id,
      filters: [
        {name: 'exists[parent]', value: false},
        {name: 'isOK', value: false},
        {name: 'description', value: 'intervention'}
      ]
    };
    apiFetchSubResource('establishments', data, 'tasks', response => {
      setTasks(response['hydra:member']);
    })
  }

  const [{canDrop, isOver}, drop] = useDrop({
    accept: "favorite",
    drop: () => ({type: "sort", sortTo: status}),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = canDrop && isOver;
  let backgroundColor = "#ddd";
  if (isActive)
    backgroundColor = '#ccc';
  else if (canDrop)
    backgroundColor = '#bbb';

  function getTitle() {
    let result = "";

    switch (status) {
      case "classic":
        result = "Combinaison(s) favorite(s)";
        break;
      case "nextToUse":
        result = "Prochain a utiliser";
        break;
      case "notUse":
        result = "A ne pas utiliser";
        break;
    }

    return result;
  }

  async function launchSerp() {
    if (!account.url || account.url < 10)
      return message.error("L'URL du compte doit être défini. Aller dans la fiche et editez l'URL")

    const data = favorites.map(favorite => {
      return {
        "language_code": "fr",
        "keyword": favorite.combination.result,
        "location_name": "France",
        "postback_url": `${process.env.API_ENTRYPOINT}/api/public/postbackscript`,
        "postback_data": "regular",
        "tag": favorite.id,
        "device": "desktop",
        "os": "windows"
      }
    })
    const response = await apiPostSerp(data);
    if (response.status_code === 20000)
      message.success("Tache envoyé les resultats remonteront d'ici quelques minutes ...")
    else
      message.error("Une erreur est survenue. Réessayez puis contactez l'équipe technique si cela persite")
  }

  return (
    <div ref={drop} className="trello-box" style={{backgroundColor}}>
      <div className="trello-box-title flex space-between">
        <div>{title}</div>
        <Space>
          {status === "classic" ? <ModalAddCombination reload={reload} language={seoState.language}/> : null}
          {favorites.length ?
            <Tooltip title="Lancer une analyse SEO (SERP) sur cette colonne">
              <BarChartOutlined onClick={launchSerp}/>
            </Tooltip> : null}
        </Space>
      </div>
      {favorites.map(favorite => (
        <FavoriteCard favorite={favorite} key={favorite.id} tasks={tasks} status={status} bulkEditing={bulkEditing}/>
      ))}
    </div>
  )
}

import React from 'react';
import {Tag} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {useAuthContext} from "../../providers/AuthProvider";
import {useDrag} from "react-dnd";
import {useApiContext} from "../../providers/ApiProvider";

const DraggableTag = ({keyWord, displayModal, selected, setSelected, rerender, setLoading}) => {

  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;
  const [authState] = useAuthContext();
  const {auth} = authState;
  const isAdmin = auth.roles.includes("ROLE_ADMIN");

  const [{isDragging}, drag] = useDrag({
    item: {id: keyWord.id, type: "keyWord"},
    begin: (monitor) => {
      if (!selected.has(keyWord.id))
        setSelected(new Set([keyWord.id]))
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (dropResult?.dropCategoryId)
        updateKeyWord([...selected], dropResult.dropCategoryId)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  function updateKeyWord(keyWordIds, categoryId) {
    const data = {categoryId, keyWordIds}
    setLoading(true)
    apiPostEntity('update-keywords-category', data, response => {
      if (response.success) {
        rerender();
        setSelected(new Set())
      } else {
        setLoading(false)
      }
    })
  }

  function toggleSelected() {
    setSelected((prev) => {
      const next = new Set(prev);
      if (next.has(keyWord.id))
        next.delete(keyWord.id)
      else
        next.add(keyWord.id)
      return next;
    });
  }

  return (
    <Tag
      onClick={toggleSelected}
      color="geekblue"
      ref={drag}
      data-key={keyWord.id}
      className={selected.has(keyWord.id) ? 'keyword-selected selectable-asset margin-5 cursor-p' : 'margin-5 selectable-asset cursor-p'}
    >
      {keyWord.name}&nbsp;
      {isAdmin && <EditOutlined onClick={() => displayModal(keyWord)}/>}
    </Tag>
  );
};

export default DraggableTag;

import React, {useEffect} from "react";
import {useApiContext} from "../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {SEOProvider} from "../providers/SEOProvider";
import SEOManager from "../components/SEOFollowUp/SEOManager";
import {useAccountContext} from "../providers/AccountProvider";
import {Tabs} from "antd";
import SerpOverview from "../components/SEOFollowUp/serp/SerpOverview";

export default function SEOFollowUp() {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {accountSet} = accountDispatch;


  useEffect(() => {
    fetchEtb()
  }, [])

  function fetchEtb() {
    apiFetchEntity("establishments", params.id, response => {
      accountSet(response);
    })
  }

  return (
    <div>
      <SEOProvider>
        <Tabs>
          <Tabs.TabPane key="1" tab="Suivis SEO">
            <SEOManager/>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="SERP">
            <SerpOverview/>
          </Tabs.TabPane>
        </Tabs>
      </SEOProvider>
    </div>
  )
}

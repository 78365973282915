import React, {useState, useRef, useEffect} from 'react'
import ApiCollectionTable from "../components/ApiCollectionTable.js"
import {useAuthContext} from '../providers/AuthProvider.js';
import {useApiContext} from '../providers/ApiProvider.js';
import {Input, Tag, Button, message, Modal} from 'antd';
import {PlusOutlined, DeleteTwoTone, EditTwoTone} from '@ant-design/icons';
import ModalCreateUser from "../components/Modal/ModalCreateUser"

const {Search} = Input;
const {confirm} = Modal;

export default function Users() {

  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiDeleteEntity} = apiDispatch;

  const inputSearch = useRef(null)
  const initialFilters = [{name: 'email', value: ''}]
  const [filters, setFilters] = useState(initialFilters)
  const [visible, setVisible] = useState(false)
  const [needUpdate, setNeedUpdate] = useState(0)

  const [user, setUser] = useState(null)

  useEffect(() => {
    if (!visible)
      setUser(null);
  }, [visible])

  function remove(id) {
    apiDeleteEntity("users", id, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }

  function displayPrompt(id) {
    const response = prompt('Ou peut-on avoir a faire a du CO2 compressé ?')
    if (response.toLowerCase().trim() === 'plage' || response.toLowerCase().trim() === 'la plage') {
      showDeleteConfirm(id)
      return message.success('Salutation camarade')
    }
    message.error('Instruisez vous plus a l\'avenir')
  }

  function showDeleteConfirm(id) {
    confirm({
      title: 'Etes vous sur de vouloir supprimer cet utilisateur ?',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        remove(id)
      },
      onCancel() {
        message.info('Sage décision');
      },
    });
  }

  const columns = [
    {
      title: "Email",
      dataIndex: 'email',
      render: (email) => email
    },
    {
      title: "Nom d'utilisateur",
      dataIndex: 'username',
      render: (username) => username
    },
    {
      title: "Role",
      dataIndex: 'roles',
      render: (roles) => <span>{getRole(roles)}</span>
    },
    {
      title: "Action",
      dataIndex: 'id',
      render: (id, reccord) => (
        <span>
          {auth.roles.includes("ROLE_SUPER_ADMIN") || auth.id === id ?
            <EditTwoTone onClick={() => displayModal(reccord)}/> : "-"}&nbsp;&nbsp;&nbsp;&nbsp;
          {auth.roles.includes("ROLE_SUPER_ADMIN") && auth.id !== id ?
            <DeleteTwoTone onClick={() => displayPrompt(id)} twoToneColor="#eb2f96"/> : "-"}
	      	</span>
      )
    }
  ];

  function getRole(roles) {
    if (roles.includes("ROLE_DEV"))
      return "Développeur";

    if (roles.includes("ROLE_SUPER_ADMIN"))
      return "Super admin";

    if (roles.includes("ROLE_ADMIN"))
      return "Admin";

    return "Utilisateur";
  }

  const handleClose = () => {
    setFilters(initialFilters);
    inputSearch.current.state.value = "";
  }

  const handleSearch = (e) => {
    setFilters([{name: 'email', value: e.trim()}]);
  }

  function displayModal(user = null) {
    if (user)
      setUser(user);

    setVisible(true);
  }

  function rerender() {
    setNeedUpdate(needUpdate + 1);
    setUser(null);
  }

  return (
    <div className="contenu-tab">
      <div className="margin-10 flex space-around">
        <div>
          <span>Recherche : </span>
          <Search
            ref={inputSearch}
            placeholder="Email"
            onSearch={handleSearch}
            style={{width: 200}}
          />
          {
            filters[0].value ?
              <Tag className="margin-5" closable onClose={handleClose}>
                {filters[0].value}
              </Tag>
              : null
          }
        </div>
        <div>
          <Button type="primary" onClick={(e) => displayModal()}><PlusOutlined/>Ajouter</Button>
        </div>
      </div>
      <ApiCollectionTable
        endpoint="users"
        columns={columns}
        filtres={filters}
        reload={needUpdate}
      />
      <ModalCreateUser
        visible={visible}
        setVisible={setVisible}
        rerender={rerender}
        user={user}
      />
    </div>
  )
}

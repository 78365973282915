import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {Spin, Button, Comment, Drawer, Dropdown, Form, Input, List, Menu, message, Modal, Switch, Badge} from "antd";
import {BellTwoTone, MessageOutlined, MoreOutlined} from '@ant-design/icons';
import {useApiContext} from "../../providers/ApiProvider";
import {stringDate} from "../../Helpers";
import {useAuthContext} from "../../providers/AuthProvider";

const {confirm} = Modal;

export default function MessageDrawer() {

  const params = useParams();
  const [authState] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource, apiPostEntity, apiUpdateEntity, apiDeleteEntity} = apiDispatch;
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [updateComment, setUpdateComment] = useState(0)
  const [messages, setMessages] = useState([])
  const [needUpdate, setNeedUpdate] = useState(0)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchComments()
  }, [needUpdate])

  function fetchComments() {
    apiFetchSubResource("establishments", {id: params.id}, "comments", response => {
      setMessages(response["hydra:member"])
      setLoading(false)
    })
  }

  function postComment() {
    let data = form.getFieldsValue()
    setLoading(true)

    if (updateComment) {
      apiUpdateEntity("comments", updateComment, data, response => {
        form.resetFields()
        setUpdateComment(0)
        setNeedUpdate(needUpdate + 1)
      })
    } else {
      data.user = "/api/users/" + auth.id
      data.establishment = "/api/establishments/" + params.id
      apiPostEntity("comments", data, response => {
        form.resetFields()
        setNeedUpdate(needUpdate + 1)
      })
    }
  }

  function hydrateForm(item) {
    setUpdateComment(item.id)
    const data = {
      content: item.content,
      status: item.status
    }
    form.setFieldsValue(data)
  }

  function showDeleteConfirm(id) {
    confirm({
      title: 'Etes vous sur de vouloir supprimer cet element ?',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        remove(id)
      },
      onCancel() {
        message.info('Sage décision');
      },
    });
  }

  function remove(id) {
    apiDeleteEntity("comments", id, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }

  function actionMenu(e, item) {
    switch (e.key) {
      case "edit":
        hydrateForm(item)
        break;
      case "delete":
        showDeleteConfirm(item.id);
        break;
    }
  }

  function makeMenu(item) {
    return (
      <Menu onClick={(e) => actionMenu(e, item)}>
        <Menu.Item key="edit">
          Editer
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="delete">
          <span className="danger">Supprimer</span>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <>
      <Badge count={messages.length}>
        <Button
          type="primary"
          className="m-left-10"
          icon={<MessageOutlined/>}
          onClick={() => setVisible(true)}
        />
      </Badge>

      <Drawer
        title="Messagerie"
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={visible}
        width={500}
      >
          <div className="flex column space-between h-100per">

            <div>
              <Spin spinning={loading}>
              <List
                dataSource={messages.sort((a, b) => ((a.status === b.status) ? 0 : a.status ? -1 : 1) || new Date(b.createdAt) - new Date(a.createdAt))}
                renderItem={item => (
                  <li>
                    <Comment
                      className="pre-wrap"
                      author={
                        item.status ?
                          <span>{item.user.username} <BellTwoTone twoToneColor="#eb2f96"/></span>
                          : <span>{item.user.username}</span>
                      }
                      content={
                        <div>
                          {item.content}
                        </div>
                      }
                      datetime={(
                        <span>
                  {stringDate(item.createdAt)}&nbsp;&nbsp;&nbsp;&nbsp;
                          {auth.id === item.user.id ?
                            <Dropdown overlay={() => makeMenu(item)} trigger={['click']}>
                              <MoreOutlined onClick={(e) => e.preventDefault()}/>
                            </Dropdown> : null}
                </span>
                      )}
                    />
                  </li>
                )}
              />
              </Spin>
            </div>

            <div>
              <Form
                form={form}
                name="comment"
                onFinish={postComment}
                layout="vertical"
              >
                <Form.Item
                  name="content"
                  label="Laisser un commentaire"
                  rules={[
                    {required: true, message: 'Ce champs ne peut etre vide'}
                  ]}
                >
                  <Input.TextArea
                    autoSize={{minRows: 5, maxRows: 20}}
                  />
                </Form.Item>
                <Form.Item
                  name="status"
                  label="Prioritaire"
                  valuePropName="checked"
                >
                  <Switch/>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="button" onClick={() => {
                    form.resetFields()
                    setUpdateComment(0)
                  }}>
                    Effacer
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Valider
                  </Button>
                </Form.Item>
              </Form>
            </div>

          </div>
      </Drawer>
    </>
  )
}

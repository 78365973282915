import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useApiContext} from "../../providers/ApiProvider";
import {Select} from "antd";

export default function CombinationDropdown({value, setValue, update}) {

  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource} = apiDispatch;
  const params = useParams();

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    fetchFavorite();
  }, [update])

  function fetchFavorite() {
    const data = {
      id: params.id,
      filters: [
        {name: "exists[page]", value: false},
        {name: "status[]", value: "classic"},
        {name: "status[]", value: "nextToUse"},
        {name: "exists[task]", value: false}
      ]
    }
    apiFetchSubResource("establishments", data, "favorite_combination_key_words", response => {
      setFavorites(response['hydra:member']);
    })
  }

  return (
    <div>
      <Select
        value={value}
        style={{width: 250}}
        placeholder="Choisissez une combinaison"
        onChange={setValue}
        allowClear
      >
        {favorites.map(favorite => (
          <Select.Option key={favorite.id} value={favorite['@id']}>{favorite.combination.result}</Select.Option>
        ))}
      </Select>
    </div>
  )
}

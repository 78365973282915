import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {message, Tag} from "antd";
import {useAuthContext} from "../../providers/AuthProvider";
import ModalKeyWordSimplify from "../Modal/ModalKeyWordSymplify";

export default function AllKeyWords({needUpdate, reload}) {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiDeleteEntity, apiPostEntity} = apiDispatch;
  const [authState] = useAuthContext();
  const {auth} = authState;

  const isAdmin = auth.roles.includes("ROLE_ADMIN");
  const [keyWords, setKeyWords] = useState([]);

  useEffect(() => {
    fetch();
  }, [needUpdate])

  function fetch() {
    const data = {
      filters: [{
        name: 'etbId',
        value: params.id
      }]
    }

    apiFetchCollection("key_words", data, response => {
      if (response['@type'] === "hydra:Error")
        return message.error(response["hydra:description"]);

      setKeyWords(response['hydra:member']);
    })
  }

  function deleteKeyWord(id) {
    apiDeleteEntity("key_words", id, response => {
      reload()
    })
  }

  function updateKeyWord(data) {
    apiPostEntity("add-keywords-to-etb", data, () => {
      reload()
    })
  }

  function onClose(iri) {
    const currentKeyWord = keyWords.find(keyWord => keyWord['@id'] === iri);
    if (!currentKeyWord.category)
      return deleteKeyWord(currentKeyWord.id)

    const data = {etbId: params.id, keyWordIds: [iri.split('/').pop()]}
    updateKeyWord(data);
  }

  return (
    <div>
      <div className="flex space-between">
        <h4>Tous vos mots clés</h4>
        <ModalKeyWordSimplify reload={reload}/>
      </div>

      <div className="border1 b-radius padding-15 margin-5 min-height-400 flex wrap content-start">
        {keyWords.length ? keyWords.sort((a, b)=> a.name.localeCompare(b.name)).map(keyWord => (
          <Tag
            className="cursor-p margin-5"
            key={keyWord.id}
            closable={isAdmin}
            onClose={() => onClose(keyWord['@id'])}
          >
            {keyWord.name}
          </Tag>
        )) : <span><em>(Vide)</em></span>}
      </div>
    </div>
  )
}

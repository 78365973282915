import React, {useEffect, useState} from 'react'
import {useAuthContext} from '../providers/AuthProvider.js';
import {useApiContext} from '../providers/ApiProvider.js';
import {Form, Input, Button, message} from "antd"
import {
  LoadingOutlined,
} from '@ant-design/icons';
import ModalCreateUser from "../components/Modal/ModalCreateUser"

export default function User() {

  const [authState] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiFetchEntity, apiUpdateEntity} = apiDispatch;

  const [form] = Form.useForm();
  const [user, setUser] = useState(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    apiFetchEntity('users', auth.id, response => setUser(response));
  }

  function handleOk() {
    const data = form.getFieldsValue();
    if (!data.password && !data.confirm && data.username === user.username && data.email === user.email)
      return;

    if (data.password && !data.confirm)
      return message.error('veuillez confirmer votre mot de passe');

    apiUpdateEntity('users', auth.id, data, response => {
      if (response["@type"] === "hydra:Error" && response["hydra:description"].includes("Integrity constraint violation"))
        return message.error("Ce nom d'utilisateur ou email exite déja");

      if (response["@type"] === "hydra:Error")
        return message.error("Une erreur est survenue, réessayé plus tard")

      setUser(response);
    });
  }

  return (
    <div className="contenu-tab">
      <p><em>*** Le mot de passe par default est:</em> mmc/2020</p>
      {user ?
        <div className="flex space-between w-100per">
          <div className="w-30per">
            <Form
              layout="vertical"
              form={form}
              name="etb"
              onFinish={handleOk}
              initialValues={{
                username: user.username,
                email: user.email,
              }}
            >
              <Form.Item
                name="username"
                label="Nom d'utilisateur"
                rules={[
                  {required: true, message: 'Ce champs ne peut etre vide'},
                ]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {required: true, message: 'Ce champs ne peut etre vide'},
                  {type: 'email', message: 'Format email requis'}
                ]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                name="password"
                label="Mot de passe"
                hasFeedback
              >
                <Input.Password autoComplete="new-password"/>
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirmer mot de passe"
                dependencies={['password']}
                hasFeedback
                rules={[
                  ({getFieldValue}) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value)
                        return Promise.resolve();

                      return Promise.reject('Les deux mots de passe ne sont pas indentiques');
                    }
                  })
                ]}
              >
                <Input.Password/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Modifier
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div>
            <Button type="primary" onClick={() => setVisible(true)}>Ajouter</Button>
          </div>
        </div>
        : <LoadingOutlined/>}
      <ModalCreateUser
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  )
}

import React, {useEffect, useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import {stringDateDMY} from "../../Helpers";
import {Link} from "react-router-dom";
import {Button, Input, DatePicker, Empty} from "antd";

const {RangePicker} = DatePicker;

export default function Historic({etbFilter}) {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [historics, setHistorics] = useState([]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [hasMore, setHasMore] = useState(false)

  const initialFilters = [{name: "order[createdAt]", value: "desc"}];
  const [filters, setFilters] = useState(initialFilters)

  useEffect(() => {
    if (page > 1)
      setPage(1);

    fetch();
  }, [filters])

  useEffect(() => {
    if (page > 1)
      fetch(true, page);
  }, [page])

  useEffect(() => {
    if (page > 1)
      setPage(1);

    search("etb", "favorite.establishment.name", etbFilter);
  }, [etbFilter])

  function fetch(concat = false, page = 1) {
    const data = {
      page: page,
      itemsPerPage: itemsPerPage,
      filters: filters
    };

    apiFetchCollection("favorite_lives", data, response => {
      if (concat)
        setHistorics([...historics, ...response["hydra:member"]]);
      else
        setHistorics(response["hydra:member"]);

      setHasMore(page * itemsPerPage < response["hydra:totalItems"]);
    });
  }

  function search(id, name, value) {
    if (!value)
      return setFilters(filters.filter(f => f.id !== id));

    const index = filters.findIndex((filter => filter.id === id));

    if (index === -1)
      return setFilters([...filters, {id: id, name: name, value: value}]);

    filters[index].name = name;
    filters[index].value = value;
    setFilters([...filters]);
  }

  return (
    <div className="m-topbot-20 w-90perw-40per">

      <div>
        <h2>Historique Combinaison</h2>
      </div>

      <div>
        <Input.Search
          className="margin-10"
          style={{width: 250}}
          placeholder="Filtrer par etablissement"
          onSearch={(value) => search("etb", "favorite.establishment.name", value)}
          allowClear
          enterButton
        />
        <RangePicker
          className="margin-10"
          style={{width: 250}}
          placeholder={["debut", "fin"]}
          onChange={(dates, dateStrings) => search("date", `createdAt[after]=${dateStrings[0]}&createdAt[before]`, dateStrings[1])}
        />
      </div>

      <div className="table">
        <div className="row">
          <div className="col head w-40per">
            Mots Clés
          </div>
          <div className="col head w-20per">
            Action
          </div>
          <div className="col head w-20per">
            Date
          </div>
          <div className="col head w-20per">
            Etablissement
          </div>
        </div>
        {historics.length ? historics.map(historic => (
          <div key={historic.id} className="row">
            <div className="col w-40per">
              {historic.favorite.combination.result}
            </div>
            <div className="col w-20per" style={{color: "rgb(0,0,0,0.6)"}}>{historic.action}</div>
            <div className="col w-20per">
              {stringDateDMY(historic.createdAt)}
            </div>
            <div className="col w-20per">
              <Link
                to={`/seo-follow-up/${historic.favorite.establishment.id}`}>{historic.favorite.establishment.name}</Link>
            </div>
          </div>
        )) : <div className="empty"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>}
        {hasMore ?
          <div className="row-has-more w-100per">
            <Button type="link" onClick={() => setPage(page + 1)}>Afficher plus</Button>
          </div> : null}
      </div>

    </div>
  )
}


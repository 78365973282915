import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import {useApiContext} from '../providers/ApiProvider.js';
import KeyWordPlateform from "../components/KeyWords/KeyWordPlareform.js"
import {Tabs, Spin, Popover, Tag} from 'antd';
import ModalCategory from "../components/Modal/ModalCategory";
import {useAuthContext} from "../providers/AuthProvider";
import AllKeyWords from "../components/KeyWords/AllKeyWords";
import {useAccountContext} from "../providers/AccountProvider";

const {TabPane} = Tabs;

export default function EstablishmentKeyWords() {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiFetchEntity, apiPostEntity} = apiDispatch;
  const [authState] = useAuthContext();
  const {auth} = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const {accountSet} = accountDispatch;

  const [activeKey, setActiveKey] = useState("");

  const [establishment, setEstablishment] = useState(null)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [reloadEtb, setReloadEtb] = useState(0)
  const [reloadMyKeyWords, setReloadMyKeyWords] = useState(0)

  const [visible, setVisible] = useState(false)
  const [reloadAll, setReloadAll] = useState(0)
  const [content, setContent] = useState([])
  const [loadingContent, setLoadingContent] = useState(false)

  const isAdmin = auth.roles.includes("ROLE_ADMIN");


  useEffect(() => {
    fetchCategories()
  }, [reloadAll])

  useEffect(() => {
    fetchEstablishment()
  }, [reloadEtb, reloadAll])

  function fetchEstablishment() {
    apiFetchEntity('establishments', params.id, response => {
      accountSet(response);
      setEstablishment(response)
      setLoading(false)
    })
  }

  function fetchCategories() {
    apiFetchCollection('categories/get_name', {}, response => {
      setCategories(response["hydra:member"])
      setActiveKey(String(response['hydra:member'][0].id))
    })
  }

  const popoverContent = (
    <div className="my_popover">
      <Spin spinning={loadingContent}>
        {loadingContent ? "chargement ..." :
          content.sort((a, b) => a.name.localeCompare(b.name)).map(c => (
            <Tag className="margin-5" key={c.id}>{c.name}</Tag>
          ))}
      </Spin>
    </div>
  )

  function dropdownOver(c) {
    setLoadingContent(true)

    let data = {};

    if (c.etbId) {
      data.filters = [
        {
          name: "etbId",
          value: c.etbId
        }
      ]
    }
    if (c.id) {
      data.filters = [
        {
          name: "categoryId",
          value: c.id
        }
      ]
    }

    if (c.geoLoc && c.geoLocFilterType === 1) {
      if (!establishment.location.latitude || !establishment.location.longitude) {
        setLoadingContent(false);
        return setContent([]);
      }

      data.filters.push({
        name: "rayon",
        value: 3
      }, {
        name: "lat",
        value: establishment.location.latitude
      }, {
        name: "lng",
        value: establishment.location.longitude
      })
    }

    if (c.geoLoc && c.geoLocFilterType === 2) {
      data.filters.push({
        name: "city",
        value: encodeURIComponent(establishment.location.city).replace(/'/g, '\'\'')
      })
    }

    apiFetchCollection("key_words", data, response => {
      if (response['hydra:member']) {
        setContent(response['hydra:member'])
        setLoadingContent(false)
      }
    })
  }

  function hardReload() {
    setReloadEtb(reloadEtb + 1);
    setReloadMyKeyWords(reloadMyKeyWords + 1)
  }

  return (
    establishment ?
      <div>
        <Tabs animated={false} activeKey={activeKey} onChange={setActiveKey}>
          {categories.map(c => (
            <TabPane tab={
              <Popover placement="bottom" content={popoverContent} onMouseEnter={() => dropdownOver(c)}>
                <strong>{c.name}</strong>
              </Popover>
            } key={c.id}>
              <div className="contenu-tab">
                <KeyWordPlateform
                  category={c}
                  etb={establishment}
                  reloadEtb={() => setReloadEtb(reloadEtb + 1)}
                  loading={loading}
                  setLoading={setLoading}
                  needUpdate={reloadMyKeyWords}
                  setActiveKey={setActiveKey}
                />
              </div>
            </TabPane>
          ))}
          <TabPane tab={
            <Popover placement="bottom" content={popoverContent} onMouseEnter={() => dropdownOver({etbId: establishment.id})}>
              <strong>Tous</strong>
            </Popover>
          } key="all">
            <div className="contenu-tab">
              <AllKeyWords needUpdate={reloadEtb} reload={hardReload}/>
            </div>
          </TabPane>
        </Tabs>
        <ModalCategory
          visible={visible}
          setVisible={setVisible}
          apiPostEntity={apiPostEntity}
          rerender={() => setReloadAll(reloadAll + 1)}
        />
      </div>
      : null
  )
}

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import {useApiContext} from "../providers/ApiProvider";
import DraggableTree from "../components/DraggableTree";
import {useAccountContext} from "../providers/AccountProvider";

export default function TreePageManager() {

  const [apiDispatch] = useApiContext();
  const {apiFetchEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {accountSet} = accountDispatch;
  const params = useParams();

  const [etb, setEtb] = useState({})

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    apiFetchEntity("establishments", params.id, response => {
      accountSet(response)
      setEtb(response)
    })
  }

  return (
    <div>
      <div className="contenu-tab">
        <h1 className="text-center">Creez votre arbo</h1>
        <div className="w-60per mt-50">
          <DraggableTree/>
        </div>
      </div>
    </div>
  )
}

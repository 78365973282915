import React, {useState} from 'react';
import {message, Modal} from "antd";
import CheckboxCombination from "./CheckboxCombination";
import {useApiContext} from "../../../providers/ApiProvider";
import {useAccountContext} from "../../../providers/AccountProvider";

const ModalResultCombination = ({visible, setVisible, result, combis, onClose = () => {}}) => {

  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;
  const [accountState] = useAccountContext();
  const {account} = accountState;

  const [validateData, setValidateData] = useState([...result]);
  const [loading, setLoading] = useState(false);

  function handleCancel() {
    setValidateData([])
    setVisible(false)
  }

  function updateValidateData(index, value) {
    let _validateData = [...validateData];
    _validateData[index] = value.map(combination => combination.split(' '));
    setValidateData(_validateData);
  }

  function handleOk() {
    setLoading(true)
    const data = {
      etbId: account.id,
      result: validateData,
      combis: combis
    }
    apiPostEntity("save-combination", data, response => {
      message.success("Combiaisons enregistré")
      handleCancel()
      setLoading(false)
      onClose()
    })
  }


  return (
    <Modal
      confirmLoading={loading}
      title="Résultats des combinaisons"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Enregistrer"
      cancelText="Annuler"
    >
      {result.map((category, index) => (
        <div key={index}>
          {result.length > 1 ?
            <h2>Catégorie {index + 1}</h2> : null}
          <CheckboxCombination combinations={category} updateValidateData={value => updateValidateData(index, value)}/>
        </div>
      ))}
    </Modal>
  );
};

export default ModalResultCombination;

import React from "react";
import {useHistory} from "react-router-dom";
import {Badge, Tooltip} from "antd";

export default function TooltipDescription({task}) {

  const history = useHistory();

  function goToEtb() {
    history.push(`/establishment/${task.establishment.id}`);
  }

  const title = (
    <div onClick={goToEtb} className="cursor-p">
      {task.childs.length ? task.childs.map(child => (
        <div key={child.id}>
          <span>{child.description}</span>&nbsp;
          {child.favorite ? <strong>{child.favorite.combination.result}&nbsp;</strong> : null}
          <span style={{backgroundColor: child.status.color}}>{child.status.name}</span>
        </div>
      )) : "Aucune Tâche ..."}
    </div>
  )

  return (
    <Tooltip overlayStyle={{maxWidth: 800}} title={title}>
      <Badge count={task.childs.length} size="small" offset={[5, -5]}>
        <span className="cursor-p" onClick={goToEtb}>{task.description}</span>
      </Badge>
    </Tooltip>
  )
}

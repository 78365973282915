import React from 'react';
import {BrowserRouter} from 'react-router-dom'

import Router from './components/Router.js';

import {AuthProvider} from './providers/AuthProvider';
import {ApiProvider} from './providers/ApiProvider';
import {AccountProvider} from "./providers/AccountProvider";

import "./styles/antd.less";
import "./styles/App.scss";

function App() {
  return (
    <AuthProvider>
      <ApiProvider>
        <AccountProvider>
          <BrowserRouter>
            <Router/>
          </BrowserRouter>
        </AccountProvider>
      </ApiProvider>
    </AuthProvider>
  );
}

export default App

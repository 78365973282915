import React, {useEffect, useRef, useState} from 'react';
import {AutoComplete, Input, InputNumber, Tag} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {createSelectable, SelectableGroup} from 'react-selectable';
import ModalKeyWord from "../Modal/ModalKeyWord"
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import DraggableTag from "./DraggableTag";
import TagDropBox from "./TagDropBox";
import {useApiContext} from "../../providers/ApiProvider"
import Search from "./Search";

const SelectableComponent = createSelectable(DraggableTag);

export default function KeyWordPlareform({category, etb, reloadEtb, loading, setLoading, needUpdate, setActiveKey}) {

  const [apiDispatch] = useApiContext();
  const {
    apiFetchCollection,
    apiFetchSubResource,
    apiPostEntity,
    apiGooglePlaceAutocomplete,
    apiGooglePlaceDetail
  } = apiDispatch;
  const [keyWords, setKeyWords] = useState([])
  const [myKeyWords, setMyKeyWords] = useState([])
  const [updateHook, setUpdateHook] = useState(0)
  const [updateMKW, setUpdateMKW] = useState(0)
  const [rayon, setRayon] = useState(3)

  const [searchKW, setSearchKW] = useState(null)
  const [proposition, setProposition] = useState([])
  const [valueAuto, setValueAuto] = useState("")

  const [visible, setVisible] = useState(false)
  const [toUpdate, setToUpdate] = useState(null)
  const [cityForModal, setCityForModal] = useState("")

  const [tagSelected, setTagSelected] = useState([])
  const [isDraggingGlobal, setIsDraggingGlobal] = useState(false)

  const minRayon = 0
  const maxRayon = 100

  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;

    fetchAllKW()
    return () => {
      isMounted.current = false;
    }
  }, [updateHook])

  useEffect(() => {
    fetchAccountKW()
    return () => {
      isMounted.current = false;
    }
  }, [updateMKW, needUpdate])

  function fetchAllKW() {
    let data = {
      filters: [{
        name: "categoryId", value: category.id
      }]
    }

    if (category.geoLoc && category.geoLocFilterType === 1) {
      data.filters.push({
        name: "rayon", value: rayon
      }, {
        name: "lat", value: etb.location.latitude
      }, {
        name: "lng", value: etb.location.longitude
      })
    }

    if (category.geoLoc && category.geoLocFilterType === 2) {
      data.filters.push({
        name: "city", value: encodeURIComponent(etb.location.city).replace(/'/g, '\'\'')
      })
    }

    apiFetchCollection("key_words", data, response => {
      if (response['hydra:member'].length) {
        setKeyWords(response["hydra:member"])
      }
    })
  }

  function fetchAccountKW() {
    const data = {
      id: etb.id, filters: [{
        name: "category.id", value: category.id
      }]
    }
    apiFetchSubResource("establishments", data, "key_words", response => {
      setMyKeyWords(response["hydra:member"])
    })
  }

  function onChange(value) {
    if (typeof value === "number" && value < maxRayon && value > minRayon) {
      setRayon(value)
      setUpdateHook(updateHook + 1)
    }
  }

  function addKeywordsToEtb(data) {
    apiPostEntity('add-keywords-to-etb', data, response => {
      setTagSelected([])
      setUpdateMKW(updateMKW + 1)
      reloadEtb()
    })
  }

  function updateKeyWordsWithList() {
    if (!loading) {
      setLoading(true)

      const data = {
        etbId: etb.id, keyWordIds: tagSelected.map(tag => tag.split('/').pop()), toggle: false
      }

      addKeywordsToEtb(data);
    }
  }

  function updateKeyWordsWithIri(iri) {
    setLoading(true)

    const data = {
      etbId: etb.id, keyWordIds: [iri.split('/').pop()]
    }

    addKeywordsToEtb(data);
  }

  function reset() {
    if (!loading) {
      setLoading(true);

      let toDel = [];
      keyWords.map(k => {
        if (etb.keyWords.includes(k['@id'])) toDel.push(k.id);
      })

      const data = {
        etbId: etb.id, keyWordIds: toDel
      }

      addKeywordsToEtb(data);
    }
  }

  function displayModal(keyWord = null) {
    if (keyWord) {
      setToUpdate(keyWord)
    }
    if (category.geoLoc && category.geoLocFilterType === 2) {
      setCityForModal(etb.location.city)
    }
    setVisible(true)
  }

  async function findValueOfProperty(q) {
    const p = q.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    let reg = new RegExp(p, "i"); // "i" to make it case insensitive
    return keyWords.filter(k => reg.test(k.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
  }

  /**
   * Si la category n'est pas de type point de localisation précis on check dans les motclé de la categorie deja recupré par fetchKW
   * Si non on recheck dans la bdd pour evité des doublons qui aurait pu etre masqué par le rayon
   *
   * Si on a des resultats on les injecte dans les propositions pour l'autocompletion
   * Sinon si la categorie est géo loc type 1 on fait une recherche dans places
   * Et on rajoute l'attribut new dans le json pour preciser que c'est une creation de mot clés
   *
   * @param q
   */
  function onSearch(q) {
    setProposition([])
    clearTimeout(searchKW)
    setSearchKW(setTimeout(async () => {
      let response;

      if (category.geoLoc && category.geoLocFilterType === 1) {
        const params = {
          id: category.id, filters: [{
            name: "name", value: q
          }]
        }
        await apiFetchSubResource("categories", params, "key_words", result => {
          response = result['hydra:member']
        })
      } else {
        response = await findValueOfProperty(q)
      }

      let predictions;

      if (response.length > 0) {
        predictions = response.map(r => {
          return {
            value: r.name, data: r
          }
        })
      } else {
        if (category.geoLoc && category.geoLocFilterType === 1) {
          const ggResponse = await apiGooglePlaceAutocomplete(q);
          if (ggResponse && ggResponse.status === "OK") {
            predictions = ggResponse.predictions.map(v => {
              return {
                value: v.description, data: "new", key: v.place_id
              }
            })
          }
        } else {
          predictions = [{
            value: q, data: "new"
          }]
        }
      }

      setProposition(predictions)
    }, 500))
  }

  /**
   * On check si c'est c'est un nouveau mot clé
   * Si oui on check si c'est un geoloc type 1, dans ce cas on fait un call google place et injecte dans un post location
   * Ou on Check si geoloc type 2 et on post la ville de l'etb dans location
   * Et on post le nouveau mot clé
   * Sinon on rajoute le mots cle a l'etb
   */
  async function makeChoice(value, data) {
    if (data.data === "new") {
      let dataToPush = {
        establishments: ["/api/establishments/" + etb.id], category: category['@id'], name: value
      }

      if (category.geoLoc && category.geoLocFilterType === 1) {
        if (data.key) {
          let place = await apiGooglePlaceDetail(data.key)
          if (place && place.status === "OK") {
            const result = place.result
            dataToPush.name = result.name
            const dataLoc = {
              adress: result.address,
              city: result.city,
              country: result.country,
              latitude: result.gps[0],
              longitude: result.gps[1],
              postalCode: result.zip,
            }
            await apiPostEntity("locations", dataLoc, response => {
              dataToPush.location = response['@id']
            })
          }
        }
      }

      if (category.geoLoc && category.geoLocFilterType === 2) {
        const dataLoc = {
          city: etb.location.city
        }
        await apiPostEntity("locations", dataLoc, response => {
          dataToPush.location = response['@id']
        })
      }

      apiPostEntity("key_words", dataToPush, response => {
        setProposition([])
        setValueAuto("")
        setUpdateHook(updateHook + 1)
        setUpdateMKW(updateMKW + 1)
        reloadEtb()
      })
    } else {
      updateKeyWordsWithIri(data.data['@id'])
      setProposition([])
      setValueAuto("")
    }
  }

  function onAutoChange(value) {
    setValueAuto(value)
  }

  const autocompletInput = (<AutoComplete
    value={valueAuto}
    onChange={onAutoChange}
    onSelect={(value, b) => makeChoice(value, b)}
    style={{width: 350}}
    onSearch={onSearch}
    options={proposition}
  >
    <Input
      placeholder="Ajouter"
      allowClear={true}
    />
  </AutoComplete>)

  return (
    <div>

      <div className="flex space-between">
        <div>
          {category.geoLoc && category.geoLocFilterType === 1 &&
            <div>
              Rayon <InputNumber min={minRayon} max={maxRayon} value={rayon} onChange={onChange}/> en Km
            </div>}
        </div>
        <div>
          {autocompletInput}
        </div>
        <div>
          <Search setActiveKey={setActiveKey}/>
        </div>
      </div>

      <DndProvider backend={HTML5Backend}>
        <div className="flex w-100per">
          <SelectableGroup onSelection={(value) => setTagSelected(value)} className="w-70per">
            <h2 className="text-center">Mots clés de la base de données</h2>
            <div className="border1 b-radius padding-15 margin-5 min-height-400 flex wrap content-start">
              <div>
                <Tag
                  color="#108ee9"
                  className="cursor-p margin-5"
                  onClick={() => displayModal()}>
                  Créer <PlusOutlined/>
                </Tag>
              </div>
              {keyWords.sort((a, b) => a.name.localeCompare(b.name)).map(k => (<SelectableComponent
                key={k.id}
                style={{display: "inline-block"}}
                selectableKey={k['@id']}
                keyWord={k}
                displayModal={displayModal}
                tagSelected={tagSelected}
                setTagSelected={setTagSelected}
                updateKeyWordsWithList={updateKeyWordsWithList}
                isDraggingGlobal={isDraggingGlobal}
                setIsDraggingGlobal={setIsDraggingGlobal}
                updateKeyWordsWithIri={updateKeyWordsWithIri}
                isIncluded={etb.keyWords.includes(k["@id"])}
              />))}
            </div>
          </SelectableGroup>
          <div className="w-30per">
            <h2 className="text-center">Mots clés liés a l'établissement</h2>
            <TagDropBox loading={loading} myKeyWords={myKeyWords} updateKeyWords={updateKeyWordsWithIri} reset={reset}/>
          </div>
        </div>
      </DndProvider>
      {visible &&
        <ModalKeyWord
          visible={visible}
          setVisible={setVisible}
          withLocation={category.geoLoc}
          geoLocFilterType={category.geoLocFilterType}
          categoryIri={category['@id']}
          toUpdate={toUpdate}
          setToUpdate={setToUpdate}
          rerender={() => setUpdateHook(updateHook + 1)}
          cityFromHook={cityForModal}
          etb={etb}
        />}
    </div>)
}

import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, Modal, Radio, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import moment from "moment";

const ModalMainTask = ({visible, setVisible, reload, item = {}}) => {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiPostEntity, apiUpdateEntity} = apiDispatch;
  const params = useParams();

  const [tags, setTags] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTags();
  }, [])

  function fetchTags() {
    apiFetchCollection("tags", {}, response => {
      setTags(response["hydra:member"]);
    });
  }

  function handleOk() {
    form
      .validateFields()
      .then((values) => {
        if (item.id)
          updateTask(values)
        else
          postTask(values)
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  function updateTask(data) {
    apiUpdateEntity('tasks', item.id, data, response => {
      if (typeof reload === 'function')
        reload()

      form.resetFields()
      setConfirmLoading(false)
      setVisible(false)
    })
  }

  function postTask(data) {
    setConfirmLoading(true)
    data.establishment = `/api/establishments/${params.id}`
    apiPostEntity('tasks', data, response => {
      if (typeof reload === 'function')
        reload()

      form.resetFields()
      setConfirmLoading(false)
      setVisible(false)
    })
  }

  return (
    <>
      <Button onClick={() => setVisible(true)}>Ajouter une Tache</Button>

      {visible &&
        <Modal
          destroyOnClose
          title="Créer une tache principale"
          visible={visible}
          onOk={handleOk}
          onCancel={() => setVisible(false)}
          okText="Enregistrer"
          cancelText="Annuler"
          confirmLoading={confirmLoading}
        >
          <Form
            form={form} layout="vertical"
            initialValues={{
              ref: item.ref || 'intervention',
              description: item.description || '',
              plannedAt: item.plannedAt ? moment(item.plannedAt) : '',
              status: item.status ? item.status['@id'] : ''
            }}
          >

            <Form.Item
              label="Description" name="description"
              rules={[{required: true, message: 'Champs requis !'}]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Type de tache" name="ref"
              rules={[{required: true, message: 'Champs requis !'}]}
            >
              <Radio.Group>
                <Radio value="onlinePublication">Mise en ligne</Radio>
                <Radio value="intervention">Intervention</Radio>
                <Radio value="meeting">Entretien annuel</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Plannifié pour" name="plannedAt"
              rules={[{required: true, message: 'Champs requis !'}]}
            >
              <DatePicker/>
            </Form.Item>

            <Form.Item
              label="Status" name="status"
              rules={[{required: true, message: 'Champs requis !'}]}
            >
              <Select
                showSearch
                placeholder="Selectionner un status"
                optionLabelProp="label"
                optionFilterProp="label"
              >
                {tags.map(tag => (
                  <Select.Option value={tag['@id']} label={tag.name} key={tag.id}>
                    <strong style={{color: tag.color}}>{tag.name}</strong>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>}
    </>
  );
};

export default ModalMainTask;

import React, {useState} from 'react';
import {AutoComplete, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";

const Search = ({setActiveKey}) => {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [search, setSearch] = useState(0)
  const [options, setOptions] = useState([])

  function onSearch(q) {
    setOptions([])
    clearTimeout(search)
    setSearch(setTimeout(async () => {
      if (!q) return;

      let predictions = [];
      const data = {
        filters: [
          {name: 'name', value: q}
        ]
      }
      apiFetchCollection('keywords_by_etb', data, response => {
        if (!response['hydra:member']) return;
        response['hydra:member'].map(keyword => {
          if (!keyword.category) return;
          predictions.push({
            value: `${keyword.name} (${keyword.category.name})`,
            data: keyword.category.id,
            key: keyword.id
          })
        })

        setOptions(predictions)
      })
    }, 500))
  }

  function onSelect(value, values) {
    setActiveKey(String(values.data))
  }

  return (
    <AutoComplete
      onSelect={onSelect}
      style={{width: 350}}
      onSearch={onSearch}
      options={options}
    >
      <Input
        prefix={<SearchOutlined/>}
        placeholder="Rechercher mot clé"
        allowClear={true}
      />
    </AutoComplete>
  );
};

export default Search;

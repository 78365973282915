import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import ApiCollectionTable from "../../components/ApiCollectionTable.js"
import {Badge, Switch} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import {stringDateDMY, stringDateMY} from "../../Helpers.js"
import {useApiContext} from '../../providers/ApiProvider.js';
import {useAuthContext} from "../../providers/AuthProvider";
import ModalRight from "../../components/Modal/ModalRight";

export default function EstablishmentsList({filters}) {

  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;
  const [authState] = useAuthContext();
  const {auth} = authState;

  const [visibleRight, setVisibleRight] = useState(false);
  const [idEtb, setIdEtb] = useState(0)

  const [loading, setLoading] = useState(false);
  const isAdmin = auth.roles.includes("ROLE_SUPER_ADMIN");
  const isAdminDev = auth.roles.includes("ROLE_DEV");

  var columns = [
    {
      title: "Nom",
      dataIndex: 'name',
      render: (name, record) => (
        <span>
          {isAdmin ? (
            <span>
              <LockOutlined onClick={() => modalRight(record.id)}/>&nbsp;
            </span>
          ) : null}
          <Link to={`/seo-follow-up/${record.id}`}>{name}</Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className="font-08"
                to={`/establishment/${record.id}`}><em>voir fiche</em></Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className="font-08" to={`/redirection/${record.id}`}><em>redirection</em></Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className="font-08" to={`/establishment-key-words/${record.id}`}><em>Mots clés</em></Link>
        </span>
      )
    },
    {
      title: "Actif",
      dataIndex: 'isActive',
      render: (isActive, reccord) =>
        <Switch
          defaultChecked={isActive}
          size="small"
          onClick={checked => toggleActive(checked, reccord.id)}
          disabled={loading || !isAdmin}
        />
    },
    {
      title: "Dev",
      dataIndex: 'isDev',
      render: (isDev, reccord) =>
        <Switch
          defaultChecked={isDev}
          size="small"
          onClick={checked => toggleDev(checked, reccord.id)}
          disabled={loading || !isAdminDev}
        />
    },
    {
      title: "Hapi",
      dataIndex: 'hapi',
      render: hapi => hapi ? `v${hapi}` : `Autre`
    },
    {
      title: "Ville",
      dataIndex: 'location',
      render: location => location.city
    },
    {
      title: "SEA",
      dataIndex: 'SEA',
      render: SEA => SEA ? "Oui" : "Non"
    },
    {
      title: "Debut",
      dataIndex: 'begeningAt',
      render: begeningAt => begeningAt ? stringDateDMY(begeningAt) : "-"
    },
    {
      title: "Dernière modif",
      dataIndex: 'changeAt',
      render: changeAt => changeAt ? stringDateMY(changeAt) : "-"
    },
    {
      title: "Facturation",
      dataIndex: 'billingInfos',
      render: billingInfos => billingInfos ? billingInfos : "-"
    },
    {
      title: "Status",
      dataIndex: 'status',
      render: status => status
    },
    {
      title: "Tâche(s) prioritaire(s)",
      dataIndex: 'comments',
      render: comments => <span><Badge count={comments.filter(c => c.status).length}/></span>
    }
  ];

  function modalRight(id) {
    setIdEtb(id)
    setVisibleRight(true)
  }

  function toggleActive(checked, id) {
    setLoading(true);
    apiUpdateEntity("establishments", id, {"isActive": checked}, () => setLoading(false));
  }

  function toggleDev(checked, id) {
    setLoading(true);
    apiUpdateEntity("establishments", id, {"isDev": checked}, () => setLoading(false));
  }

  if (!isAdmin)
    columns = columns.filter(col => col.title !== "Actif");

  if (!isAdminDev)
    columns = columns.filter(col => col.title !== "Dev");

  return (
    <>
      <ApiCollectionTable
        endpoint="establishments"
        columns={columns}
        filtres={filters}
        pageSize={50}
        displayTotal={true}
      />
      <ModalRight
        visible={visibleRight}
        setVisible={setVisibleRight}
        idEtb={idEtb}
      />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useApiContext } from '../providers/ApiProvider.js';
import { Button, List, message, Modal, Space, Spin } from "antd"
import { DeleteTwoTone, EditTwoTone, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ModalContact from "../components/Modal/ModalContact";
import EtbTask from "../components/EtbTask/EtbTask";
import DrawerDescription from "../components/Drawer/DrawerDescription";
import DrawerPause from "../components/Drawer/DrawerPause";
import ActivePause from "../components/Establishment/ActivePause";
import { useAccountContext } from "../providers/AccountProvider";
import ModalAnalytics from "../components/Establishment/ModalAnalytics";

const { confirm } = Modal;

export default function Establishment() {

  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiDeleteEntity, apiUpdateEntity } = apiDispatch;
  const params = useParams();
  const [accountState, accountDispatch] = useAccountContext();
  const { accountSet } = accountDispatch;

  const [etb, setEtb] = useState({})
  const [loading, setLoading] = useState(true)
  const [contactLoading, setContactLoading] = useState(false)
  const [analyticsLoading, setAnalyticsLoading] = useState(false)
  const [needUpdate, setNeedUpdate] = useState(0)

  const [visibleContact, setVisibleContact] = useState(false)
  const [contact, setContact] = useState(null)

  const [itemAnalytics, setItemAnalytics] = useState({})
  const [visibleAnalytics, setVisibleAnalytics] = useState(false);


  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (params.id) {
      fetchEtb()
    }
  }, [needUpdate])

  function fetchEtb() {
    const data = {
      id: params.id,
    }
    apiFetchSubResource("establishments", data, "get_very_all_details", response => {
      accountSet(response)
      setEtb(response)
      setLoading(false)
      setContactLoading(false)
      setAnalyticsLoading(false)
    })
  }

  function showModalContact(contact = null) {
    if (contact) {
      setContact(contact)
    }
    setVisibleContact(true)
  }

  function rerender() {
    setNeedUpdate(needUpdate + 1)
    setContact(null)
  }

  function showDeleteConfirm(id, endpoint) {
    confirm({
      title: 'Etes vous sur de vouloir supprimer cet element ?',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        remove(id, endpoint)
      },
      onCancel() {
        message.info('Sage décision');
      },
    });
  }

  function remove(id, endpoint) {
    setContactLoading(true)
    apiDeleteEntity(endpoint, id, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }

  function editAnalytics(item) {
    setItemAnalytics(item)
    setVisibleAnalytics(true)
  }

  function showDeleteConfirmAanalytics(id) {
    confirm({
      title: 'Etes vous sur de vouloir supprimer cet element ?',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        deleteAnalytics(id)
      },
      onCancel() {
        message.info('Sage décision');
      },
    });
  }

  function deleteAnalytics(id) {
    setAnalyticsLoading(true)
    const _analytics = etb.analytics.filter(a => a.id !== id)
    apiUpdateEntity('establishments', params.id, { analytics: _analytics }, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }

  return (
    <div>
      <div className="contenu-tab">
        {
          loading ? <Spin indicator={antIcon} />
            :
            <div className="flex">

              <div className="w-80per padding-10">

                <div className="flex space-between">
                  <DrawerDescription etb={etb} setEtb={setEtb} reload={() => setNeedUpdate(needUpdate + 1)} />
                  {etb.tasks && etb.tasks.length ?
                    <DrawerPause etb={etb} reload={() => setNeedUpdate(needUpdate + 1)} /> : null}
                </div>

                <div>
                  <ActivePause etb={etb} needUpdate={needUpdate} />
                </div>

                <div>
                  <h3 className="bold">Seo de l'établissement</h3>
                  <EtbTask reload={() => setNeedUpdate(needUpdate + 1)} />
                </div>

              </div>

              <div className="w-20per padding-10">
                <Space direction="vertical" size="large" className="w-100per">
                  <div>
                    <h3 className="bold">Contact</h3>
                    <Button type="primary" onClick={() => showModalContact()}><PlusOutlined />Ajouter</Button>
                    <List>
                      <Spin spinning={contactLoading}>
                        {
                          etb.contact.map(c => (
                            <List.Item key={c.id}>
                              <List.Item.Meta
                                title={(
                                  <span>
                                    {c.name} ({c.role || <em>non renseigné</em>})&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EditTwoTone onClick={() => showModalContact(c)} />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <DeleteTwoTone twoToneColor="#eb2f96"
                                      onClick={() => showDeleteConfirm(c.id, "contacts")} />
                                  </span>
                                )}
                                description={<span>{c.email} / {c.phone || <em>non renseigné</em>}</span>}
                              />
                            </List.Item>
                          ))
                        }
                      </Spin>
                    </List>
                  </div>

                  <div>
                    <h3 className="bold">Analytics</h3>
                    <ModalAnalytics
                      visible={visibleAnalytics}
                      setVisible={setVisibleAnalytics}
                      reload={() => {
                        setNeedUpdate(needUpdate + 1)
                        setAnalyticsLoading(true);
                      }}
                      item={itemAnalytics}
                      analytics={etb.analytics}
                      setItem={setItemAnalytics}
                    />


                    <List>
                      <Spin spinning={analyticsLoading}>
                        {
                          etb.analytics.map(a => (
                            <List.Item key={a.code}>
                              <List.Item.Meta
                                title={(
                                  <span>
                                    {a.name}&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EditTwoTone onClick={() => editAnalytics(a)} />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <DeleteTwoTone twoToneColor="#eb2f96"
                                      onClick={() => showDeleteConfirmAanalytics(a.id)} />
                                  </span>
                                )}
                                description={<span>{a.code} / {a.email || <em>non renseigné</em>}</span>}
                              />
                            </List.Item>
                          ))
                        }
                      </Spin>
                    </List>
                  </div>
                </Space>
              </div>

            </div>
        }

        {visibleContact &&
          <ModalContact
            visible={visibleContact}
            setVisible={setVisibleContact}
            rerender={rerender}
            contact={contact}
            setContact={setContact}
            etb={etb ? etb["@id"] : null}
          />}
      </div>
    </div>
  )
}

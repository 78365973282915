import React, {useEffect, useState} from 'react'
import {useApiContext} from '../../providers/ApiProvider.js';
import {AutoComplete, Button, Checkbox, Input, InputNumber, message, Modal} from 'antd'
import {SearchOutlined} from '@ant-design/icons';

const {confirm} = Modal;

export default function ModalKeyWord({
                                       visible,
                                       setVisible,
                                       withLocation,
                                       geoLocFilterType,
                                       setWithLocation,
                                       categoryIri,
                                       setCategoryIri,
                                       rerender,
                                       toUpdate,
                                       setToUpdate,
                                       cityFromHook,
                                       setCityFromHook,
                                       etb
                                     }) {

  const [apiDispatch] = useApiContext();
  const {
    apiPostEntity,
    apiUpdateEntity,
    apiDeleteEntity,
    apiGooglePlaceAutocomplete,
    apiGooglePlaceDetail
  } = apiDispatch;

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [name, setName] = useState("")
  const [adress, setAdress] = useState("")
  const [city, setCity] = useState(withLocation && etb?.location?.city ? etb?.location?.city : "")
  const [departement, setDepartement] = useState("")
  const [region, setRegion] = useState("")
  const [altRegion, setAltRegion] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [countrie, setCountrie] = useState("")
  const [latitude, setLatitude] = useState(withLocation && etb?.location?.latitude ? etb?.location?.latitude : null)
  const [longitude, setLongitude] = useState(withLocation && etb?.location?.longitude ? etb?.location?.longitude : null)

  const [activateAutocomplete, setActivateAutocomplete] = useState(true)
  const [placeSearchPropositions, setPlaceSearchPropositions] = useState([])
  const [placeSearchTimeout, setPlaceSearchTimeout] = useState(null)

  useEffect(() => {
    if (toUpdate) {
      hydrateForm()
    }
  }, [toUpdate])

  //console.log(etb)

  // useEffect(() => {
  //   setCity(cityFromHook)
  // }, [cityFromHook])

  function hydrateForm() {
    setName(toUpdate.name)
    if (toUpdate.location) {
      if (toUpdate.location.adress) {
        setAdress(toUpdate.location.adress);
      }
      if (toUpdate.location.city) {
        setCity(toUpdate.location.city);
      }
      if (toUpdate.location.departement) {
        setDepartement(toUpdate.location.departement);
      }
      if (toUpdate.location.region) {
        setRegion(toUpdate.location.region);
      }
      if (toUpdate.location.altRegion) {
        setAltRegion(toUpdate.location.altRegion);
      }
      if (toUpdate.location.postalCode) {
        setPostalCode(toUpdate.location.postalCode);
      }
      if (toUpdate.location.countrie) {
        setCountrie(toUpdate.location.countrie);
      }
      if (toUpdate.location.latitude) {
        setLatitude(toUpdate.location.latitude);
      }
      if (toUpdate.location.longitude) {
        setLongitude(toUpdate.location.longitude);
      }
    }
  }

  function handleCancel() {
    setVisible(false)
    if (typeof setWithLocation === "function") {
      setWithLocation(false);
    }
    if (typeof setCategoryIri === "function") {
      setCategoryIri(null);
    }
    if (typeof setCityFromHook === "function") {
      setCityFromHook(null);
    }
    setErrorMessage("")
    refreshForm()
    rerender()
  }

  function refreshForm() {
    setErrorMessage("")
    setName("")
    setAdress("")
    setCity(cityFromHook ? cityFromHook : "")
    setDepartement("")
    setRegion("")
    setAltRegion("")
    setPostalCode("")
    setCountrie("")
    setLatitude(0)
    setLongitude(0)
    setToUpdate(null)
  }

  function hydrateData(data) {
    if (adress) {
      data.adress = adress;
    }
    if (city) {
      data.city = city;
    }
    if (departement) {
      data.departement = departement
    }
    if (region) {
      data.region = region;
    }
    if (altRegion) {
      data.altRegion = altRegion;
    }
    if (postalCode) {
      data.postalCode = postalCode;
    }
    if (countrie) {
      data.countrie = countrie;
    }
    if (latitude) {
      data.latitude = latitude;
    }
    if (longitude) {
      data.longitude = longitude;
    }
  }

  async function update(close) {
    var iriLoc = null
    if (withLocation) {
      if (toUpdate.location) {
        const dataloc = {}
        hydrateData(dataloc)
        await apiUpdateEntity('locations', toUpdate.location.id, dataloc, response => {
          if (close) {
            handleCancel()
          } else {
            message.success("Mot clé enregisté avec succès")
            refreshForm()
          }
          setLoading(false)
        })
      } else {
        if (adress || city || departement || region || altRegion || postalCode || countrie || latitude || longitude) {
          const dataloc = {}
          hydrateData(dataloc)
          await apiPostEntity('locations', dataloc, response => {
            iriLoc = response['@id']
          })
        }
      }
    }
    if (name !== toUpdate.name || iriLoc) {
      const data = {
        name: name
      }
      if (iriLoc) {
        data.location = iriLoc
      }
      apiUpdateEntity('key_words', toUpdate.id, data, response => {
        if (response["@type"] === "hydra:Error") {
          setErrorMessage(response["hydra:description"])
        } else {
          if (close) {
            handleCancel()
          } else {
            message.success("Mot clé enregisté avec succès")
            refreshForm()
          }
        }
        setLoading(false)
      })
    }
  }

  function handleOk(close = false) {
    if (!name || name.length < 2) {
      setErrorMessage("Le nom est obligatoire !");
      return;
    }
    setLoading(true)
    if (toUpdate) {
      update(close)
    } else {
      if (withLocation) {
        const data = {
          name: name,
          category: categoryIri
        }
        if (adress || city || departement || region || altRegion || postalCode || countrie || latitude || longitude) {
          const dataloc = {}
          hydrateData(dataloc)
          apiPostEntity('locations', dataloc, response => {
            data.location = response['@id']
            apiPostEntity('key_words', data, responseK => {
              if (responseK["@type"] === "hydra:error") {
                setErrorMessage(responseK["hydra:description"])
                apiDeleteEntity("locations", response.id, responseDel => {
                  setLoading(false)
                })
              } else {
                setLoading(false)
                if (close) {
                  handleCancel()
                } else {
                  message.success("Mot clé enregisté avec succès")
                  refreshForm()
                }
              }
            })
          })
        } else {
          apiPostEntity('key_words', data, responseK => {
            setLoading(false)
            if (responseK["@type"] === "hydra:error") {
              setErrorMessage(responseK["hydra:description"])
            } else {
              if (close) {
                handleCancel()
              } else {
                message.success("Mot clé enregisté avec succès")
                refreshForm()
              }
            }
          })
        }
      } else {
        const data = {
          name: name,
          category: categoryIri
        }
        apiPostEntity('key_words', data, response => {
          setLoading(false)
          if (response["@type"] === "hydra:error") {
            setErrorMessage(response["hydra:description"])
          } else {
            if (close) {
              handleCancel()
            } else {
              message.success("Mot clé enregisté avec succès")
              refreshForm()
            }
          }
        })
      }
    }
  }

  function onChange(value, setter) {
    setter(value)
  }

  function handlePlaceSearch(q) {
    setPlaceSearchPropositions([])
    clearTimeout(placeSearchTimeout);
    setPlaceSearchTimeout(setTimeout(async () => {
      const response = await apiGooglePlaceAutocomplete(q);
      if (response && response.status == "OK") {
        const predictions = response.predictions.map(function (v, i) {
          return {
            value: v.description,
            key: v.place_id
          }
        })
        setPlaceSearchPropositions(predictions)
      }
    }, 500));
  }

  async function handlePlaceIdChange(a, b) {
    if (b.key) {
      let place = await apiGooglePlaceDetail(b.key)
      if (place && place.status === "OK") {
        const result = place.result
        setName(result.name)
        setAdress(result.address)
        setCity(result.city)
        setCountrie(result.country)
        setLatitude(result.gps[0])
        setLongitude(result.gps[1])
        setPostalCode(result.zip)
      }
    }
  }

  function showDeleteConfirm(id, endpoint) {
    confirm({
      title: 'Etes vous sur de vouloir supprimer cet element ?',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        remove(id, endpoint)
      },
      onCancel() {
        message.info('Sage décision');
      },
    });
  }

  function remove(id, endpoint) {
    setLoading(true)
    apiDeleteEntity(endpoint, id, response => {
      //console.log(response)
      rerender()
      handleCancel()
      setLoading(false)
    })
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Mot-clé"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Retour
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => handleOk()}>
          Enregistrer
        </Button>,
        <Button key="submitClose" type="primary" loading={loading} onClick={() => handleOk(true)}>
          Enregistrer et fermer
        </Button>
      ]}
    >
      {withLocation && geoLocFilterType === 1 ?
        <Checkbox onChange={() => setActivateAutocomplete(!activateAutocomplete)} checked={activateAutocomplete}>Autocompletion(recherche
          sur Google Places)</Checkbox>
        : null}
      <div style={{color: "red"}}>{errorMessage}</div>
      {withLocation && activateAutocomplete && geoLocFilterType === 1 ?
        <AutoComplete
          onSelect={(value, b) => handlePlaceIdChange(value, b)}
          onSearch={handlePlaceSearch}
          options={placeSearchPropositions}
          dropdownMatchSelectWidth={false}
          className="margin-5"
          style={{width: "100%"}}
        >
          <Input suffix={<SearchOutlined/>}/>
        </AutoComplete>
        : null}
      <Input className="margin-5" value={name} onChange={(e) => onChange(e.target.value, setName)}
             placeholder="Nom du mot-clés (obligatoire)"/>
      {withLocation ?
        <>
          <Input className="margin-5" value={adress} onChange={(e) => onChange(e.target.value, setAdress)}
                 placeholder="Adresse"/>
          <Input className="margin-5" value={city} onChange={(e) => onChange(e.target.value, setCity)}
                 placeholder="Ville"/>
          <Input className="margin-5" value={departement} onChange={(e) => onChange(e.target.value, setDepartement)}
                 placeholder="Département"/>
          <Input className="margin-5" value={region} onChange={(e) => onChange(e.target.value, setRegion)}
                 placeholder="Région"/>
          <Input className="margin-5" value={altRegion} onChange={(e) => onChange(e.target.value, setAltRegion)}
                 placeholder="Région alternative"/>
          <Input className="margin-5" value={postalCode} onChange={(e) => onChange(e.target.value, setPostalCode)}
                 placeholder="Code postal"/>
          <Input className="margin-5" value={countrie} onChange={(e) => onChange(e.target.value, setCountrie)}
                 placeholder="Pays"/>
          Latitude : <InputNumber style={{width: 250}} className="margin-5" value={latitude}
                                  onChange={(e) => onChange(e, setLatitude)}/><br/>
          Longitude : <InputNumber style={{width: 250}} className="margin-5" value={longitude}
                                   onChange={(e) => onChange(e, setLongitude)}/>
        </>
        : null}
      <br/>
      {
        toUpdate ?
          <Button type="link" className="danger"
                  onClick={() => showDeleteConfirm(toUpdate.id, "key_words")}>Supprimer</Button>
          : null
      }
    </Modal>
  )
}

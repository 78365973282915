import React, {useState} from "react";
import {Button, InputNumber, Modal} from "antd";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalExportCombination({data}) {

  const [accountState] = useAccountContext();
  const {account} = accountState;
  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [maxPosition, setMaxPosition] = useState(30);

  function handleOk() {
    setLoading(true);

    const body = {
      data: data.filter(item => item.position < maxPosition && !account.excludedCombinations.includes(item.expressionDeRecherche)),
      id: account.id,
      maxPosition: maxPosition
    }

    apiPostEntity("export-recap-combination-sheet", body, response => {
      setLoading(false);
      dlCsv(response)
      setVisible(false);
    })
  }

  function dlCsv(response) {
    const now = new Date();
    const arr = [addZero(now.getDate()), addZero(now.getMonth() + 1), now.getFullYear()]
    const date = arr.join('-')
    const filename = 'seo-machine-' + date + '.csv'
    const link = document.createElement('a');
    link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function addZero(number) {
    if (number < 10)
      return "0" + number;

    return number;
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{marginLeft: 20}}
      >
        Exporter CSV
      </Button>

      <Modal
        title="Exporter CSV"
        visible={visible}
        okText="Valider"
        cancelText="Annuler"
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        confirmLoading={loading}
      >
        <div>
          <div>
            Extraire uniquement les expressions avec une position inférieur à
            <InputNumber value={maxPosition} min={1} max={50} onChange={value => setMaxPosition(value)}/>
          </div>

        </div>
      </Modal>
    </>
  )
}

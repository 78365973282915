import React from "react";
import {Modal} from "antd";
import DraggableTree from "../DraggableTree";

export default function ModalEditTreePage({visible, setVisible, reload, type = "classic", language = "fr"}) {

  const title = type === "classic" ? "Editer page" : "Editer page SEO";

  function close() {
    reload();
    setVisible(false);
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={close}
      onCancel={close}
      width={700}
      destroyOnClose
    >
      <DraggableTree type={type} language={language}/>
    </Modal>
  )
}

import React, {useEffect, useState} from "react"
import {Spin, Button, Drawer} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import DivTinyEdit from "../DivTinyEdit";

export default function WorkflowDrawer() {

  const [apiDispatch] = useApiContext();
  const {apiFetchEntity, apiUpdateEntity} = apiDispatch;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(0);

  const [content, setContent] = useState("")

  useEffect(() => {
    if (visible)
      fetch();
  }, [visible, needUpdate])

  function fetch() {
    setLoading(true);

    apiFetchEntity("text_drawers", 1, response => {
      setContent(response.content);
      setLoading(false);
    })
  }

  function update() {
    apiUpdateEntity("text_drawers", 1, {content: content}, () => {});
  }

  function cancel() {
    setNeedUpdate(needUpdate + 1);
  }

  return (
    <>
      <Button
        type="primary"
        className="m-left-10"
        onClick={() => setVisible(true)}
      >
        Workflow
      </Button>

      <Drawer
        title="Workflow"
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={visible}
        width={800}
      >
        <Spin spinning={loading}>
          <DivTinyEdit
            update={update}
            cancel={cancel}
            content={content}
            setContent={setContent}
          />
        </Spin>
      </Drawer>
    </>
  )
}

import React, {useEffect, useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import {stringDateDMY} from "../../Helpers";
import {Link} from "react-router-dom";
import {Button, Input, Typography, Empty} from "antd";

const {Paragraph} = Typography;

export default function Messages({etbFilter}) {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const initialFilters = [
    {name: "status", value: true},
    {name: "order[updatedAt]", value: "desc"},
    {name: "establishment.name", value: ""},
    {name: "user.username", value: ""}
  ];
  const [filters, setFilters] = useState(initialFilters);

  const [messages, setMessages] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetch()
  }, [filters])

  useEffect(() => {
    fetch(true, page)
  }, [page])

  useEffect(() => {
    search("establishment.name", etbFilter);
  }, [etbFilter])

  function fetch(concat = false, page = 1) {
    const data = {
      page: page,
      itemsPerPage: itemsPerPage,
      filters: filters
    };

    apiFetchCollection("comments", data, response => {
      if (concat)
        setMessages([...messages, ...response["hydra:member"]]);
      else
        setMessages(response["hydra:member"]);

      setHasMore(page * itemsPerPage < response["hydra:totalItems"]);
    });
  }

  function search(key, value) {
    const index = filters.findIndex((filter => filter.name === key));
    filters[index].value = value || "";
    setFilters([...filters]);
  }

  return (
    <div className="m-topbot-20 w-30per padding-10" style={{minWidth: 300}}>

      <div>
        <h2>Message(s) Important(s) ({messages.length})</h2>
      </div>

      <div className="flex space-between">
        <Input.Search
          className="margin-10"
          style={{width: 200}}
          placeholder="Hotel"
          onSearch={(value) => search("establishment.name", value)}
          allowClear
          enterButton
        />
        <Input.Search
          className="margin-10"
          style={{width: 150}}
          placeholder="Auteur"
          onSearch={(value) => search("user.username", value)}
          allowClear
          enterButton
        />
      </div>

      <div className="">
        {messages.length ? messages.map(message =>
          <div key={message.id} className="padding-topbot-10">
            <div className="" style={{backgroundColor: "#eee", padding: 10, borderRadius: 5}}>
              <Paragraph ellipsis={{rows: 2, expandable: true, symbol: 'plus'}}>
                {message.content}
              </Paragraph>
            </div>
            <div className="flex space-between" style={{padding: '0 10px'}}>
              <div>
                <em>Par {message.user.username} le {stringDateDMY(message.updatedAt)}</em>
              </div>
              <div className="">
                <Link to={`/seo-follow-up/${message.establishment.id}`}>{message.establishment.name}</Link>
              </div>
            </div>
          </div>
        ) : <div className="empty message"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>}
        {hasMore ? <div className="row-has-more w-100per"><Button type="link" onClick={() => setPage(page + 1)}>Afficher
          plus</Button></div> : null}
      </div>

    </div>
  )
}


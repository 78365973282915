import React, { useEffect, useState } from "react";
import { useApiContext } from "../../providers/ApiProvider";
import {
  Button,
  Card, Collapse,
  Empty,
  Pagination,
  Space,
  Spin
} from "antd";
import { filterStringDate, stringDateMY } from "../../Helpers";
import { Link } from "react-router-dom";
import { EyeOutlined, WarningTwoTone } from "@ant-design/icons";
import TagDropDownLight from "../TagDropDownLight";
import TooltipDescription from "./TooltipDescription";
import TaskFilters from "./TaskFilters";

export default function SeoTasks({ etbFilter, setEtbFilter }) {

  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;

  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [key, setKey] = useState('thisMonth');

  const [totalThisMonth, setTotalThisMonth] = useState(0);
  const [totalBefore, setTotalBefore] = useState(0);
  const [totalAfter, setTotalAfter] = useState(undefined);
  const [all, setAll] = useState(0);

  const fortnightAway = new Date(Date.now() + 12960e5);
  const now = new Date();
  let today = new Date()
  const a = filterStringDate(today)
  const b = filterStringDate(today.setMonth(today.getMonth() + 1))

  useEffect(() => {
    fetchThisMonthAndBefore()
  }, [])

  function fetchThisMonthAndBefore() {
    apiFetchCollection('group-by-task-this-month', {}, response => {
      setTotalThisMonth(response.count)
    })

    apiFetchCollection('group-by-task-before', {}, response => {
      setTotalBefore(response.count)
    })

    apiFetchCollection('group-by-task-after', {}, response => {
      setTotalAfter(response.count)
    })

    apiFetchCollection('group-by-task-all', {}, response => {
      setAll(response.count)
    })
  }

  const [needUpdate, setNeedUpdate] = useState(0);
  const [loading, setLoading] = useState(true);

  const [etbsFilter, setEtbsFilter] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);

  const initialFilters = [
    { name: "exists[parent]", value: false },
    { name: "isOK", value: false },
    { name: "establishment.isStopped", value: false },
    { name: "order[plannedAt]", value: "asc" },
    { name: 'isStop', value: false }
  ];

  const firstFilters = [
    { name: "exists[parent]", value: false },
    { name: "isOK", value: false },
    { name: "establishment.isStopped", value: false },
    { name: "order[plannedAt]", value: "asc" },
    { id: 'date', name: `plannedAt[after]=${a}&plannedAt[before]`, value: b }
  ]
  const [filters, setFilters] = useState(firstFilters);

  useEffect(() => {
    setLoading(true)
    fetch()
  }, [needUpdate, filters, page])

  useEffect(() => {
    search("etb", "establishment.name", etbFilter);
  }, [etbFilter])

  function fetch() {
    const data = {
      filters: filters,
      page: page,
      itemsPerPage: itemsPerPage
    }

    apiFetchCollection("tasks", data, response => {
      hydrate(response["hydra:member"]);
      setTotal(response['hydra:totalItems'])
      setLoading(false)
    })
  }

  function hydrate(data) {
    let establishments = [...etbsFilter], tagsName = [...tagsFilter];

    data.map(d => {
      if (!establishments.includes(d.establishment.name))
        establishments.push(d.establishment.name);

      if (!tagsName.includes(d.status.name))
        tagsName.push(d.status.name);
    })

    setTagsFilter(tagsName);
    setEtbsFilter(establishments);

    setTasks(data);
  }

  function displayWarning(task) {
    const planned = new Date(task.plannedAt);

    if (planned > fortnightAway || task.status.name.toLowerCase().includes('ok'))
      return;

    if (planned < now)
      return <span className="absolute warning blink"><WarningTwoTone twoToneColor="red" /> Date dépassée !</span>

    if (planned < fortnightAway)
      return <span className="absolute warning"><WarningTwoTone twoToneColor="red" /> moins de 15 jours</span>
  }

  function makeTag(status) {
    return <div className="col w-20per cursor-p"
      style={{ color: "#fff", backgroundColor: status.color }}>{status.name}</div>
  }

  function seeAll(e) {
    setPage(1)
    // if (e.target.checked)
    setFilters(initialFilters.filter(filter => filter.name !== 'isOK'))
    // else
    //   setFilters([...filters, {name: "isOK", value: false}])

    setKey('seeAll')
  }

  function search(id, name, value) {
    setPage(1)
    if (name === "establishment.name")
      setEtbFilter(value);

    if (!value)
      return setFilters(filters.filter(f => f.id !== id));

    const index = filters.findIndex((filter => filter.id === id));

    if (index === -1)
      return setFilters([...filters, { id: id, name: name, value: value }]);

    filters[index].name = name;
    filters[index].value = value;
    setFilters([...filters]);
  }

  function thisMonth() {
    setPage(1)
    setKey('thisMonth')
    setFilters([...initialFilters, { id: 'date', name: `plannedAt[after]=${a}&plannedAt[before]`, value: b }])
  }

  function before() {
    setPage(1)
    setKey('before')
    setFilters([...initialFilters, { id: 'date', name: `plannedAt[before]`, value: a }])
  }

  function after() {
    setPage(1)
    setKey('after')
    setFilters(initialFilters)
  }

  return (
    <div>
      <div className="m-topbot-20 w-100per padding-10" style={{ minWidth: 700 }}>

        <Space>

          <Card className={`mb-30 text-center ${key === 'thisMonth' ? 'selected-key' : null}`}>
            <h3>{totalThisMonth} Etablissements ce mois ci</h3>
            <Button icon={<EyeOutlined />} type="link" onClick={thisMonth}>Voir</Button>
          </Card>

          <Card className={`mb-30 text-center ${key === 'before' ? 'selected-key' : null}`}>
            <h3>{totalBefore} Etablissements en retard</h3>
            <Button icon={<EyeOutlined />} type="link" onClick={before}>Voir</Button>
          </Card>

          <Card className={`mb-30 text-center ${key === 'after' ? 'selected-key' : null}`}>
            <h3>{totalAfter} Etablissements A faire</h3>
            <Button icon={<EyeOutlined />} type="link" onClick={after}>Voir</Button>
          </Card>

          <Card className={`mb-30 text-center ${key === 'seeAll' ? 'selected-key' : null}`}>
            <h3>{all} Etablissements En tout</h3>
            <Button icon={<EyeOutlined />} type="link" onClick={seeAll}>Voir</Button>
          </Card>

        </Space>

        <div className="mb-30">
          <Collapse>
            <Collapse.Panel key="filter" header="Plus de filtres" extra={`Total: ${total}`}>

              <TaskFilters search={search} filters={filters} needResetFields={key} />

            </Collapse.Panel>
          </Collapse>
        </div>

        <Spin spinning={loading}>
          <div className="table ">

            <div className="row">
              <div className="col head w-40per">
                Tâche
              </div>
              <div className="col head w-20per">
                Status
              </div>
              <div className="col head w-20per">
                Plannifié
              </div>
              <div className="col head w-20per">
                Etablissement
              </div>
            </div>

            {tasks.length ? tasks.map(task => (
              <div className="row" key={task.id}>
                <div className="col w-40per">
                  <TooltipDescription task={task} />
                </div>
                <TagDropDownLight
                  task={task}
                  content={makeTag(task.status)}
                  reload={() => setNeedUpdate(needUpdate + 1)}
                />
                <div className="col w-20per relative">
                  {stringDateMY(task.plannedAt)}
                  {displayWarning(task)}
                </div>
                <div className="col w-20per">
                  <Link to={`/establishment/${task.establishment.id}`}>{task.establishment.name}</Link>
                </div>
              </div>
            )) : <div className="empty"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
          </div>

          <div className="pt-10">
            <Pagination
              current={page}
              total={total}
              pageSize={itemsPerPage}
              showSizeChanger
              onChange={(page, pageSize) => {
                if (pageSize !== itemsPerPage) {
                  setItemsPerPage(pageSize)
                  if (page !== 1)
                    setPage(1)
                  else
                    setNeedUpdate(needUpdate + 1)
                } else {
                  setPage(page)
                }
              }}
            />
          </div>
        </Spin>
      </div>

    </div>
  )
}

import React, { useState } from "react";
import { Descriptions, Tooltip } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import { stringDateDMonthY } from "../../Helpers";
import ModalEditField from "../Modal/ModalEditField";
import { useAuthContext } from "../../providers/AuthProvider";
import { useApiContext } from "../../providers/ApiProvider";
import { useParams } from "react-router-dom";

export default function InfosEtb({ etb, reload }) {

  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity } = apiDispatch;
  const [authState] = useAuthContext();
  const { auth } = authState;
  const params = useParams();

  const [visible, setVisible] = useState(false)
  const [fieldValue, setFieldValue] = useState(null)
  const [fieldType, setFieldType] = useState(null)
  const [fieldKey, setFieldKey] = useState(null)
  const [entity, setEntity] = useState(null)

  const isAdmin = auth.roles.includes("ROLE_ADMIN");

  function showModal(key, type, value, endPoint) {
    setEntity(endPoint)
    setFieldKey(key)
    setFieldType(type)
    setFieldValue(value)
    setVisible(true)
  }

  function updateForModal(data, callback = () => {
  }) {
    let id;
    switch (entity) {
      case "establishments":
        id = params.id;
        break;
      case "locations":
        id = etb.location["@id"].split("/").pop();
        break;
      default:
        return;
    }
    apiUpdateEntity(entity, id, data, response => {
      setFieldType(null)
      setEntity(null)
      setFieldKey(null)
      setFieldValue(null)
      setVisible(false)
      reload();
      callback()
    })
  }

  return (
    <div>
      <Descriptions title="Infos">
        <Descriptions.Item label="URL">
          {etb.url}&nbsp;
          <Tooltip title="Editer URL">
            <EditTwoTone
              onClick={() => showModal("url", "input", etb.url, "establishments")}
              className="cursor-p hover-scale"
            />
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Hapi">{etb.hapi}</Descriptions.Item>
        <Descriptions.Item label="Ville">{etb.location.city}</Descriptions.Item>
        <Descriptions.Item label="Groupe">
          {etb.cluster}&nbsp;
          <Tooltip title="Editer groupe">
            <EditTwoTone onClick={() => showModal("cluster", "input", etb.cluster, "establishments")}
              className="cursor-p hover-scale" />
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="HID hapi v2">
          {etb.hidHapi}
          {isAdmin ? <Tooltip title="Editer HID">
            <EditTwoTone
              onClick={() => showModal("hidHapi", "input", etb.hidHapi, "establishments")}
              className="cursor-p hover-scale"
            />
          </Tooltip> : null}
        </Descriptions.Item>
        <Descriptions.Item label="Région alternative">
          {etb.location.altRegion}&nbsp;
          <Tooltip title="Editer HID">
            <EditTwoTone
              onClick={() => showModal("altRegion", "input", etb.location.altRegion, "locations")}
              className="cursor-p hover-scale"
            />
          </Tooltip>
        </Descriptions.Item>

      </Descriptions>

      <Descriptions title="Contrat">
        <Descriptions.Item label="Sea">{etb.sea ? "Oui" : "Non"}</Descriptions.Item>

        <Descriptions.Item
          label="Date de début">
          {etb.begeningAt ? stringDateDMonthY(etb.begeningAt) : "-"}
          {isAdmin ? <Tooltip title="Editer date">
            <EditTwoTone
              onClick={() => showModal("begeningAt", "date", etb.begeningAt, "establishments")}
              className="cursor-p hover-scale"
            />
          </Tooltip> : null}
        </Descriptions.Item>

        <Descriptions.Item label="Contrat SEO">
          {etb.seoContract || "-"}
        </Descriptions.Item>

        <Descriptions.Item label="Infos facturation">
          {etb.billingInfos || "-"}
          {isAdmin ? <Tooltip title="Editer Facturation">
            <EditTwoTone
              onClick={() => showModal("billingInfos", "billingInfos", etb.billingInfos, "establishments")}
              className="cursor-p hover-scale"
            />
          </Tooltip> : null}

        </Descriptions.Item>
      </Descriptions>

      <ModalEditField
        visible={visible}
        setVisible={setVisible}
        fieldType={fieldType}
        fieldValue={fieldValue}
        fieldKey={fieldKey}
        entity={entity}
        updateForModal={updateForModal}
      />
    </div>
  )
}

import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom"
import {useApiContext} from "../providers/ApiProvider";
import {Tag} from "antd";
import {ArrowLeftOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import ModalKeyWord from "../components/Modal/ModalKeyWord";

export default function Category() {

  const [apiDispatch] = useApiContext();
  const {apiFetchEntity} = apiDispatch;
  const params = useParams()

  const [needUpdate, setNeedUpdate] = useState(0)
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState({})
  const [cities, setCities] = useState([])
  const [citiesNoLocation, setCitiesNoLocation] = useState([])

  const [visible, setVisible] = useState(false)
  const [toUpdate, setToUpdate] = useState(null)
  const [cityForModal, setCityForModal] = useState("")

  useEffect(() => {
    fetch()
  }, [needUpdate])

  function fetch() {
    apiFetchEntity("categories", params.id, response => {
      setCategory(response)
      extractCities(response.keyWords)
      setLoading(false)
    })
  }

  function extractCities(keyWords) {
    let noloc = []

    let response = keyWords.map(kw => {
      if (kw.location)
        return capitalizeFirstLetter(kw.location.city.toLowerCase())
      else
        noloc.push(kw)
    })

    setCitiesNoLocation(noloc)
    setCities([...new Set(response)].filter(el => el))
  }

  function displayModal(keyword = null) {
    if (keyword) {
      setToUpdate(keyword);
    }

    setVisible(true)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="contenu-tab">
      <Link to="/mots-cles"><ArrowLeftOutlined/> Retour</Link>
      <h1 className="text-center">{category.name}</h1>
      <Tag color="#108ee9" size="small" onClick={() => displayModal()}
           className="site-tag-plus"><PlusOutlined/> Ajouter</Tag>
      {loading ? <div>Chargement ...</div>
        :
        <div className="mt-20">
          {citiesNoLocation.length ?
            <div>
              <div className="padding-topbot-10">
                <span className="bold font-15">A trier (sans ville défini)</span>
              </div>
              {citiesNoLocation.map(kw => (
                <Tag color="geekblue" key={kw.id}>
                  {kw.name}&nbsp;
                  <EditOutlined onClick={() => displayModal(kw)}/>
                </Tag>
              ))}
            </div>
            : null}
          {cities.map((city, index) => (
            <div key={index}>
              <div className="padding-topbot-10">
                <span className="bold font-15">{city}</span>&nbsp;&nbsp;
                <Tag
                  color="#108ee9"
                  size="small"
                  onClick={() => {
                    setCityForModal(city)
                    displayModal()
                  }}
                  className="site-tag-plus"
                >
                  <PlusOutlined/> Ajouter
                </Tag>
              </div>
              {category.keyWords.filter(kw => kw.location && kw.location.city.toLowerCase() === city.toLowerCase()).map(keyWord => (
                <Tag color="geekblue" key={keyWord.id}>
                  {keyWord.name}&nbsp;
                  <EditOutlined onClick={() => displayModal(keyWord)}/>
                </Tag>
              ))}
            </div>
          ))}
        </div>}
      <ModalKeyWord
        visible={visible}
        setVisible={setVisible}
        withLocation={true}
        geoLocFilterType={category.geoLocFilterType}
        categoryIri={category["@id"]}
        toUpdate={toUpdate}
        setToUpdate={setToUpdate}
        rerender={() => setNeedUpdate(needUpdate + 1)}
        cityFromHook={cityForModal}
        setCityFromHook={setCityForModal}
      />
    </div>
  )
}

import React, {useEffect} from 'react'
import {useAccountContext} from "../providers/AccountProvider";
import {Route, useLocation} from 'react-router-dom';

import PrivateRoute from './PrivateRoute.js'
import PublicRoute from "./PublicRoute";

import Login from '../pages/Login.js'
import Logout from '../pages/Logout.js'

import Dashboard from "../pages/Dashboard";
import Establishments from "../pages/Establishments";
import KeyWords from "../pages/KeyWords";
import CreateEstablishment from "../pages/CreateEstablishment";
import EstablishmentKeyWords from "../pages/EstablishmentKeyWords";
import Establishment from "../pages/Establishment";
import User from "../pages/User";
import Users from "../pages/Users";
import Redirection from "../pages/Redirection";
import TreePageManager from "../pages/TreePageManager";
import CombinationManager from "../pages/CombinationManager";
import Category from "../pages/Category";
import SEOFollowUp from "../pages/SEOFollowUp";
import CombinationsList from "../pages/CombinationsList";
import Canva from "../pages/Canva";
import CombinationMonitoring from "../pages/CombinationMonitoring";
import SerpOverviewPublic from "../pages/SerpOverviewPublic";

export default function Router() {

  const location = useLocation();
  const [accountState, accountDispatch] = useAccountContext();
  const {resetState} = accountDispatch;

  const accountPaths = [
    "establishment-key-words", "establishment", "edit-establishment", "category", "redirection", "tree-page", "generate", "seo-follow-up"
  ];

  useEffect(() => {
    const params = location.pathname.split('/');
    if (!accountPaths.includes(params[1]))
      resetState();
  }, [location.pathname])

  return (
    <div>
      <PrivateRoute exact path="/" component={Dashboard} sidebar={true}/>
      <PrivateRoute exact path="/dashboard" component={Dashboard} sidebar={true}/>
      <PrivateRoute exact path="/user" component={User} sidebar={true}/>
      <PrivateRoute exact path="/users" component={Users} sidebar={true}/>
      <PrivateRoute exact path="/establishments" component={Establishments} sidebar={true}/>
      <PrivateRoute path="/create-establishment" component={CreateEstablishment} sidebar={true}/>
      <PrivateRoute path="/mots-cles" component={KeyWords} sidebar={true}/>
      <PrivateRoute exact path="/combinations-list" component={CombinationsList} sidebar={true}/>
      <PrivateRoute path="/edit-establishment/:id" component={CreateEstablishment} sidebar={true}/>
      <PrivateRoute path="/category/:id" component={Category} sidebar={true}/>

      <PrivateRoute exact path="/establishment-key-words/:id" component={EstablishmentKeyWords} sidebar={true}/>
      <PrivateRoute exact path="/establishment/:id" component={Establishment} sidebar={true}/>
      <PrivateRoute path="/redirection/:id" component={Redirection} sidebar={true}/>
      <PrivateRoute path="/tree-page/:id" component={TreePageManager} sidebar={true}/>
      <PrivateRoute path="/generate/:id" component={CombinationManager} sidebar={true}/>
      <PrivateRoute path="/seo-follow-up/:id" component={SEOFollowUp} sidebar={true}/>
      <PrivateRoute path="/combination-monitoring/:id" component={CombinationMonitoring} sidebar={true}/>

      <PublicRoute path="/login" component={Login}/>
      <PrivateRoute path="/logout" component={Logout} sidebar={true}/>
      <PublicRoute path="/canva" component={Canva}/>
      <Route path="/seo/:slug/:id" component={SerpOverviewPublic}/>
    </div>
  );
}

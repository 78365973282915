import React, { useEffect, useState } from 'react'
import { useApiContext } from '../providers/ApiProvider.js';
import { AutoComplete, Button, DatePicker, Form, Input, InputNumber, message, Select, Space } from 'antd'
import { SearchOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { billingInfoList } from '../Helpers.js';

const { Option } = Select;

export default function CreateEstablishment() {

  const history = useHistory();
  const params = useParams();
  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiFetchSubResource, getPlacesFromWallet, apiUpdateEntity, apiDeleteEntity } = apiDispatch;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [etbProposition, setEtbProposition] = useState([])
  const [searchEtb, setSearchEtb] = useState(null)

  let idLocForUpdate = null;

  useEffect(() => {
    if (params.id) {
      fetchEtb()
    }
  }, [])

  function fetchEtb() {
    const data = {
      id: params.id,
      json: true
    }
    apiFetchSubResource("establishments", data, "get_all_details", response => {
      let obj = {}
      const needMoment = ["begeningAt", "changeAt", "finishAt"];
      for (const [key, value] of Object.entries(response)) {
        if (key !== "location") {
          if (needMoment.includes(key)) {
            obj[key] = moment(value)
          } else {
            obj[key] = value
          }
        }
      }
      if (response.location) {
        for (const [key, value] of Object.entries(response.location)) {
          if (key === "id") {
            idLocForUpdate = value
          } else {
            obj[key] = value
          }
        }
      }

      form.setFieldsValue(obj)
    })
  }

  function handleClose() {
    form.resetFields();
  }

  function errorMessage(content) {
    setLoading(false)
    message.error(content)
  }

  function onSuccess(path) {
    setLoading(false)
    history.push(path)
  }

  function handleOk(value) {
    setLoading(true);
    var data = sortValues();


    if (params.id) {
      if (idLocForUpdate) {
        apiUpdateEntity("locations", idLocForUpdate, data[1], responseL => {
          apiUpdateEntity("establishments", params.id, data[0], response => {
            if (response["@type"] === "ConstraintViolationList")
              return errorMessage(response["hydra:description"]);

            onSuccess(`/establishment/${response.id}`);
          })
        })
      } else {
        if (data[1]) {
          apiPostEntity("locations", data[1], response => {
            data[0].location = response['@id']
            apiUpdateEntity("establishments", params.id, data[0], responseE => {
              onSuccess(`/establishment/${responseE.id}`);
            })
          })
        } else {
          apiUpdateEntity("establishments", params.id, data[0], response => {
            if (response["@type"] === "ConstraintViolationList")
              return errorMessage(response["hydra:description"]);

            onSuccess(`/establishment/${response.id}`);
          })
        }
      }
    } else {
      if (data.length > 1) {
        data[1].establishment
        apiPostEntity("locations", data[1], responseL => {
          data[0].location = responseL['@id']
          apiPostEntity('establishments', data[0], response => {
            if (response["@type"] === "ConstraintViolationList") {
              errorMessage(response["hydra:description"])
              if (data[0].location) {
                const id = data[0].location.split("/").pop()
                apiDeleteEntity("locations", id, responseD => {
                })
              }
              return
            }
            handleClose()
            onSuccess(`/establishment/${response.id}`);
          })
        })
      } else {
        apiPostEntity('establishments', data[0], response => {
          if (response["@type"] === "ConstraintViolationList")
            return errorMessage(response["hydra:description"]);

          handleClose();
          onSuccess(`/establishment/${response.id}`);
        })
      }
    }
  }

  function needPushLoc(data) {
    return !!(data.city || data.adress || data.departement || data.region || data.postalCode || data.countrie || (data.latitude && data.longitude));
  }

  function sortValues(obj) {
    let resp = []
    let etb = {}

    const data = form.getFieldsValue()

    etb.name = data.name
    etb.url = data.url
    etb.hapi = data.hapi
    etb.cluster = data.cluster
    etb.hidHapi = data.hidHapi
    etb.sea = data.sea
    etb.begeningAt = data.begeningAt ? data.begeningAt.format('YYYY-MM-DD') : null
    etb.changeAt = data.changeAt ? data.changeAt.format('YYYY-MM-DD') : null
    etb.finishAt = data.finishAt ? data.finishAt.format('YYYY-MM-DD') : null
    etb.billingInfos = data.billingInfos
    etb.status = data.status
    etb.comment = data.comment

    resp.push(etb)

    if (needPushLoc(data)) {
      let loc = {}

      loc.city = data.city
      loc.adress = data.adress
      loc.departement = data.departement
      loc.region = data.region
      loc.altRegion = data.altRegion
      loc.postalCode = data.postalCode
      loc.countrie = data.countrie
      loc.latitude = data.latitude
      loc.longitude = data.longitude

      resp.push(loc)
    }

    return resp;
  }

  function callWallet(q) {
    setEtbProposition([])
    clearTimeout(searchEtb)
    setSearchEtb(setTimeout(async () => {
      const response = await getPlacesFromWallet(q)
      if (response && response["@type"] === "hydra:Collection") {
        const predictions = response["hydra:member"].map(r => {
          return {
            value: r.name,
            data: r
          }
        })
        setEtbProposition(predictions)
      }
    }, 500))
  }

  function getHapiVersion(data) {
    if (data.contracts && data.contracts.length > 0) {
      if (data.contracts.filter(c => c.name === "HAPI v2").length > 0) {
        return 2;
      }
      if (data.contracts.filter(c => c.name === "HAPI v1").length > 0) {
        return 1;
      }
    }
    return 0;
  }

  function getSea(data) {
    if (data.contracts && data.contracts.length > 0) {
      if (data.contracts.filter(c => c.name === "SEA").length > 0) {
        return true;
      }
    }
    return false;
  }

  function getBegeningAt(data) {
    if (data.contracts && data.contracts.length > 0) {
      if (data.contracts.filter(c => c.name === "SEO").length > 0) {
        return moment(data.contracts.filter(c => c.name === "SEO")[0].subscribedAt)
      }
    }
    return null;
  }

  function getEndedAt(data) {
    if (data.contracts && data.contracts.length > 0) {
      if (data.contracts.filter(c => c.name === "SEO").length > 0) {
        return moment(data.contracts.filter(c => c.name === "SEO")[0].endedAt)
      }
    }
    return null;
  }

  function makeChoice(value, option) {
    const data = option.data
    const hapi = getHapiVersion(data)
    const sea = getSea(data);
    const begeningAt = getBegeningAt(data)
    const finishAt = getEndedAt(data)

    let newValues = {
      name: data.name,
      url: data.website,
      hapi: hapi,
      sea: sea,
      adress: data.address,
      postalCode: data.zip,
      countrie: data.country,
      city: data.city,
      latitude: data.lat,
      longitude: data.lng,
      begeningAt: begeningAt,
      finishAt: finishAt
    }

    Object.keys(newValues).map((key, index) => {
      if (!newValues[key])
        delete newValues[key];
    })

    form.setFieldsValue(newValues)
  }

  function goToEtb() {
    history.push(`/establishment/${params.id}`)
  }

  return (
    <div className="contenu-tab">

      {params.id &&
        <div className="mb-30">
          <Button type="primary" onClick={goToEtb}>Retour</Button>
        </div>
      }

      <Form
        form={form}
        name="etb"
        onFinish={handleOk}
        initialValues={{
          url: null,
          hapi: 0,
          cluster: null,
          sea: false,
          changeAt: null,
          billingInfos: null,
          status: "A jour",
          comment: null
        }}
      >
        <h3>Infos Etablissement</h3>
        <Form.Item
          name="name"
          label="Nom"
          rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
        >
          <AutoComplete
            onSelect={(value, b) => makeChoice(value, b)}
            onSearch={callWallet}
            options={etbProposition}
            dropdownMatchSelectWidth={false}
          >
            <Input suffix={<SearchOutlined />} />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          name="url"
          label="Url"
        >
          <Input />
        </Form.Item>
        <Form.Item name="hapi" label="Hapi" rules={[{ required: true }]}>
          <Select
            allowClear
            rules={[{ required: true }]}
          >
            <Option value={0}>autre</Option>
            <Option value={1}>v1</Option>
            <Option value={2}>v2</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="cluster"
          label="Groupe"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="hidHapi"
          label="HID Hapi V2"
        >
          <Input />
        </Form.Item>
        <Form.Item name="sea" label="Sea" rules={[{ required: true, message: 'Ce champs est obligatoire' }]}>
          <Select
            allowClear
          >
            <Option value={false}>Non</Option>
            <Option value={true}>Oui</Option>
          </Select>
        </Form.Item>
        <Form.Item name="begeningAt" label="Commence le">
          <DatePicker />
        </Form.Item>
        <Form.Item name="changeAt" label="Dernière modif">
          <DatePicker />
        </Form.Item>
        <Form.Item name="finishAt" label="Fini le">
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="billingInfos"
          label="Infos Facturation"
        >
          <Select>
            {
              billingInfoList.map(item => <Option value={item}>{item}</Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Ce champs est obligatoire' }]}>
          <Select
            allowClear
          >
            <Option value="En retard">En retard</Option>
            <Option value="A jour">A jour</Option>
            <Option value="En cours">En cours</Option>
            <Option value="Suspendu">Suspendu</Option>
            <Option value="Résilié">Supprimé</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="comment"
          label="Commentaire"
        >
          <Input.TextArea />
        </Form.Item>
        <div className="flex"><h3>Localistaion</h3></div>
        <Form.Item
          name="adress"
          label="Adresse"
          initialValue={null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="city"
          label="Ville"
          initialValue={null}
          rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="departement"
          label="Departement"
          initialValue={null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="region"
          label="Region"
          initialValue={null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="altRegion"
          label="Region alternative"
          initialValue={null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="postalCode"
          label="Code postal"
          initialValue={null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="countrie"
          label="Pays"
          initialValue={null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="latitude"
          label="Latitude"
          initialValue={null}
        >
          <InputNumber style={{ width: 250 }} />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="Longitude"
          initialValue={null}
        >
          <InputNumber style={{ width: 250 }} />
        </Form.Item>
        <Form.Item>
          <Space>

            {params.id &&
              <Button type="primary" onClick={goToEtb}>Retour</Button>}

            <Button htmlType="button" type="danger" onClick={() => form.resetFields()}>
              Reset
            </Button>

            <Button type="primary" htmlType="submit" loading={loading}>
              Enregistrer
            </Button>

          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

import React, {useState} from "react";
import {Button, Input, message} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {useAuthContext} from "../../providers/AuthProvider";
import CombinationDropdown from "./CombinationDropdown";

export default function AddRow({task, reload, update}) {

  const [authState] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;
  const params = useParams();

  const [value, setValue] = useState("");
  const [selectValue, setSelectValue] = useState(null);

  function addTask() {
    if (!value || value.length < 3)
      return message.error("Description obligatoire !)");

    if (selectValue)
      return postTaskFavorite();

    postTaskBasic();
  }

  function postTaskFavorite() {
    const data = {
      description: value,
      favorite: selectValue,
      establishment: '/api/establishments/' + params.id,
      user: ['/api/users/' + auth.id],
      parent: task['@id'],
      status: "/api/tags/3"
    }

    apiPostEntity("tasks", data, () => {
      setValue("");
      setSelectValue(null)
      reload();
    })
  }

  function postTaskBasic() {
    const data = {
      description: value,
      establishment: '/api/establishments/' + params.id,
      user: ['/api/users/' + auth.id],
      parent: task['@id'],
      status: "/api/tags/3"
    }

    apiPostEntity("tasks", data, () => {
      setValue("");
      reload();
    })
  }

  return (
    <div className="row">
      <div className="col flex space-between w-100per">

        <div>
          <Input
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{width: 350}}
            placeholder="Ajouter une tache"
          />
        </div>

        {task.description.includes('Intervention') ?
          <div>
            <CombinationDropdown
              value={selectValue}
              setValue={setSelectValue}
              update={update}
            />
          </div> : null}

        <div>
          <Button onClick={addTask} type="primary">Ajouter</Button>
        </div>

      </div>
    </div>
  )
}

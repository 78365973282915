import React, { useEffect, useState } from "react";
import { useApiContext } from "../../providers/ApiProvider";
import { useParams } from "react-router-dom";
import Page from "./Page";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FavoriteBox from "./FavoriteBox";
import ModalEditPage from "../Modal/ModalEditPage";
import ModalEditTreePage from "../Modal/ModalEditTreePage";
import { PlusOutlined, EyeOutlined, BarChartOutlined, MoreOutlined, WarningTwoTone, SplitCellsOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Space, Spin, Tooltip } from "antd";
import { useSEOContext } from "../../providers/SEOProvider";
import LanguageChoice from "./LaguageChoice";
import ModalPagesKeyWords from "../Modal/ModalPagesKeyWords";
import HapiConnect from "./HapiConnect";
import { useAccountContext } from "../../providers/AccountProvider";
import ModalHapi from "./ModalHapi";
import quickEditImd from '../../assets/quickedit.png'

export default function SEOManager() {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiFetchCollection, apiPostSerp, apiPostEntity } = apiDispatch;
  const [accountState] = useAccountContext();
  const { account } = accountState;

  const [seoState, seoDispatch] = useSEOContext();
  const { hapi } = seoState;
  const { setTags, reload, setLoading } = seoDispatch;

  const [favorites, setFavorites] = useState([])

  const [pages, setPages] = useState([])

  const [pagesSEO, setPagesSEO] = useState([])

  const [favNextToUse, setFavNextToUse] = useState([])

  const [favNotUse, setFavNotUse] = useState([])

  const [visible, setVisible] = useState(false)
  const [pageToEdit, setPageToEdit] = useState({})

  const [visibleTP, setVisibleTp] = useState(false)
  const [type, setType] = useState("")

  const [visibleKw, setVisibleKw] = useState(false)
  const [keyWords, setKeyWords] = useState("")

  const [bulkEditing, setBulkEditing] = useState(false)

  useEffect(() => {
    fetchFavorite("classic")
  }, [seoState.allNeedUpdate, seoState.language])

  useEffect(() => {
    fetchPage("classic")
  }, [seoState.allNeedUpdate, seoState.language])

  useEffect(() => {
    fetchPage("seo")
  }, [seoState.allNeedUpdate, seoState.language])

  useEffect(() => {
    fetchFavorite("nextToUse")
  }, [seoState.allNeedUpdate, seoState.language])

  useEffect(() => {
    fetchFavorite("notUse")
  }, [seoState.allNeedUpdate, seoState.language])

  useEffect(() => {
    fetchTags()
  }, [seoState.tagNeedUpdate, seoState.language])

  function fetchTags() {
    apiFetchCollection("tags", {}, response => {
      setTags(response["hydra:member"])
    })
  }

  function fetchFavorite(status) {
    const data = {
      id: params.id,
      filters: [
        { name: "exists[page]", value: false },
        { name: "status", value: status },
        { name: "language", value: seoState.language }
      ]
    }
    apiFetchSubResource("establishments", data, "favorite_combination_key_words", response => {
      switch (status) {
        case "classic":
          setFavorites(response["hydra:member"])
          break;
        case "nextToUse":
          setFavNextToUse(response["hydra:member"])
          break;
        case "notUse":
          setFavNotUse(response["hydra:member"])
          break;
      }

      setLoading(false)
    })
  }

  function fetchPage(type) {
    const data = {
      id: params.id,
      filters: [
        { name: "exists[parent]", value: false },
        { name: "type", value: type },
        { name: "language", value: seoState.language }
      ]
    }
    apiFetchSubResource("establishments", data, "tree_pages", response => {
      switch (type) {
        case "classic":
          setPages(response["hydra:member"]);
          break;
        case "seo":
          setPagesSEO(response["hydra:member"]);
          break;
      }
    })
  }

  function showModal(page) {
    setPageToEdit(page)
    setVisible(true)
  }

  function showModalNewPage(type) {
    setType(type)
    setVisibleTp(true)
  }

  function extractCombination(array) {
    var result = [];

    array.map(page => {
      if (page.favoriteCombinationKeyWord)
        result.push(page.favoriteCombinationKeyWord.combination.result);

      if (page.childs && page.childs.length)
        result = [...result, ...extractCombination(page.childs)];
    })

    return result;
  }

  function showModalKeyWord(type) {
    switch (type) {
      case 'classic':
        setKeyWords(extractCombination(pages).join(', '));
        break;
      case 'seo':
        setKeyWords(extractCombination(pagesSEO).join(', '));
        break;
    }

    setVisibleKw(true);
  }

  async function launchSerp(pagesType) {
    if (!account.url || account.url < 10)
      return message.error("L'URL du compte doit être défini. Aller dans la fiche et editez l'URL")

    const data = pagesType.map(page => {
      if (page.favoriteCombinationKeyWord) {
        return {
          "language_code": "fr",
          "keyword": page.favoriteCombinationKeyWord.combination.result,
          "location_name": "France",
          "postback_url": `${process.env.API_ENTRYPOINT}/api/public/postbackscript`,
          "postback_data": "regular",
          "tag": page.favoriteCombinationKeyWord.id,
          "device": "desktop",
          "os": "windows"
        }
      }
    })

    const response = await apiPostSerp(data.filter(r => r));
    if (response.status_code === 20000)
      message.success("Tache envoyé les resultats remonteront d'ici quelques minutes ...")
    else
      message.error("Une erreur est survenue. Réessayez puis contactez l'équipe technique si cela persite")
  }

  function handleClick(key, type) {
    switch (key) {
      case 'resetPosition':
        resetPosition(type)
        break;
    }
  }

  function resetPosition(type) {
    setLoading(true)
    const data = {
      etbId: account.id,
      type: type
    }

    apiPostEntity('clean-serp-position', data, response => {
      reload();
    })
  }

  function displayMenu(type) {
    return (
      <Menu onClick={({ key }) => handleClick(key, type)}>
        <Menu.Item key="resetPosition">
          <Space>
            <WarningTwoTone twoToneColor="red" />
            <span style={{ color: 'red' }}>Effacer les résultats des positions google</span>
            <WarningTwoTone twoToneColor="red" />
          </Space>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="contenu-tab">
      <div className="flex space-between">
        <LanguageChoice />
        <Space>
          {
            bulkEditing ?
              <Button onClick={() => setBulkEditing(false)} size="small">Fermer l'édition rapide</Button>
              :
              <Tooltip title={
                <div>
                  <p>Edition rapide</p>
                  <img src={quickEditImd} className="w-100per" />
                  <p>Cliquez sur la partie que vous souhaitez garder</p>
                </div>
              }>
                <Button
                  onClick={() => setBulkEditing(prev => !prev)}
                  icon={
                    <SplitCellsOutlined />
                  } />
              </Tooltip>
          }
          <HapiConnect />
        </Space>
      </div>
      <Spin spinning={seoState.loading}>
        <DndProvider backend={HTML5Backend}>

          <div className="flex wrap">

            <FavoriteBox favorites={favorites} reload={reload} status="classic" setLoading={setLoading} bulkEditing={bulkEditing}/>

            <div className="trello-box">
              <div className="trello-box-title flex space-between">
                <div>Page(s)</div>
                <Space>
                  {pages.some(page => page.favoriteCombinationKeyWord) ?
                    <Tooltip title="Lancer une analyse SEO (SERP) sur cette colonne">
                      <BarChartOutlined onClick={() => launchSerp(pages)} />
                    </Tooltip> : null}
                  <Tooltip title="Voir les combinaisons">
                    <EyeOutlined onClick={() => showModalKeyWord("classic")} />
                  </Tooltip>
                  <Tooltip title="Ajouter page">
                    <PlusOutlined onClick={() => showModalNewPage("classic")} />
                  </Tooltip>
                  <Dropdown overlay={() => displayMenu('classic')} trigger={['click']}>
                    <MoreOutlined />
                  </Dropdown>
                </Space>
              </div>
              {pages.map(page => (
                <Page page={page} key={page.id} deg={0} reload={reload} showModal={showModal}
                  setLoading={setLoading} bulkEditing={bulkEditing} />
              ))}
            </div>

            <div className="trello-box">
              <div className="trello-box-title flex space-between">
                <div>Page(s) SEO</div>
                <Space>
                  {pagesSEO.some(page => page.favoriteCombinationKeyWord) ?
                    <Tooltip title="Lancer une analyse SEO (SERP) sur cette colonne">
                      <BarChartOutlined onClick={() => launchSerp(pagesSEO)} />
                    </Tooltip> : null}
                  <Tooltip title="Voir les combinaisons">
                    <EyeOutlined onClick={() => showModalKeyWord("seo")} />
                  </Tooltip>
                  <Tooltip title="Ajouter page SEO">
                    <PlusOutlined onClick={() => showModalNewPage("seo")} />
                  </Tooltip>
                  <Dropdown overlay={() => displayMenu('seo')} trigger={['click']}>
                    <MoreOutlined />
                  </Dropdown>
                </Space>
              </div>
              {pagesSEO.map(seo => (
                <Page key={seo.id} page={seo} deg={0} reload={reload} showModal={showModal}
                  setLoading={setLoading} bulkEditing={bulkEditing} />
              ))}
            </div>

            <FavoriteBox favorites={favNextToUse} reload={reload} status="nextToUse" setLoading={setLoading} />
            <FavoriteBox favorites={favNotUse} reload={reload} status="notUse" setLoading={setLoading} />
          </div>

        </DndProvider>
      </Spin>
      <ModalEditPage visible={visible} setVisible={setVisible} page={pageToEdit} reload={reload} />
      <ModalEditTreePage
        visible={visibleTP}
        setVisible={setVisibleTp}
        reload={reload}
        type={type}
        language={seoState.language}
      />
      <ModalPagesKeyWords visible={visibleKw} setVisible={setVisibleKw} value={keyWords} />
      {hapi.visible && <ModalHapi />}
    </div>
  )
}

import React from 'react'
import {Card, Dropdown, Menu} from "antd";
import {
  BankOutlined,
  BarChartOutlined,
  EllipsisOutlined,
  LockOutlined, SwapOutlined,
  TagOutlined,
  TagsOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";

export default function EstablishmentCard({establishment, modalRight}) {

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={`/establishment-key-words/${establishment.id}`}><TagOutlined/> Mots clés</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={`/seo-follow-up/${establishment.id}`}><BarChartOutlined/> Suivi SEO</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={`/combination-monitoring/${establishment.id}`}><BarChartOutlined/> Suivi Combinaisons</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={`/generate/${establishment.id}`}><TagsOutlined/> Combinaisons</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={`/redirection/${establishment.id}`}><SwapOutlined/> Redirection</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="etb-card"
    >
      <Card
        style={{width: '100%'}}
        actions={[
          <LockOutlined key="permission" onClick={() => modalRight(establishment.id)}/>,
          <Dropdown overlay={menu} trigger={['click']}>
            <EllipsisOutlined key="ellipsis" onClick={e => e.preventDefault()}/>
          </Dropdown>,
        ]}
      >
        <div style={{minHeight: 42}}>
          <Link to={`/establishment/${establishment.id}`}>
            <h4 style={{color: '#1890ff'}}>{establishment.name}</h4>
          </Link>
        </div>
        <div><span><BankOutlined/></span> <span>{establishment.location.city}</span></div>
        <div>{establishment.hapi ? `Hapi v${establishment.hapi}` : "-"}</div>
        <div><TagOutlined /> {establishment.billingInfos}</div>
      </Card>
    </div>
  )
}

import React, {useEffect, useState} from 'react'
import {Modal, Form, Input, message, Select} from "antd"
import {useApiContext} from '../../providers/ApiProvider.js';
import {useAuthContext} from '../../providers/AuthProvider.js';

export default function ModalCreateUser({visible, setVisible, rerender, user}) {

  const [form] = Form.useForm();
  const [authState] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity} = apiDispatch;
  const [loading, setLoading] = useState(false);

  const selectRoles = {
    1: ["ROLE_DEV", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    2: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    3: ["ROLE_ADMIN"],
    4: ["ROLE_USER"]
  }

  useEffect(() => {
    if (user)
      fetch();
  }, [user])

  function fetch() {
    let obj = {}
    for (const [key, value] of Object.entries(user)) {
      switch (key) {
        case "email":
          obj[key] = value;
          break;
        case "username":
          obj[key] = value;
          break;
        case "roles":
          obj[key] = getRoles(value);
          break;
        default:
          break;
      }
    }

    form.setFieldsValue(obj)
  }

  function getRoles(value) {
    if (value.includes("ROLE_DEV"))
      return 1;

    if (value.includes("ROLE_SUPER_ADMIN"))
      return 2;

    if (value.includes("ROLE_ADMIN"))
      return 3;

    return 4;
  }

  function handleOk() {
    setLoading(true)
    let data = form.getFieldsValue();

    data.roles = selectRoles[data.roles]

    if (user) {
      apiUpdateEntity("users", user.id, data, response => {
        if (response["@type"] === "hydra:Error") {
          setLoading(false)
          return message.error(response['hydra:description'])
        }
        closeWithReload()
      })
    } else {
      data.password = "mmc/2020";
      apiPostEntity('users', data, response => {
        if (response["@type"] === "hydra:Error") {
          setLoading(false)
          return message.error(response['hydra:description'])
        }
        closeWithReload()
      })
    }
  }

  function closeWithReload() {
    if (typeof rerender === "function")
      rerender();

    setLoading(false);
    handleCancel();
  }

  function handleCancel() {
    form.resetFields();
    setVisible(false);
  }

  return (
    <Modal
      visible={visible}
      title="Creer utilistaeur"
      onOk={() => form.submit()}
      onCancel={handleCancel}
      confirmLoading={loading}
      destroyOnClose
    >
      <p><em>*** Le mot de passe par defaut est:</em> mmc/2020</p>
      <Form
        layout="vertical"
        form={form}
        name="etb"
        onFinish={handleOk}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {required: true, message: 'Ce champs ne peut etre vide'},
            {type: 'email', message: 'Format email requis'}
          ]}
        >
          <Input placeholder="Ex: ludovic.mmcreation@gmail.com"/>
        </Form.Item>
        <Form.Item
          name="username"
          label="Nom d'utilisateur"
          rules={[
            {required: true, message: 'Ce champs ne peut etre vide'},
          ]}
        >
          <Input placeholder="EX: Ludovic"/>
        </Form.Item>
        {auth.roles.includes("ROLE_SUPER_ADMIN")
          ?
          <Form.Item
            name="roles"
            label="Roles"
            rules={[{required: true, message: 'Le role est requis'}]}
          >
            <Select
              placeholder="Selectionner un role"
              optionFilterProp="value"
            >
              <Select.Option value={1}>développeur (acces a tout meme aux
                comptes tests pour le dev)</Select.Option>
              <Select.Option value={2}>Super admin (acces a tout)</Select.Option>
              <Select.Option value={3}>Admin (acces a tous les comptes actifs)</Select.Option>
              <Select.Option value={4}>Utilisateur (acces a des comptes en particulier)</Select.Option>
            </Select>
          </Form.Item>
          : null}
      </Form>
    </Modal>
  )
}

import React, {useEffect, useState} from "react";
import {useApiContext} from '../providers/ApiProvider.js';
import {useParams} from "react-router-dom";
import {Input, message, Spin, Tree, Button, Modal, Switch, AutoComplete, Space} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import Fr from "./Svg/Flag/Fr";
import Gb from "./Svg/Flag/Gb";

const {confirm} = Modal;

export default function DraggableTree({type = "classic", language = "fr"}) {

  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity, apiFetchSubResource, apiDeleteEntity, apiFetchCollection} = apiDispatch;
  const params = useParams();

  const [loading, setLoading] = useState(true)
  const [needUpdate, setNeedUpdate] = useState(0)
  const [tree, setTree] = useState([])
  const [expanded, setExpanded] = useState([])

  const [value, setValue] = useState({fr: "", en: ""})
  const [valueEdit, setValueEdit] = useState("")
  const [nodeSelected, setNodeSelected] = useState(null)

  const [currentLng, setCurrentLng] = useState(language)

  useEffect(() => {
    setCurrentLng(language)
  }, [language])

  useEffect(() => {
    fetch()
  }, [needUpdate])

  function fetch() {
    const data = {
      id: params.id,
      filters: [
        {name: "exists[parent]", value: false},
        {name: "type", value: type},
        {name: "language", value: language}
      ]
    }
    apiFetchSubResource("establishments", data, "tree_pages", response => {
      var exp = []
      var arr = implement(response["hydra:member"], "0-", exp)

      setExpanded(exp)
      setTree(arr)
      setLoading(false)
    })
  }

  function implement(data, key, exp) {
    var tab = []

    data.sort((a, b) => a.name.localeCompare(b.name)).map((d, id) => {
      var row = {title: d.name, key: key + id, id: d.id}
      if (d.childs.length) {
        row.children = implement(d.childs, key + id + "-", exp)
        exp.push(key + id)
      }
      tab.push(row)
    });

    return tab
  }

  const onDragEnter = info => {
    setExpanded(info.expandedKeys)
  };

  const onDrop = info => {
    setLoading(true)
    let dataUpd
    if (info.dropToGap) {
      dataUpd = {
        parent: null
      }
    } else {
      dataUpd = {
        parent: "/api/tree_pages/" + info.node.id
      }
    }
    apiUpdateEntity("tree_pages", info.dragNode.id, dataUpd, response => {
      setNeedUpdate(needUpdate + 1)
    })
  };

  function addNew() {
    if (!value || value.length < 2) {
      return message.error("Nom trop court")
    }

    if (tree.some(t => t.title === value)) {
      return message.error("Cette page existe déjà")
    }

    setLoading(true);

    if (value[language])
      postPage(language);

    const otherLanguage = language === "fr" ? "en" : "fr";
    if (value[otherLanguage]) {
      if (value[language])
        postPage(otherLanguage, false);
      else
        postPage(otherLanguage);
    }
  }

  function postPage(lng, withReload = true) {
    const data = {
      name: value[lng],
      establishment: "/api/establishments/" + params.id,
      type: type,
      language: lng
    }

    apiPostEntity("tree_pages", data, () => {
      if (withReload) {
        setNeedUpdate(needUpdate + 1);
        setOptions([]);
        setValue({fr: "", en: ""});
        setCurrentLng(language);
      }
    })
  }

  const onSelect = (keys, event) => {
    if (event.selected) {
      setValueEdit(event.node.title)
      return setNodeSelected(event.node)
    }
    setValueEdit("")
    setNodeSelected(null)
  };

  function toggleExpand(expandedKeys) {
    setExpanded(expandedKeys)
  }

  function edit(value) {
    if (!value || value.length < 2) {
      return message.error("Nom trop court")
    }

    if (nodeSelected.title === value) {
      return message.error("Nom identique")
    }

    setLoading(true)
    apiUpdateEntity("tree_pages", nodeSelected.id, {name: value}, response => {
      setNeedUpdate(needUpdate + 1)
      setNodeSelected(null)
      setValueEdit("")
    })
  }

  function remove() {
    setLoading(true)
    apiDeleteEntity("tree_pages", nodeSelected.id, response => {
      setNeedUpdate(needUpdate + 1)
      setNodeSelected(null)
      setValueEdit("")
    })
  }

  function showDeleteConfirm() {
    confirm({
      title: 'Etes-vous sûr de vouloir supprimer cette page?',
      icon: <ExclamationCircleOutlined/>,
      content: 'Attention tous les enfants de cette page seront supprimés aussi',
      okText: 'Confirmer',
      okType: 'danger',
      cancelText: 'Retour',
      onOk() {
        remove()
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  const [options, setOptions] = useState([]);
  const [searchPage, setSearchPage] = useState(null);

  function onSearch(q) {
    setOptions([])
    clearTimeout(searchPage)
    setSearchPage(setTimeout(async () => {
      const data = {
        filters: [
          {name: 'name', value: q}
        ]
      }

      apiFetchCollection("tree_pages", data, response => {
        if (response && response["@type"] === "hydra:Collection") {
          const predictions = response["hydra:member"].map(r => {
            return {
              key: r.id,
              value: r.name
            }
          })
          setOptions(predictions.filter((prediction, index, self) =>
            index === self.findIndex((t) => (
              t.value === prediction.value
            ))
          ))
        }
      })
    }, 500))
  }

  function setValueLanguage(newValue) {
    value[currentLng] = newValue
    setValue({...value});
  }

  const border = {border: '2px solid orange'}

  return (
    <Spin spinning={loading}>
      <div className="flex space-between">
        <div>
          <div>
            <Space>
              <div
                className="cursor-p"
                onClick={() => setCurrentLng('fr')}
                style={currentLng === "fr" ? {transform: "scale(1.2"} : null}
              >
                <Fr style={currentLng === 'fr' ? border : null}/>
              </div>
              <div
                className="cursor-p"
                onClick={() => setCurrentLng('en')}
                style={currentLng === "en" ? {transform: "scale(1.2"} : null}
              >
                <Gb style={currentLng === 'en' ? border : null}/>
              </div>
            </Space>
          </div>
          <AutoComplete
            options={options}
            onSearch={onSearch}
            onSelect={setValueLanguage}
            value={value[currentLng]}
          >
            <Input.Search
              onChange={e => setValueLanguage(e.target.value)}
              className="w-300px"
              onSearch={addNew}
              enterButton="Créer"
              allowClear
            />
          </AutoComplete>
        </div>
        {
          nodeSelected
            ?
            <div>
              <Input.Search
                className="w-300px"
                value={valueEdit}
                onChange={(e) => setValueEdit(e.target.value)}
                enterButton="Modifier"
                onSearch={edit}
              />
              <Button
                onClick={showDeleteConfirm}
                type="primary"
                icon={<DeleteOutlined/>}
                danger
              />
            </div>
            : null
        }
      </div>
      <div className="mt-20">
        <Tree
          expandedKeys={expanded}
          className="draggable-tree"
          draggable
          blockNode
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          treeData={tree}
          onSelect={onSelect}
          onExpand={toggleExpand}
        />
      </div>
    </Spin>
  );

}


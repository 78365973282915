import React, {useState, useEffect} from 'react';
import {Table} from 'antd';
import {useApiContext} from '../providers/ApiProvider.js';

const ApiCollectionTable = ({endpoint, columns, filtres, reload, pageSize, displayTotal = false}) => {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: pageSize ? pageSize : 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '25', '50', '100'],
    position: 'bottom',
  })
  const [loading, setLoading] = useState(false)

  // internal trigger to force natural data fetch based on state change
  const [needUpdate, setNeedUpdate] = useState(0)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetch({
      itemsPerPage: pagination.pageSize,
      page: pagination.current,
    });
  }, [needUpdate, filtres, reload])

  function handleTableChange(table_pagination, filters, sorter) {
    // update current pagination
    const pager = {...table_pagination};
    pager.current = table_pagination.current;
    setPagination(pager)
    // trigger a data refresh
    setNeedUpdate(needUpdate + 1);
  };

  function fetch(params) {
    setLoading(true)
    params.filters = filtres;
    apiFetchCollection(endpoint, params, (hydra_response) => {
      const pager = {...pagination};
      pager.total = hydra_response['hydra:totalItems'];
      setData(hydra_response['hydra:member'])
      setPagination(pager)
      setLoading(false);
    });
  }

  return (
    <div>
      {displayTotal ? <div className="flex end">Total de resultats : {pagination.total}</div> : null}
      <Table
        className="ApiCollectionTable"
        columns={columns}
        rowKey={record => record['@id']}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default ApiCollectionTable;

import React from "react";
import {Button, Space} from "antd";
import Fr from "../Svg/Flag/Fr";
import Gb from "../Svg/Flag/Gb";

export default function LanguageChoice({language, setLanguage}) {

  const border = {border: '2px solid orange'}
  const underlined = {borderBottom: '2px solid orange'}

  return (
    <div className="flex">
      <Space>
        <div
          className="cursor-p"
          onClick={() => setLanguage('fr')}
          style={language === "fr" ? {transform: "scale(1.2"} : null}
        >
          <Fr style={language === 'fr' ? border : null}/>
        </div>
        <div
          className="cursor-p"
          onClick={() => setLanguage('en')}
          style={language === "en" ? {transform: "scale(1.2"} : null}
        >
          <Gb style={language === 'en' ? border : null}/>
        </div>

        <div>
          <Button
            className="flex align-center"
            style={language === '' ? border : null}
            type="link" onClick={() => setLanguage('')}>
            Voir tout
          </Button>
        </div>
      </Space>
    </div>
  )
}

import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import SerpOverview from "../components/SEOFollowUp/serp/SerpOverview";
import {useApiContext} from "../providers/ApiProvider";
import {Layout} from "antd";
import {decryptId} from "../Helpers";

const SerpOverviewPublic = () => {

  const [apiDispatch] = useApiContext();
  const {publicFetch} = apiDispatch;
  const params = useParams();

  const [account, setAccount] = useState();
  const accountId = decryptId(params.id);

  useEffect(() => {
    fetch()
  }, [])

  async function fetch() {
    const response = await publicFetch(`public/account/${accountId}`, {});

    if (response['hydra:member'])
      setAccount(response['hydra:member']);
  }

  return (
    <Layout className="root-layout">
      <Layout>

        <Layout.Header
          className="site-layout-background flex space-between"
          style={{backgroundColor: "#fff"}}
        >
          <div className="flex align-baseline">
            <h2>{account?.name}</h2>
          </div>
        </Layout.Header>


        <SerpOverview accountId={accountId}/>

      </Layout>
    </Layout>
  );
};

export default SerpOverviewPublic;

import React, {useEffect, useRef, useState} from "react"
import {Button, Select, Progress, message, Input, Collapse} from "antd"
import {DeleteTwoTone, LoadingOutlined} from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import {copy} from "../../Helpers";

const {Option} = Select;

export default function StepTwo({
                                  valueOld,
                                  setValueOld,
                                  valueNew,
                                  setValueNew,
                                  result,
                                  setResult,
                                  oldSort,
                                  setOldSort,
                                  newSort,
                                  setNewSort,
                                  languages,
                                  oldLanguages,
                                  goStep,
                                  apiPostEntity,
                                  saveUpdate,
                                  devDomain
                                }) {

  const [tampon, setTampon] = useState([])
  const [tamponShiftKey, setTamponShiftKey] = useState(-1)
  const resultText = useRef(null)

  const [translate, setTranslate] = useState([])
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [messageErrTranslate, setMessageErrTranslate] = useState("")

  const [highlightWords, setHighlightWords] = useState([])

  const [oldFilter, setOldFilter] = useState("");
  const [newFilter, setNewFilter] = useState("");

  const [nonSortedOld, setNonSortedOld] = useState([]);

  const [oldLanguageSort, setOldLanguageSort] = useState([])
  const [newLanguageSort, setNewLanguageSort] = useState([])

  useEffect(() => {
    if (!oldSort.length) {
      autoSort()
    } else {
      displayResult()
    }
  }, [])

//console.log(oldSort);
//valueOld.split('\n').filter(v => { console.log(v); v && !oldSort.includes(v)})
//	valueOld.split('\n').filter(v => v && !oldSort.includes(v))
//  setNonSortedOld()

  function displayResult() {
    let arr = []
    oldSort.map((o, id) => {
      arr.push({old: o, new: newSort[id]})
    })

    setResult(arr)
  }

  async function runTranslate(olds, news) {
    setLoading(true);
    const urls = makeUrls(olds, news);
    const needTranslate = oldLanguages.filter(l => l !== "fr" && l !== "en").concat(languages.filter(l => l !== "fr" && l !== "en"));

    const total = urls.length;
    let count = 0;

    let result = []
    const translated = urls.map(async url => {
      let lng = url.split("//").pop().split("/")[1]

      if (needTranslate.includes(lng)) {
        const data = {
          url: url
        }
        await apiPostEntity("translate-external", data, response => {
          response.url = url
          result.push(response)
          count++
          setProgress(count * 100 / total)
          setLoading(false)
        })
      }
    })
    Promise.all(translated).then(() => {
      if (result.length) {
        setTranslate(result)
      } else {
        setMessageErrTranslate("Pas de traduction")
        setLoading(false)
      }
    })
  }

  function makeUrls(olds, news) {
    const needTranslateOlds = oldLanguages.filter(l => l !== "fr" && l !== "en");
    const needTranslateNew = languages.filter(l => l !== "fr" && l !== "en");

    let ntolds = olds.map(o => {
      let lng = o.split("//").pop().split("/")[1]
      if (needTranslateOlds.includes(lng))
        return o;
    })

    let ntnews = news.map(n => {
      let lng = n.split("//").pop().split("/")[1]
      if (needTranslateNew.includes(lng))
        return n;
    })

    return ntnews.concat(ntolds).filter(Boolean);
  }

  function addShiftSelection(sort, id) {
    var min = id < tamponShiftKey ? id : tamponShiftKey
    var max = id > tamponShiftKey ? id + 1 : tamponShiftKey + 1
    var arr
    var result = []

    if (sort) {
      arr = oldSort.slice(min, max)
    } else {
      arr = valueOld.split("\n").filter(v => {
        let lng = v.split("//")[1].split('/').filter(r => r.length === 2)[0] || "other";
        if (oldFilter)
          return (!oldLanguageSort.length || oldLanguageSort.includes(lng)) && v !== "" && !oldSort.includes(v) && v.includes(oldFilter)

        return (!oldLanguageSort.length || oldLanguageSort.includes(lng)) && v !== "" && !oldSort.includes(v)
      }).slice(min, max)
    }

    arr.map((a, index) => {
      result.push({id: min + index, value: a, sort: sort})
    })

    setTampon(result)
  }

  function selectOld(value, id, sort, e) {
    if (e.shiftKey && e.shiftKey !== tamponShiftKey) {
      if (tamponShiftKey < 0) {
        setTamponShiftKey(id)
      } else if (tampon[tampon.length - 1].sort === sort) {
        addShiftSelection(sort, id)
      }
    } else if (e.ctrlKey) {
      if (tampon.filter(t => t.value === value).length === 1) {
        setTampon(tampon.filter(t => t.value !== value))
        setHighlightWords([])
      } else {
        setTampon([...tampon, {id: id, value: value, sort: sort}])
        getHighlightedText(value)
      }
      setTamponShiftKey(id)
    } else {
      if (tampon.some(t => t.value === value)) {
        setTampon([])
        setHighlightWords([])
      } else {
        setTampon([{id: id, value: value, sort: sort}])
        getHighlightedText(value)
      }
      setTamponShiftKey(id)
    }
  }

  function getHighlightedText(text, translateArr = null) {
    const last = text.split('/').pop().split(".")[0]

    if (last) {
      var params = last.split('-').filter(l => l.length > 1)
      if (translateArr) {
        if (params.length) {
          params = params.concat(translateArr)
        } else {
          params = translateArr
        }
      }
      setHighlightWords(params)
    }
  }

  function selectNew(value) {
    if (tampon.length > 0) {
      let needLoadSort = false
      let needLoadNoSort = false

      let oS = oldSort
      let nS = newSort
      let vO = [...valueOld.split('\n')]

      tampon.map(t => {
        if (t.sort) {
          needLoadSort = true
          nS[t.id] = value
        } else {
          needLoadNoSort = true
          oS.push(t.value)
          nS.push(value)
          vO = vO.filter(a => a !== t.value)
        }
      })

      if (needLoadSort && !needLoadNoSort) {
        setNewSort(nS)
      } else {
        setNewSort(nS)
        setOldSort(oS)
        setValueOld(vO.join('\n'))
      }

      saveUpdate(vO.join('\n'), oS, nS)
      setTampon([])
      loadResult(oS, nS)
      setTamponShiftKey(-1)

      message.success("URL triée")
    }
  }

  function loadResult(olds, news) {
    setResult([])
    let arr = []
    olds.map((o, id) => {
      arr.push({old: o, new: news[id]})
    })
    setResult(arr)
  }

  function autoSort() {
    if (valueOld) {
      console.time("sort")
      let VOLD = valueOld.split("\n");
      let VNEW = valueNew.split("\n")
      let OLDS = []
      let NEWS = []

      VOLD.map(v => {

        let params = v.replace(/https?:\/\//g, '').split("/")
        params.shift()
        let lng = "fr"

        if (!!params.length && params[0].length === 2) {
          lng = params[0]
        }

        // on check si la fin de l'url et la langue match
        params = !!params.length ? params.pop().split(".")[0] : null
        if (params && params.length > 2) {
          let result = VNEW.filter(n => {
            let matcher = new RegExp(lng + ".*" + params.replace(/[0-9]-?/g, ''), "g")
            if (matcher.test(n)) {
              return n
            }
          })

          if (result.length === 1) {
            OLDS.push(v)
            NEWS.push(result[0])
          }
        }

        // on check si c'est un diapo full screnn et si ca match avec photo ou galerie et langue a prendre en compte
        let matchDiapo = new RegExp(lng + ".*\/diaporama-fullscreen", "g")
        if (matchDiapo.test(v)) {
          let trad = lng === "fr" ? "galerie" : "gallery"
          let lngMatch = languages.includes(lng) ? lng : "en"
          let matcher = new RegExp("/" + lngMatch + "/" + ".*(photo|" + trad + ")")
          let result = VNEW.filter(n => {
            if (matcher.test(n)) {
              return n
            }
          })

          if (result.length === 1) {
            OLDS.push(v)
            NEWS.push(result[0])
          }
        }

        // Redirige page d'accueil avec prise en compte des langues
        let splitUrl = v.split("//")[1].split("/")
        if (splitUrl.length <= 3) {
          const regex = /^(www.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
          if (regex.test(splitUrl[0])) {
            const basepath = "https://" + VNEW[0].split("//")[1].split("/")[0] + "/"
            if (!splitUrl[1]) {
              let index;
              if (VNEW.includes(basepath)) {
                index = VNEW.indexOf(basepath)
              } else {
                index = VNEW.push(basepath) - 1
              }

              OLDS.push(v)
              NEWS.push(VNEW[index])
            }

            if (splitUrl['1'] && splitUrl[1].length === 2 && !splitUrl[2]) {
              const lngMatch = languages.includes(lng) ? lng : "en"
              let index;
              if (VNEW.includes(basepath + lngMatch + "/")) {
                index = VNEW.indexOf(basepath + lngMatch + "/")
              } else {
                index = VNEW.push(basepath + lngMatch + "/") - 1
              }

              OLDS.push(v)
              NEWS.push(VNEW[index])
            }
          }
        }
      })

      if (OLDS.length > 0) {
        setOldSort(OLDS)
        setNewSort(NEWS)
        setValueNew(VNEW.join("\n"))
        reloadValueOld(OLDS)
        loadResult(OLDS, NEWS)
      }
      console.timeEnd("sort")
    }
  }

  function reloadValueOld(old) {
    let Tab = valueOld.split("\n")
    Tab = Tab.filter(t => !old.includes(t))
    setValueOld(Tab.join('\n'))
  }

  function deleteRow(index) {
    setValueOld(valueOld + "\n" + oldSort[index])
    setOldSort(oldSort.filter((o, id) => id !== index))
    setNewSort(newSort.filter((n, id) => id !== index))
    setResult(result.filter((r, id) => id !== index))
  }

  function highlightTranslate(value, id, translateArr) {
    if (tampon.some(t => t.value === value)) {
      setTampon([])
      setHighlightWords([])
    } else {
      setTampon([{id: id, value: value, sort: false}])
      getHighlightedText(value, translateArr)
    }
    setTamponShiftKey(id)
  }

  function goToDeepl(text) {
    copy(text)
    window.open("https://www.deepl.com/", "_blank")
  }

  const [height, setHeight] = useState('80vh')
  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [window.innerHeight])

  function updateHeight() {
    const mainDiv = document.getElementById("step-two-redir").offsetTop;
    setHeight((window.innerHeight - mainDiv) - 80);
  }

  const pannelHeader = (
    <div>
      <h4>Trié(s)
        ({`${oldSort.length}/${oldSort.length + valueOld.split('\n').filter(v => v && !oldSort.includes(v)).length}`})</h4>
      {oldSort.length === 0 ?
        <span><em>(Vide)</em></span> : null}
    </div>
  )

  return (
    <div id="step-two-redir" style={{width: 'auto', whiteSpace: 'nowrap', height, fontSize: "1em"}}>
      <div className="w-80vw flex space-between">
        <Button type="primary" onClick={() => goStep(0)}>Etape précédente</Button>
        <div>
          <Button
            onClick={() => runTranslate(valueOld.split('\n'), valueNew.split('\n'))}
          >
            Traduction
          </Button>&nbsp;
          {loading ? <LoadingOutlined/> : null}
          {progress ? <Progress percent={Math.round(progress)} steps={5}/> : null}
          {messageErrTranslate ? messageErrTranslate : null}
        </div>
        <Button type="primary" onClick={() => goStep(2)}>Etape suivante</Button>
      </div>

      <Collapse className="mt-20">
        <Collapse.Panel key={1} header={pannelHeader}>
          <div className="flex">
            <div className="margin-10 no-select">
              {oldSort.map((old, id) => (
                <div
                  className="margin-5 flex"
                  key={id}
                >
                  <div style={{padding: "0 10px"}}>
                    <DeleteTwoTone onClick={() => deleteRow(id)} twoToneColor="#eb2f96"/>
                  </div>
                  <div
                    style={tampon.filter(t => t.value === old).length === 1 ? {backgroundColor: "#7cb342"} : null}
                    onClick={(e) => selectOld(old, id, true, e)}
                    className="url-redir success cursor-p padding-side-5"
                  >
                    {old}
                  </div>
                  <div className="m-left-10">
                    <a href={old} target="_blank">go to url</a>
                  </div>
                </div>
              ))}
            </div>
            <div className="margin-10 no-select">
              {newSort.map((n, id) => (
                <div
                  className="margin-5 flex"
                  key={id}
                >
                  <div
                    onClick={() => selectNew(n)}
                    className="url-redir success cursor-p padding-side-5"
                  >
                    {n}
                  </div>
                  <div className="m-left-10">
                    <a href={n} target="_blank">go to url</a>
                  </div>
                </div>
              ))}
            </div>
            <div ref={resultText} className="margin-10">
              {result.map((r, id) => (
                <div key={id} className="margin-5 success padding-side-5">"{r.old}" "{r.new}"</div>
              ))}
            </div>
          </div>
        </Collapse.Panel>

      </Collapse>
      <div className="w-80vw flex justify-center no-select">
        <h3 className="text-center margin-0">
          A Trier&nbsp;
          {valueOld.split("\n").filter(v => v !== "" && !oldSort.includes(v)).length === 0 ?
            "Tout est trié !"
            : `(Reste ${oldLanguageSort.length || oldFilter ? ` - ${valueOld.split('\n').filter(v => {
              let lng = v.split("//")[1].split('/').filter(r => r.length === 2)[0] || "other";
              if (oldFilter)
                return (!oldLanguageSort.length || oldLanguageSort.includes(lng)) && v !== "" && !oldSort.includes(v) && v.includes(oldFilter)

              return (!oldLanguageSort.length || oldLanguageSort.includes(lng)) && v !== "" && !oldSort.includes(v)
            }).length} / ` : ''}${valueOld.split("\n").filter(v => v !== "" && !oldSort.includes(v)).length})`}
        </h3>
      </div>
      <div className="flex">
        <div className="margin-10 no-select">
          <Input
            style={{width: 200}}
            placeholder="filtrer"
            value={oldFilter}
            onChange={e => setOldFilter(e.target.value)}
            allowClear
          />
          <Select
            style={{width: 200, marginLeft: 5}}
            mode="multiple"
            placeholder="Filtrer par langue"
            value={oldLanguageSort}
            onChange={values => setOldLanguageSort(values)}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {oldLanguages.map(l => (
              <Option key={l} children={l} value={l}/>
            ))}
          </Select>
          {valueOld  ? valueOld.split('\n').filter(v => {
            let lng = v.split("//")[1].split('/').filter(r => r.length === 2)[0] || "other";
            if (oldFilter)
              return (!oldLanguageSort.length || oldLanguageSort.includes(lng)) && v !== "" && !oldSort.includes(v) && v.includes(oldFilter)

            return (!oldLanguageSort.length || oldLanguageSort.includes(lng)) && v !== "" && !oldSort.includes(v)
          }).map((old, id) => (
            <div
              className="margin-5 flex"
              key={id}
            >
              <div
                style={tampon.filter(t => t.value === old).length === 1 ? {backgroundColor: "#eee"} : null}
                onClick={(e) => selectOld(old, id, false, e)}
                className="url-redir cursor-p padding-side-5"
              >
                <Highlighter
                  highlightClassName="MyHighlightClass"
                  searchWords={tampon.some(t => t.value === old) ? highlightWords : []}
                  autoEscape={true}
                  textToHighlight={old}
                />
              </div>
              <div className="m-left-10">
                <a href={old} target="_blank">go to url</a>
              </div>
              <div>
                {translate.some(t => t.url === old)
                  ?
                  translate.some(t => t.url === old && t.translation) ?
                    <div>
                      <Highlighter
                        onClick={() => highlightTranslate(old, id, translate.filter(t => t.url === old)[0].translation.split(" "))}
                        className="m-left-10 cursor-p"
                        highlightClassName="MyHighlightClass"
                        searchWords={tampon.some(t => t.value === old) ? highlightWords : []}
                        autoEscape={true}
                        textToHighlight={translate.filter(t => t.url === old)[0].translation}
                      />&nbsp;&nbsp;
                      <a onClick={() => goToDeepl(translate.filter(t => t.url === old)[0].originalText)}>go to deepl</a>
                    </div>
                    : <span className="m-left-10 danger">{translate.filter(t => t.url === old)[0].message}</span>
                  : null}
              </div>
            </div>
          )) : null}
        </div>
        <div className="margin-10 no-select" style={{position: "relative"}}>
          <Input
            style={{width: 200}}
            placeholder="filtrer"
            value={newFilter}
            onChange={e => setNewFilter(e.target.value)}
            allowClear
          />
          <Select
            style={{width: 200, marginLeft: 5}}
            mode="multiple"
            placeholder="Filtrer par langue"
            value={newLanguageSort}
            onChange={values => setNewLanguageSort(values)}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {languages.map(l => (
              <Option key={l} children={l} value={l}/>
            ))}
          </Select>
          {valueNew.split('\n').filter(n => {
            let lng = n.split("//")[1].split('/').filter(r => r.length === 2)[0] || "other";

            if (newFilter)
              return (!newLanguageSort.length || newLanguageSort.includes(lng)) && n.includes(newFilter)

            return !newLanguageSort.length || newLanguageSort.includes(lng);
          }).map((n, id) => (
            <div
              className="margin-5 flex"
              key={id}
            >
              <div
                onClick={() => selectNew(n)}
                className="url-redir cursor-p padding-side-5"
              >
                <Highlighter
                  highlightClassName="MyHighlightClass"
                  searchWords={highlightWords}
                  autoEscape={true}
                  textToHighlight={n}
                />
              </div>
              <div className="m-left-10">
                <a href={devDomain ? n.replace(n.split('/')[2], devDomain) : n} target="_blank">go to url</a>
              </div>
              <div>
                {translate.some(t => t.url === n)
                  ?
                  translate.some(t => t.url === n && t.translation) ?
                    <div>
                      <Highlighter
                        className="m-left-10"
                        highlightClassName="MyHighlightClass"
                        searchWords={highlightWords}
                        autoEscape={true}
                        textToHighlight={translate.filter(t => t.url === n)[0].translation}
                      />&nbsp;&nbsp;
                      <a onClick={() => goToDeepl(translate.filter(t => t.url === n)[0].originalText)}>go to deepl</a>
                    </div>
                    : <span className="m-left-10 danger">{translate.filter(t => t.url === n)[0].message}</span>
                  : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

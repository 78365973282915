import React from 'react';
import {Link, useLocation} from 'react-router-dom'
import {Menu} from 'antd';
import {
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
  TagsOutlined,
  HddOutlined,
  TeamOutlined,
  AreaChartOutlined
} from '@ant-design/icons';
import {useAuthContext} from "../../providers/AuthProvider";

const NavPrivate = () => {

  const [authState] = useAuthContext();
  const {auth} = authState;
  const location = useLocation()

  return (
    <Menu
      mode="inline"
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/dashboard">
        <DashboardOutlined/>
        <span>Dashboard</span>
        <Link to="/dashboard"/>
      </Menu.Item>
      <Menu.Item key="/establishments">
        <HddOutlined/>
        <span>Etablissements</span>
        <Link to="/establishments"/>
      </Menu.Item>
      <Menu.Item key="/mots-cles">
        <TagsOutlined/>
        <span>Mots-clés</span>
        <Link to="/mots-cles"/>
      </Menu.Item>
      <Menu.Item key="/combinations-list">
        <AreaChartOutlined/>
        <span>Combinaisons</span>
        <Link to="/combinations-list"/>
      </Menu.Item>
      <Menu.Item key="/user">
        <UserOutlined/>
        <span>Profil</span>
        <Link to="/user"/>
      </Menu.Item>
      {auth.roles.includes("ROLE_ADMIN") ?
        <Menu.Item key="/users">
          <TeamOutlined/>
          <span>Utilisateurs</span>
          <Link to="/users"/>
        </Menu.Item> : null}
      <Menu.Item key="/logout">
        <LogoutOutlined/>
        <span>Déconnexion</span>
        <Link to="/logout"/>
      </Menu.Item>
    </Menu>
  );
};

export default NavPrivate;

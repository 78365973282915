import React, { useState, useRef } from 'react';
import { Redirect } from 'react-router-dom'
import { Form, Icon, Input, Checkbox, Button, Spin, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthContext } from '../providers/AuthProvider.js';
import { useApiContext } from '../providers/ApiProvider.js';

const Login = (props) => {

  const [apiDispatch] = useApiContext();
  const { postRequestBasic } = apiDispatch;
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { authLogin, authIsLogged } = authDispatch;

  const [processing, setProcessing] = useState(false);

  const handleSubmit = values => {
        setProcessing(true);
        // console.log('Received values of form: ', values);
        authLogin(values.username, values.password, (response) => {
          if (response.error) {
            message.error(response.error)
            setProcessing(false)
          }
        })
  }

  return (
    <div id="login-form">
      <h2>Connexion</h2>
      {
        authIsLogged()
          ? <Redirect to="/dashboard"/>
          : (
            <Spin tip='Connexion en cours ...' spinning={processing}>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          )
      }
    </div>
  );
};

export default Login;

import React, {useEffect, useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import {Button, Space, Table, Tag} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import ModalResultCombination from "./modal/ModalResultCombination";

const NewCombinationChoice = ({rerender}) => {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiPostEntity} = apiDispatch;
  const [accountState] = useAccountContext();
  const {account} = accountState;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loadingResult, setLoadingResult] = useState(false);
  const [result, setResult] = useState([]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchKeyWords()
  }, [])

  function fetchKeyWords() {
    apiFetchCollection("keywords_by_etb", {
      filters: [{
        name: "etbId",
        value: account.id
      }]
    }, response => {
      hydrateCategories(response['hydra:member'])
      setLoading(false)
    })
  }

  function hydrateCategories(data) {
    let _categories = [];
    data.map(d => {
      if (!d.category)
        _categories.push({id: "none", name: "Non catégorisé", keyWords: []})
      else if (!_categories.some(category => category.id === d.category.id))
        _categories.push({...d.category, keyWords: []})
    })

    data.map(d => {
      let index = _categories.findIndex(category => d.category && category.id === d.category.id)
      if (index === -1)
        index = _categories.findIndex(category => category.id === "none")

      _categories[index].keyWords.push({'@id': d['@id'], id: d.id, name: d.name})
    })

    setCategories(_categories)
  }

  const columns = [
    {
      title: "Categorie",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Mots clés",
      dataIndex: "keyWords",
      render: keyWords => (
        <Space>
          {keyWords.map(keyWord => (
            <Tag key={keyWord.id}>{keyWord.name}</Tag>
          ))}
        </Space>
      )
    }
  ]

  function generate() {
    setLoadingResult(true)
    const data = {
      etbId: account.id,
      combis: [selectedRowKeys],
      autoPush: false
    }

    apiPostEntity("combination", data, response => {
      if (response.success) {
        setResult(response.result)
        setVisible(true)
      }

      setLoadingResult(false)
    })
  }

  function toggleCategory(id) {
    if (selectedRowKeys.includes(id))
      setSelectedRowKeys(selectedRowKeys.filter(key => key !== id));
    else
      setSelectedRowKeys([...selectedRowKeys, id]);
  }

  function rowEvents(record, rowIndex) {
    return {
      onClick: () => toggleCategory(record.id)
    }
  }

  function onClose() {
    setSelectedRowKeys([]);
    setResult([]);
    rerender();
  }

  return (
    <div className="contenu-tab">
      <div className="flex space-between m-topbot-20">
        <div>
          <Space>
            {selectedRowKeys.map(catId => (
              <Tag key={catId} color="geekblue">
                {categories.find(category => category.id === catId).name}
              </Tag>
            ))}
          </Space>
        </div>

        <Button
          onClick={generate}
          disabled={selectedRowKeys.length < 2}
          type="primary"
          icon={<EyeOutlined/>}
          loading={loadingResult}
        >
          Voir résultats
        </Button>
      </div>
      <Table
        loading={loading}
        rowClassName="cursor-p"
        onRow={rowEvents}
        rowSelection={{
          selectedRowKeys,
          onChange: values => setSelectedRowKeys(values)
        }}
        rowKey="id"
        dataSource={categories}
        columns={columns}
        pagination={false}
      />

      {visible && result.length ?
        <ModalResultCombination
          visible={visible} setVisible={setVisible}
          result={result} combis={[selectedRowKeys]}
          onClose={onClose}
        /> : null}
    </div>
  );
};

export default NewCombinationChoice;

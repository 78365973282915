import React, {useState} from "react"
import {Progress, Button, message} from "antd"
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";

export default function CheckRedirections({finalResult}) {

  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();

  const resultArray = finalResult.split('\n').filter(Boolean);
  const total = resultArray.length;

  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  function checkRedir() {
    let nbResponse = 0;

    if (total)
      setLoading(true);


    // recuperation of url and checking if url is shortened to be able to check the redirections properly
    let accountUrl = accountState.account.url ? accountState.account.url.replace(/\/$/,"").replace(/https?:\/\//, "") : null
    let isShortenUrl = resultArray[0].split(' ')[0].replace(/"/g, '').startsWith('/')

    if (isShortenUrl && !accountUrl) {
      message.error('Impossible de tester les urls simplifié, ce compte n\'a pas d\'url')
      setLoading(false);
      return
    }

    resultArray.map((row, id) => {
      let [baseUrl, redirUrl] = row.split(' ');

      if (baseUrl === '"/"')
        return nbResponse++;


      baseUrl = baseUrl.replace(/"/g, '')
      redirUrl = redirUrl.replace(/"/g, '')


      // shorthened url usually starts with a / so we had the domain name before so it works normally
      const data = {
        baseUrl: baseUrl.startsWith('/') ? accountUrl+baseUrl : baseUrl,
        redirUrl: redirUrl.startsWith('/') ? accountUrl+redirUrl : redirUrl
      };


      apiPostEntity("check-redirection", data, response => {

        if (!response.success)
          setErrors(errors => [...errors, {line: (id + 1), baseUrl: data.baseUrl,message: response.message}])

        nbResponse++;
        setPercent(Math.round(nbResponse * 100 / total));

        if (nbResponse >= total)
          setLoading(false);
      })
    })
  }

  return (
    <div>
      <Button
        type="primary"
        className="mt-20"
        onClick={checkRedir}
        loading={loading}
      >
        Check les redirections
      </Button>

      {percent ? <Progress className="m-left-15" style={{width: 300}} percent={percent}/> : null}

      {percent === 100 && !errors.length ?
        <h3 className="mt-20" style={{color: "green"}}>Les redirections sont correctes</h3> : null}

      <div className="mt-20">
        {errors.map((error, id) => (
          <div key={id} style={{color: "red", padding: 5}}>
            ERROR (l.{error.line}):&nbsp;
            <a target="_blank" href={error.baseUrl}>{error.baseUrl}</a>&nbsp;
            <span dangerouslySetInnerHTML={{__html: error.message}}/>
          </div>
        ))}
      </div>
    </div>
  )
}

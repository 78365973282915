import React, {useState, useEffect} from 'react'
import {Modal, Button, Input, Switch, message, Select} from 'antd'

export default function ModalCategory({visible, setVisible, apiPostEntity, rerender, catToUpdate, setCatToUpdate, apiUpdateEntity}) {

	const [loading, setLoading] = useState(false)
	const [name, setName] = useState("")
	const [geoLoc, setGeoLoc] = useState(false)
	const [filterType, setFilterType] = useState(null)
	const [errorMessage, setErrorMessage] = useState("")

	useEffect(() => {
		if (catToUpdate) {
			setName(catToUpdate.name)
			if (catToUpdate.geoLoc) { setGeoLoc(catToUpdate.geoLoc) }
			if (catToUpdate.geoLocFilterType) { setFilterType(catToUpdate.geoLocFilterType) }
		}
	}, [catToUpdate])

	function handleCancel() {
		setVisible(false)
		setName("")
		setErrorMessage("")
		setLoading(false)
    if (catToUpdate) {
      setCatToUpdate(null)
    }
		setGeoLoc(false)
		setFilterType(null)
	}

	function handleOk() {
		setLoading(true)
		if (!name || name.length < 2) { setErrorMessage("Champ non valide"); return; }

		const data = { name: name, geoLoc: geoLoc, geoLocFilterType: filterType };
		if (catToUpdate) {
			apiUpdateEntity("categories", catToUpdate.id, data, response => {
				if (response["@type"] === "ConstraintViolationList") {
					setErrorMessage(response["hydra:description"]);
					setLoading(false)
					return;
				}
				//console.log(response)
				handleCancel()
				rerender()
			})
		} else {
			apiPostEntity("categories", data, response => {
				if (response["@type"] === "ConstraintViolationList") {
					setErrorMessage(response["hydra:description"]);
					setLoading(false)
					return;
				}
				//console.log(response)
				handleCancel()
				rerender()
			})
		}
	}

	return (
		<Modal
		    visible={visible}
	        title="Catégorie"
	        onOk={handleOk}
	        onCancel={handleCancel}
	       	footer={[
	            <Button key="back" onClick={handleCancel}>
	                Retour
	            </Button>,
	            <Button key="submitClose" type="primary" loading={loading} onClick={handleOk}>
	                Enregistrer
	            </Button>
	        ]}
		>
			<div style={{color: "red"}}>{errorMessage}</div>
			<Input className="margin-5" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nom la catégorie (obligatoire)" />
			Géolocalistation : <Switch className="margin-5" checked={geoLoc} onChange={(e) => setGeoLoc(e) }/>
			{
				geoLoc ?
					<div className="margin-5">
						Type de filtre :&nbsp;&nbsp;&nbsp;&nbsp;
						<Select
							style={{width: 200}}
							defaultValue={filterType}
							onChange={(value) => setFilterType(value)}
						>
				            <Select.Option value={1}>Latitude longitude</Select.Option>
				            <Select.Option value={2}>Ville</Select.Option>
				        </Select>
				    </div>
				: null
			}
		</Modal>
	)
}

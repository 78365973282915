import React, {useState} from "react";
import {Button, Form, Input, Modal, Space, Tooltip} from "antd";
import {PlusOutlined, QuestionCircleTwoTone} from "@ant-design/icons";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalKeyWordSimplify({reload}) {

  const [accountState] = useAccountContext();
  const {account} = accountState;

  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  function handleOk() {
    form.validateFields()
      .then(values => {
        setLoading(true);
        values.establishments = [account['@id']];

        apiPostEntity("key_words", values, response => {
          reload()
          form.resetFields();
          setLoading(false);
          setVisible(false);
        })
      })
  }

  return (
    <>
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined/>}
          onClick={() => setVisible(true)}
        >
          Ajouter
        </Button>
        <Tooltip placement="left" title="Ajouter un mot clé spécifique a cet établissement">
          <QuestionCircleTwoTone className="cursor-p"/>
        </Tooltip>
      </Space>

      <Modal
        title="Mot clé"
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        okText="Valider"
        cancelText="Annuler"
        confirmLoading={loading}
      >

        <Form
          form={form}
        >
          <Form.Item
            label="Nom"
            name="name"
            rules={[{required: true, message: "Le nom est obligatoire"}]}
          >
            <Input/>
          </Form.Item>
        </Form>

      </Modal>
    </>
  )
}

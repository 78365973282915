import React, {useEffect, useState} from "react";
import {stringDateMY} from "../../Helpers";
import {Space} from "antd";

export default function ActivePause({etb}) {

  const [inPause, setInPause] = useState(null);

  useEffect(() => {
    let isPause = false;

    etb.pauses.map(pause => {
      let now = new Date();
      let beginAt = new Date(pause.beginAt);
      let finishAt = new Date(pause.finishAt);

      if (now >= beginAt && now <= finishAt && pause.isActive) {
        setInPause(pause);
        isPause = true;
      }
    })

    if (!isPause)
      setInPause(null);
  }, [etb])

  return (
    <Space direction="vertical">
      {etb.isStopped ?
        <div className="warning blink m-topbot-20" style={{fontSize: 16}}>
          Contrat SEO de l'etablissement arreté depuis {stringDateMY(etb.stoppedAt)}
        </div> : null}
      {inPause ?
        <div className="warning blink m-topbot-20" style={{fontSize: 16}}>
          En pause de {stringDateMY(inPause.beginAt)} à {stringDateMY(inPause.finishAt)}
        </div> : null}
    </Space>
  )
}

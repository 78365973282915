import React, {useEffect, useState} from 'react';
import {useSEOContext} from "../../providers/SEOProvider";
import {Form, Input, Modal, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

const ModalHapi = () => {

    const [seoState, seoDispatch] = useSEOContext();
    const {hapi, language} = seoState;
    const {setter} = seoDispatch;
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection, apiUpdateEntity, apiPostEntity} = apiDispatch;

    const [formI18n] = Form.useForm();

    const [page, setPage] = useState({});
    const [loading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const title = hapi.pageId ? 'Editer page' : 'Creer Page';

    useEffect(() => {
      if (hapi.pageId)
        fetch();
      else
        setLoading(false)
    }, [])

    function fetch() {
      const params = {
        filters: [{name: 'lng', value: language}]
      }

      apiFetchCollection(`hapi/seo/page/${hapi.pageId}`, params, response => {
        if (response.status === 'success')
          setPage(response.page)
        setLoading(false)
      })
    }

    function close() {
      setter('hapi', {visible: false, pageId: null});
    }

    function update() {
      setConfirmLoading(true)
      const data = {
        i18n: formI18n.getFieldsValue(),
        withoutExt: true
      }

      apiUpdateEntity(`hapi/seo/page`, `${hapi.pageId}/${language}`, data, response => {
        setConfirmLoading(false)
        close()
      })
    }

    function handleOk() {
      if (hapi.pageId)
        update();
    }

    return (
      <Modal
        width={800}
        title={title}
        visible={hapi.visible}
        onCancel={close}
        onOk={handleOk}
        confirmLoading={confirmLoading}
      >

        <Spin spinning={loading} wrapperClassName="min-height-400">

          {!loading &&
            <Form
              layout="vertical"
              form={formI18n}
              initialValues={{
                title: page?.title,
                url_label: page?.seo?.title,
                url: page?.seo?.url,
                description: page?.seo?.description
              }}
            >

              <Form.Item label="Titre de la page" name="title">
                <Input/>
              </Form.Item>

              <Form.Item label="Libellé du lien" name="url_label">
                <Input/>
              </Form.Item>

              <Form.Item label="Code URL" name="url">
                <Input/>
              </Form.Item>

              <Form.Item label="Description" name="description">
                <Input.TextArea/>
              </Form.Item>

            </Form>}

        </Spin>

      </Modal>
    );
  }
;

export default ModalHapi;

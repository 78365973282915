import React, {useEffect, useState} from 'react';
import {Space, Tooltip} from "antd";
import ModalHistoryFavorite from "../Modal/ModalHistoryFavorite";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

const FavoriteData = ({favorite}) => {

  const [visible, setVisible] = useState(false);
  const [diff, setDiff] = useState(null);
  const rank = favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[0].rank;


  useEffect(() => {
    if (typeof favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[1] === "object") {
      if (isNaN(favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[1].rank))
        setDiff(rank);
      else
        setDiff(favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[1].rank -  rank);
    }
  }, [])


  return (
    <>
      <Tooltip
        title={`Position recherche google (derniere recherce le ${favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[0].date}`}
      >
        <Space>
          <strong onClick={() => setVisible(true)} className="cursor-p">
            {rank}
          </strong>
          {diff && diff > 0 ? <span style={{color: "#3f8600"}}><ArrowUpOutlined/>{diff}</span> : null}
          {diff && diff < 0 ? <span style={{color: "#cf1322"}}><ArrowDownOutlined/>{diff}</span> : null}
        </Space>
      </Tooltip>

      <ModalHistoryFavorite favorite={favorite} visible={visible} setVisible={setVisible}/>
    </>
  );
};

export default FavoriteData;

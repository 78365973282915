import React, { useEffect, useState } from 'react';
import { useAccountContext } from "../../../providers/AccountProvider";
import { useApiContext } from "../../../providers/ApiProvider";
import { Button, message, Space, Table } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, CopyOutlined, FileExcelOutlined } from "@ant-design/icons";
import ModalHistoryFavorite from "../../Modal/ModalHistoryFavorite";
import { encryptId, sluggify } from "../../../Helpers";
import ModalExport from './ModalExport';

const SerpOverview = ({ accountId = null }) => {

  const [accountState] = useAccountContext();
  const { account } = accountState;
  const [apiDispatch] = useApiContext();
  const { publicFetch } = apiDispatch;

  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  const [favoriteSelected, setFavoriteSelected] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    fetch();
  }, [])

  async function fetch() {
    const data = {
      filters: [
        { name: 'etb', value: accountId ? accountId : account.id }
      ]
    }
    const response = await publicFetch("public/public-fav", data)
    setFavorites(response['hydra:member'])
    setLoading(false)
  }

  function sortByRank(a, b) {
    let c = generateRankForSort(a.data);
    let d = generateRankForSort(b.data);

    return c - d;
  }

  function generateRankForSort(data) {
    if (!data.length)
      return 200;
    else if (isNaN(data.sort((a, b) => new Date(b.date) - new Date(a.date))[0].rank))
      return 150;
    else
      return data.sort((a, b) => new Date(b.date) - new Date(a.date))[0].rank;
  }

  const columns = [
    {
      title: "Combinaison",
      dataIndex: "combination",
      sorter: (a, b) => a.combination.result.localeCompare(b.combination.result),
      render: combination => combination.result
    },
    {
      title: "Position",
      dataIndex: "data",
      sorter: sortByRank,
      render: data => {
        let diff = null;
        let rank = data.length ? data.sort((a, b) => new Date(b.date) - new Date(a.date))[0].rank : null;

        if (data.length > 1) {
          if (isNaN(data.sort((a, b) => new Date(b.date) - new Date(a.date))[1].rank))
            diff = rank;
          else
            diff = data.sort((a, b) => new Date(b.date) - new Date(a.date))[1].rank - rank;
        }

        return (
          <Space>
            <span>{rank}{rank === 1 && 'er'}{rank && rank > 1 && 'ème'}</span>
            {diff && diff > 0 ? <span style={{ color: "#3f8600" }}><ArrowUpOutlined />{diff}</span> : null}
            {diff && diff < 0 ? <span style={{ color: "#cf1322" }}><ArrowDownOutlined />{diff}</span> : null}
          </Space>
        )
      }
    }
  ]

  function rowEvents(record, rowIndex) {
    return {
      onClick: (e => handleRowClick(record))
    }
  }

  function handleRowClick(record) {
    setFavoriteSelected(record);
    setVisible(true);
  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '25', '50', '100'],
    position: 'bottom',
  })

  function handleChange(table_pagination) {
    const pager = { ...table_pagination };
    pager.current = table_pagination.current;
    setPagination(pager)
  }

  function handleClick() {
    navigator.clipboard.writeText(link).then(function () {
      /* le presse-papier est correctement paramétré */
      message.success('Lien copié dans le presse papier')
    }, function () {
      /* l'écriture dans le presse-papier a échoué */
      //console.log(('no ok'))
    });
  }

  const link = account ? `https://seomachine.mmcreation.com/seo/${sluggify(account.name)}/${encryptId(account.id)}` : null;



  return (
    <div className="contenu-tab">

      {account &&
        <div className="mb-10 flex end">
          <Space>
            <span>Lien de partage:</span>
            <a target="_blank" href={link}>{link}</a>
            <CopyOutlined onClick={handleClick} />
            {
              !!favorites.length &&
              <Button icon={<FileExcelOutlined />} onClick={() => setIsExporting(true)} />
            }
          </Space>
        </div>}

      <Table
        loading={loading}
        columns={columns}
        dataSource={favorites.sort(sortByRank)}
        rowKey="id"
        pagination={pagination}
        onChange={handleChange}
        onRow={rowEvents}
        rowClassName="cursor-p"
      />

      {visible && favoriteSelected ?
        <ModalHistoryFavorite
          favorite={favoriteSelected} visible={visible}
          setVisible={setVisible} onClose={() => setFavoriteSelected(null)}
        /> : null}

      <ModalExport isVisible={isExporting} setIsVisible={setIsExporting} accountId={accountId ? accountId : account.id} />
    </div>
  );
};

export default SerpOverview;

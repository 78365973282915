import React, {useState} from "react";
import {Button, Checkbox, Input, InputNumber, message, Modal, Popconfirm, Space, Spin, Tooltip} from "antd";
import {useParams} from "react-router-dom";
import {useApiContext} from "../../providers/ApiProvider";
import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  RiseOutlined
} from '@ant-design/icons';
import LanguageChoice from "./LanguageChoice";
const { confirm } = Modal;

export default function MyCombination({myCombis, rerender, favorite, rerenderFav, loading, setLoading, lng, setLng, allFavorite}) {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity, apiDeleteEntity} = apiDispatch;

  const [process, setProcess] = useState(false)
  const [newNote, setNewNote] = useState(0)

  const [manually, setManually] = useState("")

  function update() {
    setProcess(true)
    const ids = myCombis.map(m => m.idCategories.split(' '))

    const data = {
      etbId: params.id,
      combis: ids
    }
    apiPostEntity("combination", data, response => {
      rerender()
      setProcess(false)
    })
  }

  function toggleFav(iri) {

    if (favorite.some(f => f.combination["@id"] === iri)) {
      const fav = favorite.filter(f => f.combination["@id"] === iri).shift()

      if (fav.page)
        return message.warning("Cette combinaison est liées a une page et ne peut etre supprimé");

      if (fav.status === "nextToUse")
        return message.warning("Cette combinaison est dans la liste des prochaines a utliser et ne peut etre supprimé");

      if (fav.status === "notUse")
        return message.warning("Cette combinaison est dans la liste ne pas utliser et ne peut etre supprimé");

      setLoading(true)
      apiDeleteEntity("favorite_combination_key_words", fav.id, response => {
        rerenderFav()
      })
    } else {
      const data = {
        establishment: "/api/establishments/" + params.id,
        combination: iri,
        language: lng ? lng : 'fr'
      }

      setLoading(true)
      apiPostEntity("favorite_combination_key_words", data, response => {
        rerenderFav()
      })
    }
  }

  function note(id) {
    apiUpdateEntity("combination_key_words", id, {note: newNote}, response => {
      rerender()
      rerenderFav()
    })
  }

  function getNoteColor(note) {
    if (!note)
      return;

    if (note > 66)
      return {color: "green"};

    if (note > 33)
      return {color: "orange"};

    return {color: "red"};
  }

  function editFav(value, favorite) {
    if (value === favorite.combination.result)
      return message.warning("Même valeur");

    if (!value || value.length < 5)
      return message.error("Combinaison trop courte");

    setLoading(true);

    const data = {
      account: params.id,
      favorite: favorite.id,
      combination: value
    }

    apiPostEntity("edit-favorite", data, response => {
      if (!response.success)
        return message(response.message);

      rerenderFav()
    });
  }

  function makeEditFav(favorite) {
    return (
      <div style={{color: "rgb(0,0,0,0.6"}}>
        <Input.Search
          defaultValue={favorite.combination.result}
          enterButton="Valider"
          onSearch={(value) => editFav(value, favorite)}
        />
      </div>
    )
  }

  function addManually(value) {
    if (!value || value.length < 5)
      return message.warning("Combinaison trop courte");

    setLoading(true);

    const data = {
      account: params.id,
      combination: value
    }

    apiPostEntity("add-favorite", data, response => {
      if (!response.success)
        return message.error(response.message);

      setManually("");
      rerenderFav()
    })
  }

  function removeOfCombi(catId, cbkwId) {
    setLoading(true);
    const data = combinationSelected.length ? combinationSelected : [{
      categoryId: catId,
      combinationId: cbkwId.split("/").pop()
    }];
    apiPostEntity("remove-combis-kw", data, response => {
      setCombinationSelected([]);
      rerender()
    })
  }

  const [combinationSelected, setCombinationSelected] = useState([])

  function addCombis(categoryId, combinationIri) {
    const combinationId = combinationIri.split("/").pop();

    if (combinationSelected.some(selected => selected.combinationId === combinationId))
      setCombinationSelected(combinationSelected.filter(selected => selected.combinationId !== combinationId))
    else
      setCombinationSelected([...combinationSelected, {categoryId: categoryId, combinationId: combinationId}]);
  }

  function showDeleteConfirm(id) {
    confirm({
      title: 'Etes vous sure de vouloir supprimer cette catégorie ?',
      icon: <ExclamationCircleOutlined />,
      content: 'Vous ne verrez plus apparaitre les combinaisons liées a cette categorie.',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        //console.log('OK');
        removeCategory(id)
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  function removeCategory(id) {
    setLoading(true)
    apiDeleteEntity('combination_categories', id, response => {
      rerender();
    })
  }

  //console.log(favorite)

  return (
    <div className="contenu-tab" style={{overflow: "auto"}}>
      <Spin spinning={loading}>
        <div style={{minWidth: 1450}}>
          <div className="flex end">
            <Button onClick={update} type="primary" loading={process}>Mettre à jour</Button>
          </div>
          <div className="flex">
            <div className="w-50per padding-15">
              {
                myCombis.map((cat, id) => (
                  <div key={id}>
                    <Space>
                      <h2>Combinaison {id + 1} ({cat.combinationKeyWords.length})</h2>
                      <div><DeleteTwoTone onClick={() => showDeleteConfirm(cat.id)} twoToneColor="red"/></div>
                    </Space>
                    <div className="flex space-between padding-topbot-10">
                      <div className="w-40per"><strong>Combinaison</strong></div>
                      <div className="w-60per flex space-between">
                        <div className="w-15per flex justify-center"><strong>Note</strong></div>
                        <div className="w-25per flex justify-center"><strong>Google search</strong></div>
                        <div className="w-25per flex justify-center"><strong>Concurrence</strong></div>
                        <div className="w-25per flex justify-center"><strong>CPC Adword</strong></div>
                        <div className="w-15per flex justify-center"><strong>action</strong></div>
                      </div>
                    </div>
                    {cat.combinationKeyWords.sort((a, b) => b.note - a.note || a.result.localeCompare(b.result)).map((comb, id) => (
                      <div key={id} className="flex space-between">
                        <div
                          style={allFavorite.some(f => f.combination["@id"] === comb["@id"]) ? {textDecoration: "line-through"} : null}
                          className="cursor-p url-redir w-40per"
                          onClick={() => toggleFav(comb["@id"])}
                        >
                          {comb.result}&nbsp;
                        </div>
                        <div className="w-60per flex space-between">
                          <div className="w-15per flex justify-center" style={getNoteColor(comb.note)}>
                            <Popconfirm
                              placement="right"
                              title={<>
                                <div>Note de 0 à 100</div>
                                <InputNumber
                                  min={0}
                                  max={100}
                                  value={newNote}
                                  onChange={(value) => setNewNote(value)}
                                  onPressEnter={() => note(comb.id)}/>
                              </>}
                              onConfirm={() => note(comb["@id"].split("/").pop())}
                              okText="Valider"
                              cancelText="Retour"
                              icon={null}
                            >
                              <RiseOutlined className="url-redir cursor-p"
                                            onClick={() => setNewNote(comb.note)}/>&nbsp;&nbsp;
                            </Popconfirm>
                            {comb.note ? comb.note : "-"}
                          </div>
                          <div
                            className="w-25per flex justify-center">{comb.googleSearch ? comb.googleSearch : "-"}</div>
                          <div className="w-25per flex justify-center">{comb.concurence ? comb.concurence : "-"}</div>
                          <div className="w-25per flex justify-center">{comb.cpcAdwords ? comb.cpcAdwords : "-"}</div>
                          <div className="w-15per flex justify-center">
                            <Space>
                              <CloseCircleTwoTone
                                twoToneColor="red"
                                onClick={() => removeOfCombi(cat.id, comb["@id"])}
                              />
                              <Checkbox
                                onClick={() => addCombis(cat.id, comb["@id"])}
                                checked={combinationSelected.some(selected => selected.combinationId === comb["@id"].split("/").pop())}
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              }
            </div>


            <div className="w-50per padding-15">
              <div className="flex wrap space-between">
                <Space size="large">
                  <h2>Favoris</h2>
                  <LanguageChoice language={lng} setLanguage={setLng} setLoading={setLoading}/>
                </Space>
                <Input.Search
                  enterButton="Valider"
                  placeholder="Ajouter manuellement"
                  value={manually}
                  onChange={(e) => setManually(e.target.value)}
                  onSearch={addManually}
                  style={{width: 400}}
                />
              </div>
              <div className="flex space-between padding-topbot-10">
                <div className="w-40per"><strong>Combinaison</strong></div>
                <div className="w-60per flex space-between">
                  <div className="w-15per flex justify-center"><strong>Note</strong></div>
                  <div className="w-25per flex justify-center"><strong>Google search</strong></div>
                  <div className="w-25per flex justify-center"><strong>Concurrence</strong></div>
                  <div className="w-25per flex justify-center"><strong>CPC Adword</strong></div>
                  <div className="w-15per flex justify-center"><strong>action</strong></div>
                </div>
              </div>
              {
                favorite.map((f, id) => (
                  <div key={id} className="flex space-between">
                    <div className="w-40per">
                      {id + 1}. {f.combination.result}&nbsp;
                    </div>
                    <div className="w-60per flex space-between">
                      <div className="w-15per flex justify-center" style={getNoteColor(f.combination.note)}>
                        <Popconfirm
                          placement="right"
                          title={<>
                            <div>Note de 0 à 100</div>
                            <InputNumber
                              min={0}
                              max={100}
                              value={newNote}
                              onChange={(value) => setNewNote(value)}
                              onPressEnter={() => note(f.combination.id)}
                            /></>}
                          onConfirm={() => note(f.combination.id)}
                          okText="Valider"
                          cancelText="Retour"
                          icon={null}
                        >
                          <RiseOutlined
                            className="url-redir cursor-p"
                            onClick={() => setNewNote(f.combination.note)}
                          />&nbsp;&nbsp;
                        </Popconfirm>
                        {f.combination.note ? f.combination.note : "-"}
                      </div>
                      <div
                        className="w-25per flex justify-center">{f.combination.googleSearch ? f.combination.googleSearch : "-"}</div>
                      <div
                        className="w-25per flex justify-center">{f.combination.concurence ? f.combination.concurence : "-"}</div>
                      <div
                        className="w-25per flex justify-center">{f.combination.cpcAdwords ? f.combination.cpcAdwords : "-"}</div>
                      <div className="w-15per flex justify-center">
                        <Tooltip trigger="click" title={() => makeEditFav(f)} color="#fff">
                          <EditTwoTone
                          />
                        </Tooltip>
                        <CloseCircleTwoTone
                          twoToneColor="red"
                          onClick={() => toggleFav(f.combination["@id"])}
                          className="m-left-10"
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

import React from 'react';
import {Button, Modal, Space, Table} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

const ModalHistoryFavorite = ({favorite, visible, setVisible, onClose = () => {}}) => {

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Position",
      dataIndex: "rank",
      render: (rank, record, index) => {
        let diff = null;

        if (typeof favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[index + 1] === "object") {
          if (isNaN(favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[index + 1].rank))
            diff = rank;
          else
            diff = favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))[index + 1].rank - rank;
        }

        return (
          <Space>
            <span>{rank}{rank === 1 && 'er'}{rank && rank > 1 && 'ème'}</span>
            {diff && diff > 0 ? <span style={{color: "#3f8600"}}><ArrowUpOutlined/>{diff}</span> : null}
            {diff && diff < 0 ? <span style={{color: "#cf1322"}}><ArrowDownOutlined/>{diff}</span> : null}
          </Space>
        )
      }
    }
  ]

  function onCancel() {
    setVisible(false);
    if (typeof onClose() === "function") {
      onClose();
    }
  }

  return (
    <Modal
      title={`${favorite.combination.result} - Historique`}
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
      footer={[
        <Button type="primary" key="cancel" onClick={onCancel}>Fermer</Button>
      ]}
    >
      <Table
        columns={columns}
        dataSource={favorite.data.sort((a, b) => new Date(b.date) - new Date(a.date))}
        rowKey="date"
      />
    </Modal>
  );
};

export default ModalHistoryFavorite;

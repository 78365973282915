import React, {useEffect} from 'react';
import {useAuthContext} from '../providers/AuthProvider.js';

const Logout = () => {
  const [authState, authDispatch] = useAuthContext();
  const {authLogout} = authDispatch;

  authLogout();

  return 0;
};

export default Logout;

import React, { useState } from 'react'
import { Modal, Button, Input, InputNumber, Form, Select, DatePicker } from 'antd'
import { billingInfoList } from '../../Helpers';
const { Option } = Select;

export default function ModalEstablishment({ visible, setVisible, apiPostEntity }) {

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false)
	const [displayLoc, setDisplayLoc] = useState(false)

	function handleCancel() {
		setVisible(false)
		setLoading(false)
		setDisplayLoc(false)
		form.resetFields()
	}

	function handleOk(value) {
		//console.log(form.getFieldsValue())
		var data = sortValues()

		//console.log(data)

		if (displayLoc) {
			data[1].establishment
			apiPostEntity("locations", data[1], responseL => {
				console.log(responseL)
				data[0].location = responseL['@id']
				apiPostEntity('establishments', data[0], response => {
					//console.log(response)
					handleClose()

				})
			})
		} else {
			apiPostEntity('establishments', data[0], response => {
				//console.log(response)
				handleClose()
			})
		}
	}

	function sortValues(obj) {
		let resp = []
		let etb = {}

		const data = form.getFieldsValue()

		etb.name = data.name
		etb.hapi = data.hapi
		etb.cluster = data.cluster
		etb.sea = data.sea
		etb.begeningAt = data.begeningAt ? data.begeningAt.format('YYYY-MM-DD') : null
		etb.changeAt = data.changeAt ? data.changeAt.format('YYYY-MM-DD') : null
		etb.billingInfos = data.billingInfos
		etb.status = data.status
		etb.comment = data.comment

		resp.push(etb)

		if (displayLoc) {
			let loc = {}

			loc.city = data.city
			loc.adress = data.adress
			loc.departement = data.departement
			loc.region = data.region
			loc.postalCode = data.postalCode
			loc.countrie = data.countrie
			loc.latitude = data.latitude
			loc.longitude = data.longitude

			resp.push(loc)
		}

		return resp;
	}

	return (
		<Modal
			visible={visible}
			title="Établissement"
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Retour
				</Button>,
				<Button key="submit" type="primary" loading={loading} onClick={form.submit}>
					Enregistrer
				</Button>, ,
				<Button key="submitClose" type="primary" loading={loading} onClick={form.submit}>
					Enregistrer et fermer
				</Button>
			]}
		>
			<Form
				layout="vertical"
				form={form}
				name="etb"
				onFinish={handleOk}
				initialValues={{
					hapi: 0,
					cluster: null,
					sea: false,
					changeAt: null,
					billingInfos: null,
					status: "A jour",
					comment: null
				}}
			>
				<h3>Infos établissement</h3>
				<Form.Item
					name="name"
					label="Nom"
					rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="hapi" label="Hapi" rules={[{ required: true }]}>
					<Select
						allowClear
						rules={[{ required: true }]}
					>
						<Option value={0}>autre</Option>
						<Option value={1}>v1</Option>
						<Option value={2}>v2</Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="cluster"
					label="Groupe"
				>
					<Input />
				</Form.Item>
				<Form.Item name="sea" label="Sea" rules={[{ required: true, message: 'Ce champs est obligatoire' }]}>
					<Select
						allowClear
					>
						<Option value={false}>Non</Option>
						<Option value={true}>Oui</Option>
					</Select>
				</Form.Item>
				<Form.Item name="begeningAt" label="Commence le" rules={[{ required: true, message: 'Ce champs est obligatoire' }]}>
					<DatePicker />
				</Form.Item>
				<Form.Item name="changeAt" label="Dernière modif">
					<DatePicker />
				</Form.Item>
				<Form.Item
					name="billingInfos"
					label="Infos Facturation"
				>
					<Select>
						{
							billingInfoList.map(item => <Option key={item} value={item}>{item}</Option>)
						}
					</Select>
				</Form.Item>
				<Form.Item name="status" label="Status" rules={[{ required: true, message: 'Ce champs est obligatoire' }]}>
					<Select
						allowClear
					>
						<Option value="En retard">En retard</Option>
						<Option value="A jour">A jour</Option>
						<Option value="En cours">En cours</Option>
						<Option value="Suspendu">Suspendu</Option>
						<Option value="Supprimé">Supprimé</Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="comment"
					label="Commentaire"
				>
					<Input.TextArea />
				</Form.Item>
				<div className="flex"><h3>Localisation</h3><a className="m-left-5" onClick={() => setDisplayLoc(!displayLoc)}>{displayLoc ? "Masquer" : "afficher"}</a></div>
				{
					displayLoc ?
						<>
							<Form.Item
								name="adress"
								label="Adresse"
								initialValue={null}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="city"
								label="Ville"
								initialValue={null}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="departement"
								label="Departement"
								initialValue={null}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="region"
								label="Region"
								initialValue={null}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="postalCode"
								label="Code postal"
								initialValue={null}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="countrie"
								label="Pays"
								initialValue={null}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="latitude"
								label="Latitude"
								initialValue={null}
							>
								<InputNumber style={{ width: 250 }} />
							</Form.Item>
							<Form.Item
								name="longitude"
								label="Longitude"
								initialValue={null}
							>
								<InputNumber style={{ width: 250 }} />
							</Form.Item>
						</>
						: null
				}
			</Form>
		</Modal>
	)
}

import React, {useState} from 'react'
import { Button, DatePicker, message, Modal } from 'antd'
import { useApiContext } from '../../../providers/ApiProvider';
import moment from 'moment'
const { RangePicker } = DatePicker;

export default function ModalExport({accountId, isVisible, setIsVisible}) {

    const [apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;
    const [loadingXls, setLoadingXls] = useState(false);
    const [dateRange, setDateRange] = useState(undefined)

    function generateFile() {
        if (!dateRange) message.error('Vous devez sélectionner des dates')
        setLoadingXls(true)
        //console.log('dataRange', dateRange)
        const data = {
            filters: [
                { name: 'etb', value: accountId},
                { name: 'from', value: dateRange[0].format('DD-MM-YYYY')},
                { name: 'to', value:  dateRange[1].format('DD-MM-YYYY')}
            ]
        }
        apiFetchCollection("fav-xls", data, (response) => {

            if (response['success']) {
                window.open(response.link);
            }
            setLoadingXls(false)
        })

    }

    return (
        <Modal visible={isVisible} title="Exporter les données"
            onCancel={() => setIsVisible(false)}
            footer={[<Button key="close" onClick={() => setIsVisible(false)}>Fermer</Button>, <Button key="export" loading={loadingXls} onClick={generateFile}>Exporter</Button>]}>
            <p>Choisissez les dates d'export:</p>
            <RangePicker disabledDate={current => moment().endOf('month') < current } picker="month" onChange={val => setDateRange(val)} 
            ranges={
                {
                    'Six derniers mois': [moment().startOf('month').subtract(5, 'months'), moment().startOf('month')],
                    'un an': [moment().startOf('month').subtract(11, 'months'), moment().startOf('month')]
                }} />
        </Modal>
    )
}

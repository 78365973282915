import React, {useState} from "react";
import ApiCollectionTable from "../components/ApiCollectionTable";
import {Button, Checkbox, Input, Switch} from "antd";
import {CaretUpOutlined, CloseOutlined} from '@ant-design/icons';
import {useApiContext} from "../providers/ApiProvider";

export default function CombinationsList() {

  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;

  const initialFilters = [{name: "result", value: ""}, {name: "isHidden", value: false}];
  const [filters, setFilters] = useState(initialFilters);

  const columns = [
    {
      title: "Combinaison",
      dataIndex: 'result',
      render: result => result
    },
    {
      title: "Note",
      dataIndex: 'note',
      render: note => <span>{note || "-"}</span>
    },
    {
      title: "Recherche Google",
      dataIndex: 'googleSearch',
      render: googleSearch => <span>{googleSearch || "-"}</span>
    },
    {
      title: "Concurrence",
      dataIndex: 'concurence',
      render: concurence => <span>{concurence || "-"}</span>
    },
    {
      title: "CPC adwords",
      dataIndex: 'cpcAdwords',
      render: cpcAdwords => <span>{cpcAdwords || "-"}</span>
    },
    {
      title: "Hidden",
      dataIndex: 'isHidden',
      render: (isHidden, record) => (
        <Switch
          defaultChecked={isHidden}
          size="small"
          onClick={(checked) => toggleHidden(checked, record.id)}
        />)
    }
  ];

  function toggleHidden(checked, id) {
    apiUpdateEntity('combination_key_words', id, {isHidden: checked}, () => {
    })
  }

  function search(key, value) {
    const index = filters.findIndex((filter => filter.name === key));
    filters[index].value = value;
    setFilters([...filters]);
  }

  function toggleSearch(key) {
    if (filters.some(filter => filter.name === key))
      setFilters(filters.filter(filter => filter.name !== key));
    else
      setFilters([...filters, {name: key, value: "desc"}]);
  }

  return (
    <div className="contenu-tab">
      <div className="text-center">
        <h1>Liste des combinaisons</h1>
      </div>
      <div className="padding-topbot-10">
        <Input
          allowClear
          placeholder="Rechercher"
          onChange={e => search("result", e.target.value)}
          style={{width: 300}}
        />
        <Button className="m-left-10" type="primary" icon={<CaretUpOutlined/>}
                onClick={() => toggleSearch("order[googleSearch]")}>Top recherche Google</Button>
        <Button className="m-left-10" type="primary" icon={<CaretUpOutlined/>}
                onClick={() => toggleSearch("order[concurence]")}>Top Concurrence</Button>
        <Button className="m-left-10" type="primary" icon={<CaretUpOutlined/>}
                onClick={() => toggleSearch("order[cpcAdwords]")}>Top CPC adwords</Button>
        <Button className="m-left-10" type="danger" icon={<CloseOutlined/>}
                onClick={() => setFilters(initialFilters)}>Reset</Button>
        <Checkbox className="m-left-10" onChange={e => search('isHidden', e.target.checked)}/> Hidden
      </div>
      <div>
        <ApiCollectionTable
          endpoint="combination_key_words"
          columns={columns}
          pageSize={50}
          filtres={filters}
          displayTotal={true}
        />
      </div>
    </div>
  )
}

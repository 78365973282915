import React, {useEffect} from "react"
import {useDrag} from 'react-dnd'
import {Tag} from "antd";
import {EditOutlined} from '@ant-design/icons';
import {useAuthContext} from "../../providers/AuthProvider";

export default function DraggableTag({keyWord, displayModal, tagSelected, setTagSelected, updateKeyWordsWithList, isDraggingGlobal, setIsDraggingGlobal, updateKeyWordsWithIri, isIncluded}) {

  const [authState] = useAuthContext();
  const {auth} = authState;

  const isAdmin = auth.roles.includes("ROLE_ADMIN");
  var click = 0;

  const [{isDragging}, drag] = useDrag({
    item: {name, type: "keyWord"},
    begin: (monitor) => {
      if (!tagSelected.includes(keyWord["@id"])) {
        setTagSelected([keyWord["@id"]])
      }
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        updateKeyWordsWithList()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    if (isDragging) {
      setIsDraggingGlobal(true)
    } else {
      setIsDraggingGlobal(false)
    }
  }, [isDragging])

  function toggleTag() {
    if (tagSelected.includes(keyWord['@id'])) {
      setTagSelected(tagSelected.filter(t => t !== keyWord['@id']))
    } else {
      setTagSelected([...tagSelected, keyWord['@id']])
    }
  }

  function handleClick() {
    click++
    if (click === 1) {
      setTimeout(() => {
        toggleTag()
        click = 0;
      }, 300)
    }
  }

  function doubleClick(e) {
    updateKeyWordsWithIri(keyWord["@id"])
  }

  const opacity = isDraggingGlobal && tagSelected.includes(keyWord["@id"]) ? 0.4 : 1
  const border = tagSelected.includes(keyWord['@id']) ? "1px solid orange" : null
  const backgroundColor = isIncluded ? "#ddd" : null

  return (
    <Tag
      style={{opacity, border, backgroundColor}}
      ref={drag}
      className="cursor-p margin-5"
      onClick={handleClick}
      onDoubleClick={doubleClick}
    >
      {keyWord.name}&nbsp;
      {isAdmin ? <EditOutlined onClick={() => displayModal(keyWord)}/> : null}
    </Tag>
  )
}

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useApiContext} from "../providers/ApiProvider";
import {useAccountContext} from "../providers/AccountProvider";
import {Button, Select, Space, Table} from "antd";
import moment from 'moment';
import {ArrowDownOutlined, ArrowUpOutlined, DeleteTwoTone} from '@ant-design/icons';
import ModalHistoryCombination from "../components/Modal/ModalHistoryCombination";
import ModalExportCombination from "../components/Modal/ModalExportCombination";
import UploadFile from "../components/CombinationMonitoring/UploadFile";

export default function CombinationMonitoring() {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchEntity, apiFetchSubResource, apiDeleteEntity, apiUpdateEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {accountSet} = accountDispatch;
  const {account} = accountState;

  const [needUpdate, setNeedUpdate] = useState(0);

  const [sheets, setSheets] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);

  const [combinationHistory, setCombinationHistory] = useState({})
  const [visible, setVisible] = useState(false);

  const [currentTable, setCurrentTable] = useState([]);
  const [filterExcluded, setFilterExcluded] = useState("all");

  useEffect(() => {
    fetchEtb();
  }, [])

  useEffect(() => {
    fetchCombinationSheet();
  }, [needUpdate])

  function fetchEtb() {
    apiFetchEntity("establishments", params.id, response => {
      accountSet(response);
    })
  }

  function fetchCombinationSheet() {
    apiFetchSubResource('establishments', {id: params.id}, 'combination_sheets', response => {
      setSheets(response['hydra:member']);

      if (response['hydra:member'].length && response['hydra:member'][0].data)
        setCurrentTable(response['hydra:member'][0].data);
    })
  }

  function handleClickRow(item) {
    let array = [];

    sheets.map(sheet => {
      const combination = sheet.data.find(d => d.expressionDeRecherche === item.expressionDeRecherche)

      if (combination !== undefined)
        array.push({date: sheet.extractedAt, ...combination})
    })

    setCombinationHistory({expressionDeRecherche: item.expressionDeRecherche, history: array});
    setVisible(true);
  }

  function remove(id) {
    apiDeleteEntity('combination_sheets', id, response => {
      setNeedUpdate(needUpdate + 1);
    })
  }

  function hideRow(expressionDeRecherche) {
    let copy = {...account};

    if (copy.excludedCombinations.includes(expressionDeRecherche))
      copy.excludedCombinations = copy.excludedCombinations.filter(ec => ec !== expressionDeRecherche)
    else
      copy.excludedCombinations = [...copy.excludedCombinations, expressionDeRecherche];

    apiUpdateEntity('establishments', account.id, {excludedCombinations: copy.excludedCombinations}, response => {
    });
    accountSet(copy);
  }

  function handleSortChange(pagination, filters, sorter, extra) {
    setCurrentTable(extra.currentDataSource)
  }

  const columns = [
    {
      title: 'Expression de recherche',
      dataIndex: 'expressionDeRecherche',
      sorter: (a, b) => a.expressionDeRecherche.localeCompare(b.expressionDeRecherche),
      render: (text, record) => (
        <span>
          <a onClick={() => handleClickRow(record)}>{text}</a>&nbsp;
          <span style={{color: 'orange', fontSize: '0.8em'}}>{record.isNew && 'New'}</span>
        </span>
      )
    },
    {
      title: 'Trafic SEO',
      dataIndex: 'traficSeo',
      sorter: (a, b) => a.traficSeo - b.traficSeo,
      render: (traficSeo, record) => (
        <span>
          {traficSeo}&nbsp;
          <span style={{color: record.traficSeoDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.traficSeoDiff > 0 && <ArrowUpOutlined/>}
            {record.traficSeoDiff < 0 && <ArrowDownOutlined/>}
            {record.traficSeoDiff || null}
          </span>
        </span>
      )
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: (a, b) => a.position - b.position,
      render: (position, record) => (
        <span>
          {position}&nbsp;
          <span style={{color: record.positionDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.positionDiff > 0 && <ArrowUpOutlined/>}
            {record.positionDiff < 0 && <ArrowDownOutlined/>}
            {record.positionDiff || null}
          </span>
        </span>
      )
    },
    {
      title: 'Page positionnée',
      dataIndex: 'pagePositionnee',
      sorter: (a, b) => a.pagePositionnee.localeCompare(b.pagePositionnee),
    },
    {
      title: 'Recherches Google',
      dataIndex: 'recherchesGoogle',
      sorter: (a, b) => a.recherchesGoogle - b.recherchesGoogle,
      render: (recherchesGoogle, record) => (
        <span>
          {recherchesGoogle}&nbsp;
          <span style={{color: record.recherchesGoogleDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.recherchesGoogleDiff > 0 && <ArrowUpOutlined/>}
            {record.recherchesGoogleDiff < 0 && <ArrowDownOutlined/>}
            {record.recherchesGoogleDiff || null}
          </span>
        </span>
      )
    },
    {
      title: 'Concurrence',
      dataIndex: 'concurrence',
      sorter: (a, b) => a.concurrence - b.concurrence,
      render: (concurrence, record) => (
        <span>
          {concurrence}&nbsp;
          <span style={{color: record.concurrenceDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.concurrenceDiff > 0 && <ArrowUpOutlined/>}
            {record.concurrenceDiff < 0 && <ArrowDownOutlined/>}
            {record.concurrenceDiff !== '0.00' ? record.concurrenceDiff : null}
          </span>
        </span>
      )
    },
    {
      title: 'CPC Adwords',
      dataIndex: 'cpcAdwords',
      sorter: (a, b) => a.cpcAdwords - b.cpcAdwords,
      render: (cpcAdwords, record) => (
        <span>
          {cpcAdwords}&nbsp;
          <span style={{color: record.cpcAdwordsDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.cpcAdwordsDiff > 0 && <ArrowUpOutlined/>}
            {record.cpcAdwordsDiff < 0 && <ArrowDownOutlined/>}
            {record.cpcAdwordsDiff !== '0.00' ? record.cpcAdwordsDiff : null}
          </span>
        </span>
      )
    },
    {
      title: 'Action',
      dataIndex: 'expressionDeRecherche',
      render: expressionDeRecherche => (
        <span>
          {account.excludedCombinations.includes(expressionDeRecherche) ?
            <Button type="link" onClick={() => hideRow(expressionDeRecherche)}>
              remettre
            </Button> :
            <Button type="link" danger onClick={() => hideRow(expressionDeRecherche)}>
              ecxlure
            </Button>
          }
        </span>
      )
    }
  ];

  function insideFilter(item) {
    if (filterExcluded === "all")
      return true;

    if (filterExcluded === "true" && account.excludedCombinations.includes(item.expressionDeRecherche))
      return true;

    return filterExcluded === "false" && !account.excludedCombinations.includes(item.expressionDeRecherche);
  }

  return (
    <div className="contenu-tab">

      <UploadFile setNeedUpdate={setNeedUpdate}/>

      <div style={{marginTop: 100}}>
        <div style={{float: "right"}}>
          <Select
            style={{width: 200}}
            defaultValue="all"
            onChange={value => setFilterExcluded(value)}
          >
            <Select.Option value="all">Tous</Select.Option>
            <Select.Option value="false">Non Exclus</Select.Option>
            <Select.Option value="true">Exclus</Select.Option>
          </Select>
          <ModalExportCombination
            data={currentTable}
          />
        </div>
        {sheets.map((sheet, index) => (
          index === 0 || displayAll ?
            <div key={index}>
              <Space size="large">
                <h2>{moment(sheet.extractedAt).format('MM-YYYY')}</h2>
                <div><DeleteTwoTone twoToneColor="#eb2f96" onClick={() => remove(sheet.id)}/></div>
              </Space>
              <Table
                columns={columns}
                dataSource={sheet.data.filter(insideFilter)}
                rowKey={record => record.id} pagination={{defaultPageSize: 50}}
                rowClassName={(record, index) => account.excludedCombinations.includes(record.expressionDeRecherche) ? "excluded-row" : ""}
                onChange={handleSortChange}
              />
            </div> : null
        ))}


        {!displayAll && sheets.length > 1 &&
        <div style={{display: "flex", justifyContent: "center"}}>
          <Button onClick={() => setDisplayAll(true)}>Afficher Tableaux plus anciens</Button>
        </div>}

      </div>
      <ModalHistoryCombination visible={visible} setVisible={setVisible} history={combinationHistory}
                               setHistory={setCombinationHistory}/>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useApiContext } from "../../providers/ApiProvider";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  MoreOutlined,
  TagOutlined
} from '@ant-design/icons';
import { Button, Divider, Dropdown, Input, Menu, message, Space, Spin, Tag, Tooltip } from "antd";
import { useSEOContext } from "../../providers/SEOProvider";
import { useParams } from "react-router-dom";
import { copy, stringDateDMonthY, stringDateDMY } from "../../Helpers";
import { useAuthContext } from "../../providers/AuthProvider";
import TagDropDownLight from "../TagDropDownLight";
import FavoriteData from "./FavoriteData";

export default function FavoriteCard({ favorite, noDelete = false, tasks, status, bulkEditing }) {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity, apiPostEntity, apiDeleteEntity } = apiDispatch;
  const [seoState, seoDispatch] = useSEOContext();
  const { reload, setLoading, reloadTags } = seoDispatch;
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [favoriteItem, setFavoriteItem] = useState(null)

  const [editLoading, setEditLoading] = useState("");
  const [edit, setEdit] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    setFavoriteItem(favorite)
    if (favorite.task)
      setSelectedKeys([String(favorite.task.parent.id)]);
  }, [favorite])

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: "favorite" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (favoriteItem && dropResult)
        update(dropResult);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  function update(dropResult) {
    setLoading(true)
    let data;

    if (dropResult.type === "page")
      data = { page: dropResult.pageIri };

    if (dropResult.type === "sort")
      data = { page: null, status: dropResult.sortTo };

    apiUpdateEntity("favorite_combination_key_words", favoriteItem?.id, data, response => {
      reload();
    })
  }

  const opacity = isDragging ? 0.4 : 1;

  //console.log('favoriteItem', favoriteItem)
  const title = (
    <div style={{ color: "rgba(0, 0, 0, 0.6)" }}>
      <div><strong>Note</strong> : {favoriteItem?.combination.note || "-"}</div>
      <div><strong>Recherches Google</strong> : {favoriteItem?.combination.googleSearch || "-"}</div>
      <div><strong>Concurrence</strong> : {favoriteItem?.combination.concurence || "-"}</div>
      <div><strong>CPC adwords</strong> : {favoriteItem?.combination.cpcAdwords || "-"}</div>
    </div>
  )

  function duplicate() {
    setLoading(true);
    const data = {
      establishment: "/api/establishments/" + params.id,
      combination: "/api/combination_key_words/" + favoriteItem?.combination.id,
      language: seoState.language
    };
    apiPostEntity("favorite_combination_key_words", data, () => reload());
  }

  function deleteFav() {
    setLoading(true)
    apiDeleteEntity("favorite_combination_key_words", favorite.id, () => reload());
  }

  function actionMenu(e) {
    switch (e.key) {
      case "edit":
        setEdit(favoriteItem.combination.result);
        break;
      case "duplicate":
        duplicate()
        break;
      case "delete":
        deleteFav();
        break;
      case "createPageSeo":
        createPageSeo();
        break;
      case "remove":
        removeFav();
        break;
      default:
        if (selectedKeys.includes(e.key))
          removeTask();
        else
          postTask(e.key);
        break;
    }
  }

  function removeFav() {
    setLoading(true)
    const data = { page: null, status: 'classic' };
    apiUpdateEntity("favorite_combination_key_words", favorite.id, data, response => {
      reload();
    })
  }

  function createPageSeo() {
    const data = { favId: favoriteItem?.id, language: seoState.language }
    apiPostEntity("create-page-from-favorite", data, () => reload())
  }

  function removeTask() {
    apiDeleteEntity('tasks', favorite.task.id, () => {
      reload();
      setSelectedKeys([]);
    })
  }

  function postTask(taskId) {
    const data = {
      favorite: favorite['@id'],
      parent: '/api/tasks/' + taskId,
      establishment: '/api/establishments/' + params.id,
      description: 'Page SEO ' + seoState.language,
      status: "/api/tags/3",
      user: ['/api/users/' + auth.id]
    }
    apiPostEntity('tasks', data, () => reload())
  }

  function editFav(value, shouldReload = true) {
    //console.log('favoriteItem', favoriteItem)
    if (value === favoriteItem?.combination.result)
      return setEdit("");

    if (!value || value.length < 5)
      return message.error("Combinaison trop courte");

    setEditLoading(true);

    const data = {
      account: params.id,
      favorite: favoriteItem?.id,
      combination: value
    }

    apiPostEntity("edit-favorite", data, response => {
      //console.log('response', response)
      if (!response.success)
        return message(response.message);

      setFavoriteItem(response.favorite)
      setEdit("");
      setEditLoading(false)
    });
  }


  const menu = (
    <Menu onClick={actionMenu} selectedKeys={selectedKeys}>
      {status === 'nextToUse' ?
        <>
          <Menu.Item key="createPageSeo">
            Creer page SEO
          </Menu.Item>
          <Menu.SubMenu title="Assigner a une tâche">
            {tasks.map(task => (
              <Menu.Item key={task.id}>
                {task.description}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        </> : null}
      <Menu.Item key="edit">
        Editer
      </Menu.Item>
      <Menu.Item key="duplicate">
        Dupliquer
      </Menu.Item>
      <Menu.Divider />
      {noDelete &&
        <Menu.Item key="remove">
          <span>Retirer</span>
        </Menu.Item>}
      <Menu.Item key="delete" disabled={noDelete}>
        <span className="danger">Supprimer</span>
      </Menu.Item>
    </Menu>
  );

  function switchLife(life) {
    if (life.tag)
      return <span style={{ color: life.tag.color, fontWeight: "bold" }}>{life.tag.name}</span>
    else
      return <strong>{life.action}</strong>
  }

  const timeContent = (
    <div style={{ color: "rgba(0, 0, 0, 0.6)" }}>
      {favoriteItem?.favoriteLives.length ? favoriteItem?.favoriteLives.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((life, id) => (
        <div key={id}>
          <div key={life.id}>
            <div>
              {switchLife(life)}&nbsp;
              <em>le {stringDateDMonthY(life.createdAt)}</em>
            </div>
          </div>
          {id !== (favoriteItem?.favoriteLives.length - 1) ? <Divider style={{ margin: "5px 0" }} /> : null}
        </div>
      )) : <em>Pas d'historique ...</em>}
    </div>
  )

  const tag = favoriteItem?.task ?
    <Tag className="cursor-p" color={favoriteItem?.task.status.color}>{favoriteItem?.task.status.name}</Tag> :
    <TagOutlined />

  function copyCombination(e) {
    e.preventDefault()
    e.stopPropagation()
    copy(favoriteItem?.combination.result)
    message.success("Texte copié dans le presse papier")
  }

  function buildBulkEdit() {
    let texts = favoriteItem?.combination?.result?.split('|')?.map(item => item.trim())
    if (texts.length === 1)
      return texts[0];

    return texts.map(item => <Tag key={favoriteItem?.combination['@ui'] + item} className="cursor-p" color="geekblue" onClick={() => handleBulkEdit(item)} closeIcon={<CheckOutlined />}>{item}</Tag>)
  }

  function handleBulkEdit(val) {
    editFav(val, false)
  }

  return (
    <Spin spinning={editLoading} >
      <div ref={drag} className="trello-card" style={{ opacity }}>
        <div className="trello-card-title flex space-between">
          {
            bulkEditing && favoriteItem && !edit &&
            <div>{buildBulkEdit()}</div>
          }
          {!!edit && !bulkEditing &&
            <span>
              <Input value={edit} onChange={(e) => setEdit(e.target.value)} />
              <Button type="primary" icon={<CheckOutlined />} onClick={() => editFav(edit)} />
              <Button type="danger" icon={<CloseOutlined />} onClick={() => setEdit("")} />
            </span>
          }
          {
            !edit && !bulkEditing &&
            <>
              <div>
                <div>
                  <Space>
                    <Tooltip color="#fff" title={title} placement="right">
                      {favoriteItem?.combination.result}
                    </Tooltip>
                    <CopyOutlined onClick={copyCombination} />
                  </Space>
                </div>
              </div>
              <div className="m-left-10">
                <Dropdown overlay={menu} trigger={['click']}>
                  <MoreOutlined onClick={(e) => e.preventDefault()} />
                </Dropdown>
              </div>
            </>
          }
        </div>
        <div className="flex space-between align-center">
          {favoriteItem?.data.length ?
            <FavoriteData favorite={favoriteItem} />
            : null}
          {status === "nextToUse" && favoriteItem?.task ?
            <TagDropDownLight task={favoriteItem?.task} reload={reload} content={tag} forceTags={seoState.tags}
              reloadTags={reloadTags} /> : <div />}
          <div className="m-left-10">
            <Tooltip trigger="click" title={timeContent} color="#fff" placement="bottom">
              <ClockCircleOutlined />
            </Tooltip>
          </div>
        </div>
        {favoriteItem?.task ?
          <div>
            <span className="danger">Planifié pour le {stringDateDMY(favoriteItem?.task.parent.plannedAt)}</span>
          </div> : null}
      </div>
    </Spin>
  )
}

import React from 'react';
import {Layout, Menu} from 'antd';
import {useAccountContext} from "../../providers/AccountProvider";
import {Link, useLocation} from "react-router-dom";
import MessageDrawer from "../Drawer/MessageDrawer";
import WorkflowDrawer from "../Drawer/WorkflowDrawer";
import {LinkOutlined} from "@ant-design/icons";

export default function Header() {

  const [accountState] = useAccountContext();
  const {account} = accountState;
  const location = useLocation();

  return (
    <Layout.Header
      className="site-layout-background flex space-between"
      style={{backgroundColor: "#fff"}}
    >
      {account ?
        <>
          <Menu mode="horizontal" selectedKeys={[location.pathname]} style={{zIndex: 10, borderBottom: '0px'}}>
            <Menu.Item key={`/establishment/${account.id}`}>
              <strong><Link to={`/establishment/${account.id}`}>Fiche</Link></strong>
            </Menu.Item>
            <Menu.Item key={`/establishment-key-words/${account.id}`}>
              <strong><Link to={`/establishment-key-words/${account.id}`}>Mots clés</Link></strong>
            </Menu.Item>
            <Menu.Item key={`/generate/${account.id}`}>
              <strong><Link to={`/generate/${account.id}`}>Combinaisons</Link></strong>
            </Menu.Item>
            <Menu.Item key={`/seo-follow-up/${account.id}`}>
              <strong><Link to={`/seo-follow-up/${account.id}`}>Suivi SEO</Link></strong>
            </Menu.Item>
            <Menu.Item key={`/combination-monitoring/${account.id}`}>
              <strong><Link to={`/combination-monitoring/${account.id}`}>Suivi Combinaisons</Link></strong>
            </Menu.Item>
            <Menu.Item key={`/redirection/${account.id}`}>
              <strong><Link to={`/redirection/${account.id}`}>Redirections</Link></strong>
            </Menu.Item>
          </Menu>

          <div className="flex align-baseline">
            <h2>{account.name}</h2>
            <a className="m-left-5" href={account.url} target="_blank"><LinkOutlined/></a>
            <MessageDrawer/>
            <WorkflowDrawer/>
          </div>
        </>
        : null}
    </Layout.Header>
  );
}

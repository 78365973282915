import React from "react"
import {Spin, Tag} from "antd";
import {useDrop} from 'react-dnd'
import {DeleteOutlined} from '@ant-design/icons';
import {useAuthContext} from "../../providers/AuthProvider";

export default function TagDropBox({loading, myKeyWords, updateKeyWords, reset}) {

  const [authState] = useAuthContext();
  const {auth} = authState;

  const isAdmin = auth.roles.includes("ROLE_ADMIN");

  const [{canDrop, isOver}, drop] = useDrop({
    accept: "keyWord",
    drop: () => ({name: 'Dustbin'}),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = canDrop && isOver
  let backgroundColor = '#fff'
  if (isActive) {
    backgroundColor = '#eee'
  } else if (canDrop) {
    backgroundColor = '#ddd'
  }

  return (
    <div
      ref={drop}
      style={{backgroundColor, position: "relative"}}
      className="border1 b-radius padding-15 margin-5 min-height-400"
    >
      <Spin spinning={loading}>
        {myKeyWords.map(m => (
          <Tag className="cursor-p margin-5" onClick={() => updateKeyWords(m["@id"])} key={m.id}>{m.name}</Tag>
        ))}
      </Spin>
      {isAdmin ?
        <Tag
          style={{position: "absolute", right: 10, bottom: 10}}
          color="#eb2f96" className="cursor-p margin-5"
          onClick={reset}
        >
          Reset <DeleteOutlined/>
        </Tag>
        : null}
    </div>
  )
}

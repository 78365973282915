import React, {useEffect, useState} from 'react'
import {Modal, Form, Input, message} from "antd"
import {useApiContext} from '../../providers/ApiProvider.js';

export default function ModalContact({visible, setVisible, rerender, contact, etb, setContact}) {

  const [form] = Form.useForm();
  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity} = apiDispatch;
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (contact) {
      fetch()
    }
  }, [contact])

  function fetch() {
    let obj = {}
    for (const [key, value] of Object.entries(contact)) {
      switch (key) {
        case "email":
          obj[key] = value;
          break;
        case "name":
          obj[key] = value;
          break;
        case "role":
          obj[key] = value;
          break;
        case "phone":
          obj[key] = value;
          break;
        default:
          break;
      }
    }

    form.setFieldsValue(obj)
  }

  function handleOk() {
    setLoading(true)
    let data = form.getFieldsValue();

    if (contact) {
      apiUpdateEntity("contacts", contact.id, data, response => {
        closeWithReload()
      })
    } else {
      data.establishment = etb;
      apiPostEntity('contacts', data, response => {
        if (response["@type" === "hydra:error"]) {
          return message.error(response['hydra:description'])
        }
        closeWithReload()
      })
    }
  }

  function closeWithReload() {
    if (typeof rerender === "function") {
      rerender();
    }

    setLoading(false)
    handleCancel()
  }

  function handleCancel() {
    form.resetFields()
    setContact(null)
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title="Creer contact"
      onOk={() => form.submit()}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Form
        layout="vertical"
        form={form}
        name="contact"
        onFinish={handleOk}
      >
        <Form.Item
          name="name"
          label="Nom"
          rules={[
            {required: true, message: 'Ce champs ne peut etre vide'}
          ]}
        >
          <Input placeholder="Ex: Ludovic"/>
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {required: true, message: 'Ce champs ne peut etre vide'},
            {type: 'email', message: 'Format email requis'}
          ]}
        >
          <Input placeholder="Ex: ludovic.mmcreation@gmail.com"/>
        </Form.Item>
        <Form.Item
          name="role"
          label="Poste"
        >
          <Input placeholder="Ex: Directeur"/>
        </Form.Item>
        <Form.Item
          name="phone"
          label="Téléphone"
        >
          <Input placeholder="Ex: 06 12 13 14 15"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

import React, {useContext} from 'react';
import {useImmer} from 'use-immer';

const defaultState = {
  tags: [],
  allNeedUpdate: 0,
  tagNeedUpdate: 0,
  loading: true,
  language: "fr",
  hapi: {visible: false, pageId: null}
}

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SEOProvider = ({children}) => {
  const [state, dispatch] = useImmer({...defaultState});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useStateContext() {
  const state = useContext(StateContext);

  if (state === undefined)
    throw new Error("Ut oh, where is my state?");

  return state;
}

function useDispatchContext() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  if (state === undefined)
    throw new Error("Ut oh, where is my state?");

  if (dispatch === undefined)
    throw new Error("Ut oh, where is my dispatch?");

  function setTags(tags) {
    dispatch(draft => {
      draft.tags = tags;
    });
  }

  function reloadTags() {
    dispatch(draft => {
      draft.tagNeedUpdate = state.tagNeedUpdate + 1;
    });
  }

  function reload() {
    dispatch(draft => {
      draft.allNeedUpdate = state.allNeedUpdate + 1;
    });
  }

  function setLoading(bool) {
    dispatch(draft => {
      draft.loading = bool;
    });
  }

  function setter(key, value) {
    dispatch(draft => {
      draft[key] = value;
    });
  }

  return {setTags, reload, setLoading, reloadTags, setter};
}

const useSEOContext = () => {
  return [useStateContext(), useDispatchContext()];
}

export {useSEOContext, SEOProvider, StateContext, DispatchContext};

import React from "react";
import {Space} from "antd";
import Fr from "../Svg/Flag/Fr";
import Gb from "../Svg/Flag/Gb";
import {useSEOContext} from "../../providers/SEOProvider";

export default function LanguageChoice() {

  const [seoState, seoDispatch] = useSEOContext();
  const {setter} = seoDispatch;

  const border = {border: '2px solid orange'}

  return (
    <div className="flex">
      <Space>
        <div
          className="cursor-p"
          onClick={() => setter('language', 'fr')}
          style={seoState.language === "fr" ? {transform: "scale(1.2"} : null}
        >
          <Fr style={seoState.language === 'fr' ? border : null}/>
        </div>
        <div
          className="cursor-p"
          onClick={() => setter('language', 'en')}
          style={seoState.language === "en" ? {transform: "scale(1.2"} : null}
        >
          <Gb style={seoState.language === 'en' ? border : null}/>
        </div>
      </Space>
    </div>
  )
}

import React, {useEffect, useState} from "react";
import {Input, message, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalEditPage({visible, setVisible, page, reload}) {

  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;

  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(page.url)
  }, [page])

  function handleOk() {
    if (!value || value.length < 3)
      return message.error("url trop court");

    const data = {
      url: value
    }

    apiUpdateEntity("tree_pages", page.id, data, response => {
      reload()
      setVisible(false)
    })
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleOk}
      title="Editer votre url"
      destroyOnClose
    >
      <Input
        value={value}
        placeholder="URL de votre page"
        onChange={(e) => setValue(e.target.value)}
      />
    </Modal>
  )
}

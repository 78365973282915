import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Space,
  Tooltip,
  Upload,
} from "antd";
import {
  EditTwoTone,
  FileExcelTwoTone,
  FileImageTwoTone,
  FilePdfTwoTone,
  FileTextTwoTone,
  FileWordTwoTone,
  FileUnknownTwoTone,
  MessageOutlined,
  MoreOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useApiContext } from "../../providers/ApiProvider";
import { stringDateDMonthY } from "../../Helpers";
import TagDropDownLight from "../TagDropDownLight";
import moment from "moment";

export default function TaskRow({
  task,
  reload,
  showDrawer,
  mainTask,
  parentId,
}) {
  const [apiDispatch] = useApiContext();
  const { apiDeleteEntity, apiUpdateEntity, apiPostEntity } = apiDispatch;

  const pdf = (
    <FilePdfTwoTone
      style={{ transform: "scale(1.5)" }}
      twoToneColor="#bb0706"
    />
  );
  const word = (
    <FileWordTwoTone
      style={{ transform: "scale(1.5)" }}
      twoToneColor="#25559a"
    />
  );
  const excel = (
    <FileExcelTwoTone
      style={{ transform: "scale(1.5)" }}
      twoToneColor="#007334"
    />
  );
  const image = (
    <FileImageTwoTone style={{ transform: "scale(1.5)" }} twoToneColor="#777" />
  );
  const text = (
    <FileTextTwoTone style={{ transform: "scale(1.5)" }} twoToneColor="#777" />
  );
  const unknown = (
    <FileUnknownTwoTone
      style={{ transform: "scale(1.5)" }}
      twoToneColor="#777"
    />
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase();
  }

  function makeTag(status) {
    return (
      <div
        className="col w-20per cursor-p"
        style={{ color: "#fff", backgroundColor: status.color }}
      >
        {status.name}
      </div>
    );
  }

  function removeRow() {
    apiDeleteEntity("tasks", task.id, () => {
      reload();
    });
  }

  function actionMenu(e) {
    switch (e.key) {
      case "delete":
        removeRow();
        break;
      case "createFr":
        createPage("fr");
        break;
      case "createEn":
        createPage("en");
        break;
      default:
        if (e.key.startsWith("duplicateAndMove-"))
          DuplicateAndMove(e.key.split("-")[1]);
        else updateParent(e.key);
        break;
    }
  }

  function updateParent(parentIri) {
    const data = { parent: parentIri };

    apiUpdateEntity("tasks", task.id, data, (response) => {
      reload();
    });
  }

  function DuplicateAndMove(parentIri) {
    const data = {
      description: task.description,
      establishment: task.establishment["@id"],
      isDone: false,
      status: "/api/tags/3",
      user: task.user.map((u) => u["@id"]),
      parent: parentIri,
    };
    apiPostEntity("tasks", data, (response) => {
      reload();
    });
  }

  function createPage(language) {
    const data = {
      taskId: task.id,
      language: language,
    };
    apiPostEntity("create-page-from-task", data, (response) => {
      console.log(response);
      reload();
    });
  }

  const menu = (
    <Menu onClick={actionMenu}>
      {task.favorite && (
        <>
          <Menu.Item key="createFr" disabled={task.favorite.page}>
            Creer page SEO fr
          </Menu.Item>
          <Menu.Item key="createEn" disabled={task.favorite.page}>
            Creer page SEO en
          </Menu.Item>
        </>
      )}

      <Menu.SubMenu title="Déplacer vers">
        {mainTask
          .filter((t) => t.id !== parentId)
          .map((t) => (
            <Menu.Item key={t["@id"]}>{t.description}</Menu.Item>
          ))}
      </Menu.SubMenu>

      <Menu.SubMenu title="Dupliquer et déplacer vers">
        {mainTask
          .filter((t) => t.id !== parentId)
          .map((t) => (
            <Menu.Item key={`duplicateAndMove-${t["@id"]}`}>
              {t.description}
            </Menu.Item>
          ))}
      </Menu.SubMenu>

      <Menu.Divider />

      <Menu.Item key="delete">
        <span className="danger">Supprimer</span>
      </Menu.Item>
    </Menu>
  );

  function upload(file) {
    let formData = new FormData();
    formData.append("file", file);

    apiPostEntity("media_objects", formData, (response) => {
      if (response.id) {
        const array = task.documents
          ? task.documents.map((doc) => doc["@id"]).concat(response["@id"])
          : [response["@id"]];
        apiUpdateEntity("tasks", task.id, { documents: array }, () => {
          reload();
        });
      }
    });
    return false;
  }

  function genDoc(ext) {
    switch (ext) {
      case "pdf":
        return pdf;

      case "doc":
      case "docx":
      case "htm":
      case "html":
        return word;

      case "xls":
      case "xlsx":
      case "csv":
        return excel;

      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
      case "svg":
      case "webp":
      case "tif":
      case "tiff":
      case "jfif":
        return image;

      case "rtf":
      case "txt":
      case "md":
      case "markdown":
        return text;

      default:
        return unknown;
    }
  }

  const [editDate, setEditDate] = useState(false);

  function updateDate(date, dateString) {
    if (!dateString) return setEditDate(false);

    apiUpdateEntity("tasks", task.id, { updatedAt: dateString }, (response) => {
      reload();
      setEditDate(false);
    });
  }

  const getDocMenu = (id) => {
    return (
      <Menu onClick={(e) => switchDocMenu(e, id)}>
        <Menu.Item key="remove">Supprimer</Menu.Item>
      </Menu>
    );
  };

  function removeDoc(id) {
    apiDeleteEntity("media_objects", id, () => {
      reload();
    });
  }

  function switchDocMenu(e, id) {
    switch (e.key) {
      case "remove":
        removeDoc(id);
        break;
    }
  }

  const [description, setDescription] = useState(task.description || "");
  const [editDescription, setEditDescription] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editDescription) inputRef.current.focus({ preventScroll: true });
  }, [editDescription]);

  function handleEditDescription() {
    setEditDescription(true);
  }

  function save() {
    setEditDescription(false);

    if (
      !description ||
      description.length < 2 ||
      description === task.description
    )
      return;

    apiUpdateEntity(
      "tasks",
      task.id,
      { description: description.trim() },
      () => {}
    );
  }

  return (
    <div className="row">
      <div
        className="flex space-between col w-50per"
        style={{ textAlign: "left" }}
      >
        <div className="flex">
          {editDescription ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          ) : (
            <span>
              {description} <EditTwoTone onClick={handleEditDescription} />
            </span>
          )}
          {/*<Paragraph editable={{onChange: setDescription}}>{description}</Paragraph>*/}
          {task.favorite ? (
            <strong>&nbsp;{task.favorite.combination.result}</strong>
          ) : null}
        </div>

        <div>
          <Badge count={task.note ? task.note.length : 0} dot>
            <MessageOutlined
              className="cursor-p"
              onClick={() =>
                showDrawer({
                  id: task.id,
                  initialValue: task.note,
                  note: task.note,
                })
              }
            />
          </Badge>
        </div>
      </div>

      <TagDropDownLight
        task={task}
        content={makeTag(task.status)}
        reload={reload}
      />

      <div className="col w-15per">
        {editDate ? (
          <DatePicker
            style={{ width: 120 }}
            defaultValue={moment(task.updatedAt)}
            onChange={updateDate}
          />
        ) : (
          <Space>
            {stringDateDMonthY(task.updatedAt)}
            <EditTwoTone onClick={() => setEditDate(true)} />
          </Space>
        )}
      </div>

      <div className="col w-10per">
        {task.user.map((user) => (
          <Tooltip title={user.username} key={user.username}>
            <Avatar className="cursor-p" size="small">
              {capitalizeFirstLetter(user.username)}
            </Avatar>
          </Tooltip>
        ))}
      </div>

      <div className="col w-15per">
        <Space
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            gap: "2px",
            justifyContent: task.documents.length >= 5 ? "start" : "center",
          }}
        >
          {task.documents.map((doc) => {
            return (
              <Dropdown
                key={doc["@id"]}
                overlay={() => getDocMenu(doc["@id"].split("/").pop())}
                trigger={["contextMenu"]}
              >
                <div>
                  <Tooltip title={doc.originalName}>
                    <a href={doc.contentUrl || "#"} target="_blank">
                      {doc.originalName
                        ? genDoc(doc.originalName.split(".").pop())
                        : "Unknown file name"}
                    </a>
                  </Tooltip>
                </div>
              </Dropdown>
            );
          })}
          <Upload
            name="file"
            showUploadList={false}
            accept=".doc,.docx,.xls,.xlsx,.csv,.pdf,.rtf,.txt,.jpg,.jpeg,.png,.gif,.bmp,.svg,.webp,.tif,.tiff,.jfif,.md,.markdown,.htm,.html"
            beforeUpload={upload}
          >
            <Button size="small" icon={<UploadOutlined />} />
          </Upload>
        </Space>
      </div>

      <div className="col w-5per">
        <Dropdown overlay={menu} trigger={["click"]}>
          <MoreOutlined onClick={(e) => e.preventDefault()} />
        </Dropdown>
      </div>
    </div>
  );
}

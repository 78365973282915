import React, {useContext} from 'react';
import {useImmer} from 'use-immer';
import {notification} from 'antd';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
let _account = null;

try {
  _account = window.localStorage.getItem('account') || null;
  _account = _account ? JSON.parse(_account) : null;
} catch (e) {
  // nothing to do ^^
}

const defaultState = {
  account: _account,
  notifications: [],
};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const AccountProvider = ({children}) => {
  const [state, dispatch] = useImmer({...defaultState});
  // alternatively without Immer:  const [state, dispatch] = useState({});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

// ---------------------------------------------------
// Context usage function declaration
// ---------------------------------------------------
function useStateContext() {
  const state = useContext(StateContext);

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  return state;
}

function useDispatchContext() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  if (dispatch === undefined) {
    throw new Error("Ut oh, where is my dispatch?");
  }

  function accountSet(value) {
    localStorage.setItem('account', JSON.stringify(value));

    dispatch(draft => {
      draft.account = value;
    });
  }

  function addNotification(item) {
    dispatch(draft => {
      let notifications = draft.notifications;
      if (notifications.filter(notif => notif.id === item.id).length === 1) {
        updateNotification(item);
      } else {
        draft.notifications = [item, ...draft.notifications];
        if (item.type) {
          notification[item.type]({
            message: item.message,
            description: item.description,
          });
        } else {
          notification.info({
            message: item.message,
            description: item.description,
          });
        }
      }
    });
  }

  function updateNotification(notif) {
    dispatch(draft => {
      draft.notifications = [notif, ...draft.notifications.filter(item => item.id !== notif.id)]
    })
  }

  function deleteNotification(notif) {
    dispatch(draft => {
      draft.notifications = [...draft.notifications.filter(item => item.id !== notif.id)]
    })
  }

  function resetState() {
    localStorage.removeItem("account");

    dispatch(draft => {
      draft.account = null;
      draft.notifications = [];
    });
  }

  return {
    accountSet,
    addNotification,
    deleteNotification,
    resetState
  };
}

const useAccountContext = () => {
  return [useStateContext(), useDispatchContext()]
};

export {useAccountContext, AccountProvider, StateContext, DispatchContext};

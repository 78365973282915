import React, {useState} from "react"
import {Button, message, Space} from "antd";
import {CopyOutlined, EditTwoTone} from '@ant-design/icons';
import {Editor} from "@tinymce/tinymce-react";
import {copy} from "../Helpers";

export default function DivTinyEdit({update, cancel, content, setContent, canCopy = false}) {

  const [edit, setEdit] = useState(false);

  function onUpdate() {
    update();
    setEdit(false);
  }

  function onCancel() {
    cancel();
    setEdit(false);
  }

  function copyWithMessage() {
    var div = document.createElement("div");
    div.innerHTML = content;
    var txt = div.textContent || div.innerText || ""
    const success = copy(txt);

    return success ? message.success("Texte copié") : message.error("un probleme est survenue");
  }

  return (
    edit ?
      <>
        <Editor
          apiKey="gbvvbpfr94ka54sjradg08gdap68at2811ruttkx5ktlpzkd"
          value={content}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
          }}
          onEditorChange={(value) => setContent(value)}
        />
        <Space className="mt-20">
          <Button type="primary" onClick={onUpdate}>Enregistrer</Button>
          <Button type="danger" onClick={onCancel}>Annuler</Button>
        </Space>
      </>
      : <div>
        <div dangerouslySetInnerHTML={{__html: content}}/>
        <Space>
          {canCopy ? <CopyOutlined onClick={copyWithMessage}/> : null}
          <EditTwoTone className="cursor-p" onClick={() => setEdit(true)}/>
        </Space>
      </div>
  )
}

import React from "react";
import {useDrop} from "react-dnd";
import FavoriteCard from "./FavoriteCard";
import {LinkOutlined, MoreOutlined} from "@ant-design/icons";
import {Dropdown, Tooltip, Menu} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useSEOContext} from "../../providers/SEOProvider";
import {useParams} from "react-router-dom";

export default function Page({page, deg, showModal, bulkEditing}) {

  const [apiDispatch] = useApiContext();
  const {apiDeleteEntity, apiPostEntity} = apiDispatch;
  const [seoState, seoDispatch] = useSEOContext();
  const {reload, setLoading, setter} = seoDispatch;
  const params = useParams();

  const [{canDrop, isOver}, drop] = useDrop({
    accept: "favorite",
    drop: () => ({type: "page", pageIri: page["@id"]}),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = canDrop && isOver;
  let backgroundColor = "#ddd";
  if (isActive)
    backgroundColor = '#ccc';
  else if (canDrop)
    backgroundColor = '#bbb';

  const link = (
    page.url ?
      <a href={page.url} target="_blank"><LinkOutlined/></a>
      :
      <Tooltip title="Pas de lien">
        <LinkOutlined/>
      </Tooltip>
  )

  function actionMenu(e) {
    switch (e.key) {
      case "url":
        showModal(page)
        break;
      case 'duplicate':
        duplicate();
        break;
      case"remove":
        removePage();
        break;
      case "editHapi":
        setter('hapi', {visible: true, pageId: page.hapiId});
        break;
    }
  }

  function duplicate() {
    setLoading(true);
    const data = {
      language: seoState.language === 'fr' ? 'en' : 'fr',
      name: page.name,
      establishment: "/api/establishments/" + params.id,
      type: page.type
    }

    apiPostEntity("tree_pages", data, () => {
      reload();
    })
  }

  function removePage() {
    setLoading(true);
    apiDeleteEntity("tree_pages", page.id, () => {
      reload();
    })
  }

  const menu = (
    <Menu onClick={actionMenu}>
      {!!(page.type === 'seo' && page.hapiId) &&
        <Menu.Item key="editHapi">
          Editer page Hapi
        </Menu.Item>
      }
      <Menu.Item key="url">
        Editer url
      </Menu.Item>
      <Menu.Item key="duplicate">
        Dupliquer en {seoState.language === 'fr' ? 'EN' : 'FR'}
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item key="remove" disabled={page.favoriteCombinationKeyWord || page.childs.length}>
        <span className="danger">Supprimer</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div
        ref={!page.favoriteCombinationKeyWord ? drop : null}
        className="trello-card-page"
        style={{marginLeft: deg, backgroundColor}}
      >

        <div className="flex space-between">
          <div>{page.name} {link}</div>
          <div>
            <Dropdown overlay={menu} trigger={['click']}>
              <MoreOutlined onClick={(e) => e.preventDefault()}/>
            </Dropdown>
          </div>
        </div>
        {page.favoriteCombinationKeyWord ?
          <FavoriteCard favorite={page.favoriteCombinationKeyWord} noDelete={true} bulkEditing={bulkEditing}/>
          : null}

      </div>
      {page.childs.map(child => (
        <Page key={child.id} page={child} deg={deg + 5} showModal={showModal}/>
      ))}
    </div>
  )
}

import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, Select, Space, Tag, Tooltip } from 'antd';
import { AppstoreOutlined, BarsOutlined, PlusOutlined } from '@ant-design/icons';
import ModalEstablishment from "../components/Modal/ModalEstablishment.js"
import { useApiContext } from '../providers/ApiProvider.js';
import { useAuthContext } from "../providers/AuthProvider";
import EstablishmentsList from "../components/Establishments/EstablishmentsList";
import EstablishmentsCard from "../components/Establishments/EstablishmentsCard";
import { billingInfoList } from '../Helpers.js';

const { Search } = Input;

export default function Establishments() {

  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiUpdateEntity } = apiDispatch;
  const [authState] = useAuthContext();
  const { auth } = authState;

  const inputSearch = useRef(null);
  const [searchEtb, setSearchEtb] = useState(null)

  const initialFilters = [{ name: 'name', value: '' }];
  const [filters, setFilters] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isCard, setIsCard] = useState(true);

  const isAdmin = auth.roles.includes("ROLE_SUPER_ADMIN");

  function handleChange(e) {
    clearTimeout(searchEtb)
    setSearchEtb(setTimeout(async () => {
      if (!e.target.value.trim()) {
        return  setFilters(prev => prev.filter(item => item.name !== 'name'));
      }
      setFilters(prev => [...prev.filter(item => item.name !== 'name'), { name: 'name', value: e.target.value.trim() }]);
    }, 500))
  }

  function handleBillingFilter(val) {
    if (!val) {
      return  setFilters(prev => prev.filter(item => item.name !== 'billingInfos'));
    }

    setFilters(prev => [...prev.filter(item => item.name !== 'billingInfos'), { name: 'billingInfos', value: val }])
  }

  return (
    <div className="contenu-tab">
      <div className="margin-10 flex space-between">
        <Space>
          <span>Filtrer</span>
          <Input
            ref={inputSearch}
            placeholder="Nom de compte"
            style={{ width: 200 }}
            onChange={handleChange}
            allowClear
          />
          <Select style={{ width: '200px' }} onChange={handleBillingFilter} placeholder="Facturation" allowClear>
          <Select.Option key="empty" value={'-'}>-</Select.Option>
            {
              billingInfoList.map(item => <Select.Option key={item} value={item}>{item}</Select.Option>)
            }
          </Select>
        </Space>
        <Space>
          {isAdmin ?
            <div>
              <Link to="/create-establishment">
                <Button
                  icon={<PlusOutlined />}
                />
              </Link>
            </div> : null}
          <Tooltip title={isCard ? "Vue liste" : "Vue carte"}>
            <Button
              onClick={() => setIsCard(prev => !prev)}
              type={'default'}
              icon={isCard ? <AppstoreOutlined /> : <BarsOutlined />}
            />
          </Tooltip>
        </Space>
      </div>

      {isCard ? <EstablishmentsCard filters={filters} /> : <EstablishmentsList filters={filters} />}

      <ModalEstablishment
        visible={visible}
        setVisible={setVisible}
        apiPostEntity={apiPostEntity}
        apiUpdateEntity={apiUpdateEntity}
      />
    </div>
  )
}

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuthContext} from '../providers/AuthProvider.js';
import MyLayout from "./Layout/MyLayout.js";

const PrivateRoute = ({component: Component, sidebar, searchbar,  ...rest}) => {

  const [authState, authDispatch] = useAuthContext();
  const {authIsLogged, authLogout} = authDispatch

  const renderer = (sidebar,props) => {
    if (authIsLogged()) {
      return <MyLayout sidebar={sidebar} private={true} {...props}><Component {...props} /></MyLayout>
    }
    authLogout()
    window.location.href = '/login'
  }

  return (
    <Route {...rest} render={renderer.bind(null,sidebar)} />
  );
};

export default PrivateRoute;

import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {Button, Select, Modal, Tag} from "antd";
import {PlusOutlined, MinusOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useParams} from "react-router-dom";

const {confirm} = Modal;
const {Option} = Select;

export default function CombinationChoice({myCombis, rerender}) {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiPostEntity, apiDeleteEntity, apiFetchSubResource} = apiDispatch;

  const [combinations, setCombinations] = useState([[null, null, null]])
  const [categories, setCategories] = useState([])
  const [process, setProcess] = useState(false)
  const [result, setResult] = useState([])

  const [keyWords, setKeyWords] = useState([])

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    apiFetchCollection("categories", {}, response => {
      setCategories(response["hydra:member"])
    })
  }

  function fetchKeyWords(id) {
    const data = {
      id: params.id,
      filters: [{
        name: "category.id",
        value: id
      }]
    }
    apiFetchSubResource("establishments", data, "key_words", response => {
      if (response["hydra:member"].length)
        setKeyWords(response["hydra:member"])
      else
        setKeyWords([{id: 'emptyCat', name: 'vide'}])
    })
  }

  function onChange(value, id, key) {
    fetchKeyWords(value)

    var arr = [...combinations]

    arr[id][key] = value
    setCombinations(arr)
  }

  function addRow() {
    setCombinations([...combinations, [null, null, null]])
  }

  function toggleColumn(id, mode) {
    var arr = [...combinations]

    switch (mode) {
      case "add":
        arr[id].push(null);
        break;
      case "remove":
        arr[id].splice(-1, 1)
        break;
    }

    setCombinations(arr)
  }

  function generate() {
    const data = {
      etbId: params.id,
      combis: purify(combinations)
    }

    if (data.combis.length) {
      apiPostEntity("combination", data, response => {
        setResult(response.result)
        setProcess(false)
        setKeyWords([])

        setCombinations([[null, null, null]])
        rerender()
      })
    }
  }

  function purify(arr) {
    let response = []
    const olds = myCombis.map(m => m.idCategories)

    arr.map(a => {
      let row = []

      if (!olds.includes(a.join(" "))) {
        a.map(b => {
          if (b)
            row.push(b)
        })

        if (row.length > 1)
          response.push(row)
      }
    })

    return response
  }

  function showConfirm(id) {
    confirm({
      title: 'Etes vous sûr de vouloir supprimer cette catégorie?',
      icon: <ExclamationCircleOutlined/>,
      content: 'Toutes les combinaisons liées seront supprimées.',
      onOk() {
        //console.log('ok', id);
        apiDeleteEntity("combination_categories", id, response => {
          //console.log(response)
          setTimeout(() => rerender(), 1000);
        })
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }


  return (
    <div className="contenu-tab">

      <div className="flex wrap space-between">
        <div>
          {myCombis.map((c, id) => (
            <div key={id} className="flex">
              <div className="flex align-center" style={{width: 35}}>
                {id + 1}
              </div>
              {
                c.idCategories.split(" ").map((key, index) => (
                  <div key={index}>
                    <Select
                      style={{width: 200}}
                      value={Number(key)}
                      disabled
                    >
                      {
                        categories.map(category => (
                          <Option key={category.id} value={category.id}>{category.name}</Option>
                        ))
                      }
                    </Select>
                  </div>
                ))
              }
              <Button
                onClick={() => showConfirm(c.id)}
                type="danger"
                icon={<DeleteOutlined/>}
              />
            </div>
          ))}

          {combinations.map((c, id) => (
            <div key={id} className="flex">
              <div className="flex align-center" style={{width: 35}}>
                {myCombis.length + 1}
              </div>
              {c.map((key, index) => (
                <div key={index}>
                  <Select
                    showSearch
                    style={{width: 200}}
                    placeholder="Selectionner une Categorie"
                    optionFilterProp="children"
                    onChange={(value) => onChange(value, id, index)}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {categories.map(category => (
                      <Option key={category.id} value={category.id}>{category.name}</Option>
                    ))}
                  </Select>
                </div>
              ))}
              <Button
                disabled={Object.keys(c).length > 4}
                onClick={() => toggleColumn(id, "add")}
                type="primary"
                icon={<PlusOutlined/>}
              />
              <Button
                disabled={Object.keys(c).length < 3}
                onClick={() => toggleColumn(id, "remove")}
                type="danger"
                icon={<MinusOutlined/>}
              />
            </div>
          ))}
        </div>

        <div>
          {keyWords.length ?
            <div>
              <h3>Liste des mots clés</h3>
              {keyWords.map(kw => (
                kw.id === "emptyCat" ? <em key={kw.id}>Vide ...</em> : <Tag key={kw.id}>{kw.name}</Tag>
              ))}
            </div> : null}
        </div>

      </div>

      <div>
        <Button
          className="margin-5 block"
          onClick={addRow}
          type="primary"
        >
          Ajouter combinason <PlusOutlined/>
        </Button>
        <Button
          className="margin-5 block"
          type="primary"
          onClick={generate}
          loading={process}
        >
          Génerer
        </Button>
      </div>

      <div className="mt-50">
        {result.map((r, id) => (
          <div key={id}>
            <h2>Combinaison {id + 1} ({r.length})</h2>
            {r.map((c, id) => (
              <div key={id}>{c.join(" ")}</div>
            ))}
          </div>
        ))}
      </div>

    </div>
  )
}

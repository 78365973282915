import React, {useEffect, useRef, useState} from 'react'
import {useApiContext} from '../providers/ApiProvider.js';
import {Alert, Button, Modal, Progress, Spin, Tag, Tooltip, Upload} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleTwoTone,
  PlusOutlined
} from '@ant-design/icons';
import ModalKeyWord from "../components/Modal/ModalKeyWord.js"
import ModalCategory from "../components/Modal/ModalCategory.js"
import {useAuthContext} from "../providers/AuthProvider";
import DroppableCategory from "../components/KeyWordManager/DroppableCategory";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import Selectable from "../components/Selectable";

const {confirm} = Modal;

export default function KeyWords() {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiPostEntity, apiUpdateEntity, apiDeleteEntity} = apiDispatch;
  const [authState] = useAuthContext();
  const {auth} = authState;

  const [categories, setCategories] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(0);

  const [visible, setVisible] = useState(false);
  const [withLocation, setWithLocation] = useState(false);
  const [geoLocFilterType, setGeoLocFilterType] = useState(0);
  const [categoryIri, setCategoryIri] = useState(null);
  const [toUpdate, setToUpdate] = useState(null);
  const [loading, setLoading] = useState(true);

  const [visibleCat, setVisibleCat] = useState(false);
  const [catToUpdate, setCatToUpdate] = useState(null);

  const isAdmin = auth.roles.includes("ROLE_ADMIN");

  const [percent, setPercent] = useState(0);
  const [filesError, setFilesError] = useState([]);

  useEffect(() => {
    setLoading(true)
    fetch()
  }, [needUpdate])

  function fetch() {
    const params = {
      filters: [
        {
          name: "pagination",
          value: "false"
        }
      ]
    }
    apiFetchCollection('categories', params, response => {
      setCategories(response["hydra:member"])
      setLoading(false)
    })
  }

  function displayModal(cat, keyword = null) {
    if (cat.geoLoc) {
      setWithLocation(true)
    }
    if (cat.geoLocFilterType) {
      setGeoLocFilterType(cat.geoLocFilterType)
    }
    if (keyword) {
      setToUpdate(keyword);
    }
    setCategoryIri(cat["@id"])
    setVisible(true)
  }

  var count = 0;

  function importCsv(file, files) {
    if (!count)
      runUpload(files);

    count++;

    return false;
  }

  function runUpload(files) {
    setPercent(0.1)
    //console.log("run");
    let nbFiles = files.length;

    files.map(file => {
      let formData = new FormData()
      formData.append('file', file);

      apiPostEntity("import-csv", formData, response => {
        if (!response.success)
          setFilesError(filesError => [...filesError, {file: file.name, message: response.message}]);

        nbFiles--;
        setPercent(((files.length - nbFiles) * 100) / files.length)
      })
    })

  }

  const [selected, setSelected] = useState(new Set());
  const scrollerRef = useRef(null);

  function deleteCategory(id) {
    setLoading(true)
    apiDeleteEntity('categories', id, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }

  function showDeleteConfirm(id) {
    confirm({
      title: 'Etes-vous sûre de vouloir supprimer cette catégorie ?',
      icon: <ExclamationCircleOutlined/>,
      content: '',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        deleteCategory(id)
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  return (
    <div className="contenu-tab selectable-container">
      <Selectable setSelected={setSelected} scrollerRef={scrollerRef}/>

      <Spin spinning={loading}>
        <h1 style={{textAlign: "center"}}>Liste des mots-clés</h1>

        {isAdmin &&
          <div className="flex space-between mb-30">
            <Button type="primary" onClick={() => setVisibleCat(true)}><PlusOutlined/>Ajouter une catégorie</Button>
            <div>
              <Upload
                accept=".csv"
                showUploadList={false}
                beforeUpload={importCsv}
                multiple
              >
                <Button type="primary">Importer CSV</Button>
              </Upload>&nbsp;&nbsp;&nbsp;
              <Tooltip title="Le traitement des fichiers est en différé, cela prendra environ 1H">
                <InfoCircleTwoTone/>
              </Tooltip>

              {percent > 0 ?
                <div>
                  <Progress percent={Math.round(percent)} size="small"/>
                  {filesError.map((file, id) =>
                    <Alert type="error" key={id} banner message={`${file.file} => ${file.message}`}/>
                  )}
                </div> : null}

            </div>
          </div>}

        <DndProvider backend={HTML5Backend}>
          {
            categories.map(c => (
              <div style={{padding: '0 20px'}} key={c['@id']}>
                <div>
                  <span className="bold">{c.name}</span>
                  {isAdmin &&
                    <span>
                  <EditOutlined onClick={() => {
                    setVisibleCat(true);
                    setCatToUpdate(c);
                  }}/> :&nbsp;
                      <Tag
                        color="#108ee9" size="small"
                        onClick={() => displayModal(c)}
                        className="site-tag-plus">
                    <PlusOutlined/> Ajouter
                  </Tag>
                </span>}
                  {(isAdmin && !c.keyWords.length) &&
                    <Button size="small" type="danger" icon={<DeleteOutlined/>}
                            onClick={() => showDeleteConfirm(c.id)}>Supprimer</Button>}
                </div>
                <DroppableCategory
                  key={c.id}
                  category={c}
                  displayModal={displayModal}
                  selected={selected}
                  setSelected={setSelected}
                  rerender={() => setNeedUpdate(needUpdate + 1)}
                  setLoading={setLoading}
                />
              </div>
            ))
          }
        </DndProvider>

        <ModalKeyWord
          visible={visible}
          setVisible={setVisible}
          withLocation={withLocation}
          geoLocFilterType={geoLocFilterType}
          setWithLocation={setWithLocation}
          categoryIri={categoryIri}
          setCategoryIri={setCategoryIri}
          toUpdate={toUpdate}
          setToUpdate={setToUpdate}
          rerender={() => setNeedUpdate(needUpdate + 1)}
        />
        <ModalCategory
          visible={visibleCat}
          setVisible={setVisibleCat}
          apiPostEntity={apiPostEntity}
          apiUpdateEntity={apiUpdateEntity}
          catToUpdate={catToUpdate}
          setCatToUpdate={setCatToUpdate}
          rerender={() => setNeedUpdate(needUpdate + 1)}
        />
      </Spin>
    </div>
  )
}

import React, {useEffect, useRef, useState} from "react";
import {Button, Input, Select, Upload} from "antd";
import {
  AlignRightOutlined,
  AlignLeftOutlined,
  UploadOutlined,
  AlignCenterOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  DeleteOutlined
} from '@ant-design/icons';

export default function Canva() {

  const [children, setChildren] = useState([])
  const [selected, setSelected] = useState({})
  const [isDragging, setIsDragging] = useState({})

  const [background, setBackground] = useState("#ddd")
  const pixelFontSize = fontSizeList()

  const refSelected = useRef(selected);

  useEffect(() => {
    refSelected.current = selected;
  }, [selected])

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [])

  function handleUserKeyPress(event) {
    if (event.keyCode === 46) {
      setChildren(children => children.filter(child => child.id !== refSelected.current.id));
      setSelected({});
    }
  }

  function addText(e) {
    e.stopPropagation();
    const id = children.length + 1;
    var canva = document.getElementById("canva").getBoundingClientRect();

    const child = {
      id: id,
      style: {
        whiteSpace: "pre-wrap",
        position: "absolute",
        top: (canva.height / 2) - (34 / 2),
        left: (canva.width / 2) - (122 / 2),
        zIndex: 10,
        fontSize: 16,
        color: "rgb(0,0,0,0.6)",
        textAlign: "left",
        fontWeight: "normal",
        fontStyle: "normal",
        textDecoration: "none",
        padding: "5px 10px"
      },
      type: "text",
      content: "Ajouter du texte"
    }

    setSelected(child)
    setChildren([...children, child])
  }

  function addImg(file) {
    if (file.size > 10000000)
      return message.error("Max 10Mo");

    getBase64(file, image => {
      const id = children.length + 1;
      var canva = document.getElementById("canva").getBoundingClientRect();

      const child = {
        id: id,
        style: {
          position: "absolute",
          top: 50,
          left: (canva.width / 2) - (400 / 2),
          width: 400,
        },
        type: "image",
        src: image
      }

      setSelected(child)
      setChildren([...children, child])
    });

    return false;
  }

  function addBackgroundImg(file) {
    if (file.size > 10000000)
      return message.error("Max 10Mo");

    getBase64(file, image => {
      const id = children.length + 1;

      const child = {
        position: "absolute",
        id: id,
        style: {
          top: 0,
          left: 0,
          width: 800,
          objectFit: "cover"
        },
        type: "image",
        src: image
      }

      setSelected(child)
      setChildren([...children, child])
    });

    return false;
  }

  function addBtn(e) {
    e.stopPropagation();
    const id = children.length + 1;
    var canva = document.getElementById("canva").getBoundingClientRect();

    const child = {
      id: id,
      style: {
        position: "absolute",
        outline: "none",
        backgroundColor: "#1890ff",
        top: "90%",
        left: (canva.width / 2) - 45,
        zIndex: 10,
        fontSize: 16,
        padding: "5px 20px",
        border: 0,
        color: "#fff",
        borderRadius: 30
      },
      type: "button",
      content: "Fermer"
    }

    setSelected(child)
    setChildren([...children, child])
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function onDragStart(e, child) {
    if (selected.id !== child.id)
      setSelected(child);

    var el = document.getElementById(child.id).getBoundingClientRect();
    setIsDragging({id: child.id, x: e.clientX - el.x, y: e.clientY - el.y});
  }

  function onDragOver(e) {
    e.preventDefault()
  }

  function onDrop(e) {
    var canva = document.getElementById("canva").getBoundingClientRect();

    var x = e.clientX - canva.x - isDragging.x - 2;
    var y = e.clientY - canva.y - isDragging.y - 2;

    var objIndex = children.findIndex(child => child.id === selected.id);

    children[objIndex].style.left = x;
    children[objIndex].style.top = y;

    setChildren([...children])
  }

  function addBackground(value) {
    setBackground(value)
  }

  function fontSizeList() {
    let response = [];
    for (let i = 1; i <= 60; i++) {
      if (i % 2 === 0)
        response.push(i)
    }

    return response;
  }

  function updateChildStyle(key, value) {
    var objIndex = children.findIndex(child => child.id === selected.id);
    children[objIndex].style[key] = value;

    setChildren([...children])
  }

  function updateChild(key, value) {
    var objIndex = children.findIndex(child => child.id === selected.id);
    children[objIndex][key] = value;

    setChildren([...children])
  }

  function displayChild(child) {
    switch (child.type) {
      case "text":
        return (
          <div
            className={selected.id === child.id ? "selected cursor-p" : "cursor-p"}
            key={child.id}
            id={child.id}
            draggable="true"
            onDragStart={e => onDragStart(e, child)}
            style={{...child.style}}
            onClick={e => {
              e.stopPropagation();
              setSelected(child)
            }}
          >
            {child.content}
          </div>
        )
      case "image":
        return (
          <img
            key={child.id}
            id={child.id}
            className={selected.id === child.id ? "selected cursor-p" : "cursor-p"}
            draggable="true"
            onDragStart={e => onDragStart(e, child)}
            src={child.src}
            style={{...child.style}}
            onClick={e => {
              e.stopPropagation();
              setSelected(child)
            }}
          />
        )
      case "button":
        return (
          <button
            key={child.id}
            style={{...child.style}}
            id={child.id}
            className={selected.id === child.id ? "selected cursor-p btn" : "cursor-p btn"}
            draggable="true"
            onDragStart={e => onDragStart(e, child)}
            onClick={e => {
              e.stopPropagation();
              setSelected(child)
            }}
          >
            {child.content}
          </button>
        )
    }
  }

  return (
    <div style={{width: "100vw", height: "100vh", display: "flex"}} onClick={() => setSelected({})}>
      <div style={{width: "25%", backgroundColor: "red"}}></div>

      <div
        style={{width: "50%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative"}}
      >
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "#eee",
          height: 60,
          display: "flex",
          alignItems: "center"
        }}>
          <Button onClick={addText}>
            Ajouter texte
          </Button>

          <Upload
            accept=".jpeg,.jpg,.png"
            showUploadList={false}
            beforeUpload={addBackgroundImg}
          >
            <Button icon={<UploadOutlined/>}>Mettre Image de fond</Button>
          </Upload>

          <Upload
            accept=".jpeg,.jpg,.png"
            showUploadList={false}
            beforeUpload={addImg}
          >
            <Button icon={<UploadOutlined/>}>Ajouter image</Button>
          </Upload>

          <Input.Search
            style={{width: 200}}
            placeholder="Couleur de fond"
            enterButton="valider"
            onSearch={addBackground}
          />

          <Button onClick={addBtn}>
            Ajouter un bouton
          </Button>
        </div>

        {selected.type === "text" ?
          <div style={{
            position: "absolute",
            top: 60,
            left: 0,
            right: 0,
            backgroundColor: "#eee",
            height: 60,
            display: "flex",
            alignItems: "center"
          }}>
            <Select
              placeholder="taille"
              onClick={e => e.stopPropagation()}
              style={{width: 100}}
              value={selected ? selected.style.fontSize : null}
              onChange={value => updateChildStyle("fontSize", value)}
            >
              {pixelFontSize.map(pixel => (
                <Select.Option key={pixel} value={pixel}>{pixel} px</Select.Option>
              ))}
            </Select>

            <Input.Search
              onClick={e => e.stopPropagation()}
              style={{width: 200}}
              placeholder="couleur"
              enterButton="valider"
              onSearch={(value) => updateChildStyle("color", value)}
            />

            <Input.TextArea
              style={{width: 400}}
              onClick={e => e.stopPropagation()}
              value={selected ? selected.content : null}
              onChange={e => updateChild("content", e.target.value)}
            />

            <Button
              icon={<AlignCenterOutlined/>}
              onClick={e => {
                e.stopPropagation();
                updateChildStyle("textAlign", "center")
              }}
            />
            <Button
              icon={<AlignLeftOutlined/>}
              onClick={e => {
                e.stopPropagation();
                updateChildStyle("textAlign", "left")
              }}
            />
            <Button
              icon={<AlignRightOutlined/>}
              onClick={e => {
                e.stopPropagation();
                updateChildStyle("textAlign", "right")
              }}
            />

            <Button
              icon={<BoldOutlined/>}
              onClick={e => {
                e.stopPropagation();
                if (selected.style.fontWeight === "normal")
                  updateChildStyle("fontWeight", "bold");
                else
                  updateChildStyle("fontWeight", "normal");
              }}
            />
            <Button
              icon={<ItalicOutlined/>}
              onClick={e => {
                e.stopPropagation();
                if (selected.style.fontStyle === "normal")
                  updateChildStyle("fontStyle", "italic");
                else
                  updateChildStyle("fontStyle", "normal");
              }}
            />
            <Button
              icon={<UnderlineOutlined/>}
              onClick={e => {
                e.stopPropagation();
                if (selected.style.textDecoration === "none")
                  updateChildStyle("textDecoration", "underline");
                else
                  updateChildStyle("textDecoration", "none");
              }}
            />

            <Button
              icon={<DeleteOutlined/>}
              type="danger"
              onClick={e => {
                e.stopPropagation();
                setChildren(children.filter(child => child.id !== selected.id))
                setSelected({})
              }}
            />

          </div> : null}

        {selected.type === "image" ?
          <div style={{
            position: "absolute",
            top: 60,
            left: 0,
            right: 0,
            backgroundColor: "#eee",
            height: 60,
            display: "flex",
            alignItems: "center"
          }}>

            <Button
              icon={<DeleteOutlined/>}
              type="danger"
              onClick={e => {
                e.stopPropagation();
                setChildren(children.filter(child => child.id !== selected.id))
                setSelected({})
              }}
            />

          </div> : null}

        {selected.type === "button" ?
          <div style={{
            position: "absolute",
            top: 60,
            left: 0,
            right: 0,
            backgroundColor: "#eee",
            height: 60,
            display: "flex",
            alignItems: "center"
          }}>

            <Input.Search
              placeholder="texte"
              onClick={e => e.stopPropagation()}
              style={{width: 200}}
              enterButton="valider"
              onSearch={(value) => updateChild("content", value)}
            />

            <Input.Search
              placeholder="couleur de fond"
              onClick={e => e.stopPropagation()}
              style={{width: 200}}
              enterButton="valider"
              onSearch={(value) => updateChildStyle("backgroundColor", value)}
            />

            <Input.Search
              placeholder="couleur texte"
              onClick={e => e.stopPropagation()}
              style={{width: 200}}
              enterButton="valider"
              onSearch={(value) => updateChildStyle("color", value)}
            />

            <Button
              icon={<DeleteOutlined/>}
              type="danger"
              onClick={e => {
                e.stopPropagation();
                setChildren(children.filter(child => child.id !== selected.id))
                setSelected({})
              }}
            />

            <Select
              placeholder="forme"
              onClick={e => e.stopPropagation()}
              style={{width: 100}}
              value={selected ? selected.style.borderRadius : null}
              onChange={value => updateChildStyle("borderRadius", value)}
            >
                <Select.Option value={3}>carré</Select.Option>
                <Select.Option value={30}>arrondi</Select.Option>
            </Select>

          </div> : null}


        <div
          style={{
            width: 800,
            height: 600,
            backgroundColor: background,
            border: "2px solid #3ce2e8",
            position: "relative",
            overflow: "hidden"
          }}
          id="canva"
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          {children.map(child => (
            displayChild(child)
          ))}
        </div>
      </div>


      <div style={{width: "25%", backgroundColor: "red"}}></div>
    </div>
  )
}

import React, {useState} from 'react';
import {Layout, Card, Avatar} from 'antd';
import {useAuthContext} from '../../providers/AuthProvider.js';
import NavPrivate from './NavPrivate.js';

const Sidebar = () => {

  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider className="sidebar-layout no-select" theme="light" collapsible collapsed={collapsed} onCollapse={() => {
      setCollapsed(!collapsed)
    }}>
        <Card style={{borderRadius: 0, borderWidth: "0 1px 0 0"}}>
          <Avatar>{auth.username[0].toUpperCase()}</Avatar>
          {!collapsed ? <span className="username">{auth.username}</span> : null}
        </Card>
        <NavPrivate/>
    </Layout.Sider>
  );
};

export default Sidebar;

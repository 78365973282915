import React, {useState} from 'react';
import {BackTop, Space} from "antd";
import Messages from "../components/Dashboard/Messages";
import Historic from "../components/Dashboard/Historic";
import SeoTasks from "../components/Dashboard/SeoTasks";

export default function Dashboard() {

  const [etbFilter, setEtbFilter] = useState("");

  return (
    <div className="contenu-tab" style={{overflow: "auto"}}>

      <Space direction="vertical" className="w-100per">
        <SeoTasks etbFilter={etbFilter} setEtbFilter={setEtbFilter}/>
        <Messages etbFilter={etbFilter}/>
        <Historic etbFilter={etbFilter}/>
      </Space>

      <BackTop/>

    </div>
  )
}


import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useApiContext} from "../../providers/ApiProvider";
import {Button, DatePicker, Form, message, Upload} from "antd";
import moment from 'moment';

export default function UploadFile({setNeedUpdate}) {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;

  const [form] = Form.useForm();
  const [formProcess, setFormProcess] = useState(false);

  function beforeUpload() {
    return false;
  }

  function submit() {
    setFormProcess(true);
    const data = form.getFieldsValue();

    let formData = new FormData();
    formData.append('file', data.file.shift().originFileObj);
    formData.append('date', data.fileDate);
    formData.append('establishmentId', params.id);

    apiPostEntity('import-combination-sheet', formData, response => {
      setFormProcess(false);

      if (!response.success)
        return message.error(response.message);

      setNeedUpdate(new Date());
    })
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <Form
      onFinish={submit}
      form={form}
      initialValues={{
        fileDate: moment(new Date(), 'MM/YYYY')
      }}
    >
      <Form.Item
        name="file"
        rules={[
          {required: true, message: 'Ce champs ne peut etre vide'}
        ]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          accept=".csv"
          beforeUpload={beforeUpload}
        >
          <Button type="primary">Importer CSV</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        label="Date du fichier"
        name="fileDate"
        rules={[
          {required: true, message: 'Ce champs ne peut etre vide'}
        ]}
      >
        <DatePicker picker="month"/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={formProcess}>Valider</Button>
      </Form.Item>
    </Form>
  )
}

import React from 'react';
import { Layout } from 'antd';
import Sidebar from './Sidebar.js';
import Header from "./Header";

const MyLayout = ({children, ...rest}) => {
  return (
    <Layout className="root-layout">
      {rest.sidebar ? <Sidebar /> : ''}
      <Layout>
        <Header/>
        {children}
      </Layout>
    </Layout>
  );
};

export default MyLayout;

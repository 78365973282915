import React, {useEffect, useState} from "react";
import {Tabs, BackTop} from 'antd';
import {useApiContext} from "../providers/ApiProvider";
import CombinationChoice from "../components/Combination/CombinationChoice";
import MyCombination from "../components/Combination/MyCombination";
import {useParams, Link} from "react-router-dom";
import {useAccountContext} from "../providers/AccountProvider";
import NewCombinationChoice from "../components/Combination/NewCombinationChoice";

const {TabPane} = Tabs;

export default function CombinationManager() {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource, apiFetchEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {accountSet} = accountDispatch;

  const [myCombis, setMyCombis] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [favorite, setFavorite] = useState([]);
  const [allFavorite, setAllFavorite] = useState([]);
  const [favNeedUpdate, setFavNeedUpdate] = useState(0);

  const [lng, setLng] = useState('fr')

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEtb();
  }, [])

  useEffect(() => {
    fetch();
  }, [needUpdate])

  useEffect(() => {
    setLoading(true)
    fetchFavorite();
  }, [favNeedUpdate, lng])

  useEffect(() => {
    fetchAllFavorite();
  }, [favNeedUpdate])

  function fetchFavorite() {

    const filters = lng ? [{name: 'language', value: lng}] : [];
    apiFetchSubResource("establishments", {
      id: params.id,
      filters: filters
    }, "favorite_combination_key_words", response => {
      setFavorite(response["hydra:member"]);
      setLoading(false);
    })
  }

  function fetchAllFavorite() {

    apiFetchSubResource("establishments", {id: params.id}, "favorite_combination_key_words", response => {
      setAllFavorite(response["hydra:member"]);
    })
  }

  function fetch() {
    apiFetchSubResource("establishments", {id: params.id}, "combination_categories", response => {
      setMyCombis(response["hydra:member"])
      setLoading(false)
    });
  }

  function fetchEtb() {
    apiFetchEntity("establishments", params.id, response => {
      accountSet(response)
    });
  }

  return (
    <div>
      <Tabs
        animated={false}
        tabBarExtraContent={<Link className="m-right-15" to={`/establishment-key-words/${params.id}`} type="link">Retour
          aux mots clés</Link>}
      >
        <TabPane key="1" tab="Mes combinaisons">
          <MyCombination
            myCombis={myCombis}
            rerender={() => setNeedUpdate(needUpdate + 1)}
            favorite={favorite}
            rerenderFav={() => setFavNeedUpdate(favNeedUpdate + 1)}
            loading={loading}
            setLoading={setLoading}
            lng={lng} setLng={setLng}
            allFavorite={allFavorite}
          />
        </TabPane>
        <TabPane key="2" tab="Generateur de combinaison">
          <NewCombinationChoice rerender={() => setNeedUpdate(needUpdate + 1)}/>
        </TabPane>
        <TabPane key="3" tab="Generateur de combinaison (old)">
          <CombinationChoice myCombis={myCombis} rerender={() => setNeedUpdate(needUpdate + 1)}/>
        </TabPane>
      </Tabs>
      <BackTop/>
    </div>
  )
}

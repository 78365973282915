import React, {useEffect, useState} from 'react'
import {useApiContext} from '../../providers/ApiProvider.js';
import ModalRight from "../../components/Modal/ModalRight";
import {Pagination, Spin} from "antd";
import EstablishmentCard from "./EstablishmentCard";

export default function EstablishmentsCard({filters}) {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [establishments, setEstablishments] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const [idEtb, setIdEtb] = useState(0)

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(30);

  useEffect(() => {
    fetch();
  }, [filters, page, itemsPerPage])

  function fetch() {
    setLoading(true)
    const params = {
      itemsPerPage: itemsPerPage,
      page: page,
      filters: filters
    }
    apiFetchCollection("establishments", params, response => {
      setEstablishments(response['hydra:member']);
      setTotal(response['hydra:totalItems']);
      setLoading(false)
    })
  }

  function modalRight(id) {
    setIdEtb(id)
    setVisibleRight(true)
  }

  function onItemsPerPageChange(page, itemPerPage) {
    setItemsPerPage(itemPerPage);
    setPage(1);
  }

  return (
    <Spin spinning={loading}>
      <div className="flex wrap">

        {establishments.map(establishment => (
          <EstablishmentCard
            establishment={establishment}
            modalRight={modalRight}
            key={establishment.id}
          />
        ))}
      </div>

      <div className="flex justify-center mt-20">
        <Pagination
          showSizeChanger={false}
          current={page}
          total={total}
          defaultPageSize={itemsPerPage}
          onChange={page => setPage(page)}
          onShowSizeChange={onItemsPerPageChange}
        />
      </div>

      <ModalRight
        visible={visibleRight}
        setVisible={setVisibleRight}
        idEtb={idEtb}
      />
    </Spin>
  )
}

import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";

const ModalAnalytics = ({visible, setVisible, item = {}, reload, analytics, setItem}) => {

  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;
  const params = useParams();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  function handleOk() {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true)
        if (item.id)
          updateAnalytics(values)
        else
          postAnalytics(values)
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  function updateAnalytics(values) {
    let _analytics = [...analytics];
    const index = _analytics.findIndex(a => a.id === item.id);
    _analytics[index] = {id: item.id, ...values}
    apiUpdateEntity('establishments', params.id, {analytics: _analytics}, response => {
      setConfirmLoading(false)
      reload()
      close()
    })
  }

  function postAnalytics(values) {
    values.id = new Date().getTime();
    apiUpdateEntity('establishments', params.id, {analytics: [...analytics, values]}, response => {
      setConfirmLoading(false)
      reload()
      close()
    })
  }

  function close() {
    setItem({})
    form.resetFields()
    setVisible(false)
  }

  useEffect(() => {
    if (item) {
      fetch()
    }
  }, [item])

  function fetch() {
    let obj = {}
    for (const [key, value] of Object.entries(item)) {
      switch (key) {
        case "name":
          obj[key] = value;
          break;
        case "email":
          obj[key] = value;
          break;
        case "code":
          obj[key] = value;
          break;
        default:
          break;
      }
    }

    form.setFieldsValue(obj)
  }

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}><PlusOutlined/>Ajouter</Button>

      {visible &&
        <Modal
          destroyOnClose
          title="Ajouter un champs analytics"
          visible={visible}
          onOk={handleOk}
          onCancel={close}
          okText="Enregistrer"
          cancelText="Annuler"
          confirmLoading={confirmLoading}
        >

          <Form
            form={form} layout="vertical"
          >

            <Form.Item
              label="Nom" name="name"
              rules={[{required: true, message: 'Champs requis !'}]}
            >
              <Input placeholder="Ex: GTM"/>
            </Form.Item>

            <Form.Item
              label="Email" name="email"
            >
              <Input placeholder="Ex: test@exemple.com"/>
            </Form.Item>

            <Form.Item
              label="Code" name="code"
              rules={[{required: true, message: 'Champs requis !'}]}
            >
              <Input placeholder="Ex: GTM-45454656"/>
            </Form.Item>

          </Form>

        </Modal>}

    </>
  );
};

export default ModalAnalytics;

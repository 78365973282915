import React from "react";
import {Input, message, Modal} from "antd";
import {copy} from "../../Helpers";

export default function ModalPagesKeyWords({visible, setVisible, value}) {

  function handleOk() {
    message.success("Copié dans presse-papier");
    copy(value);
    setVisible(false);
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleOk}
      title="Liste de vos mots clés"
      okText="Copier et fermer"
      cancelText="Annuler"
      destroyOnClose
    >
      <Input.TextArea
        value={value}
        rows={8}
      />
    </Modal>
  )
}

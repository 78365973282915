import React, {useState} from 'react';
import {EyeOutlined} from "@ant-design/icons";
import {Tooltip, Modal} from "antd";

const MyComponent = ({deleted, saveOld}) => {

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleOk() {
    setLoading(true);
    await saveOld();
    setLoading(false);
    setVisible(false);
  }

  return (
    <>
      <Tooltip title="Voir" onClick={() => setVisible(true)}><EyeOutlined/></Tooltip>

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="URL supprimés"
        width={800}
        onOk={handleOk}
        okText="Valider"
        cancelText="Retour"
        confirmLoading={loading}
      >
        {deleted.map((url, id) => (
          <div key={id}>{url}</div>
        ))}
      </Modal>
    </>
  );
};

export default MyComponent;

import React, { useEffect, useState } from "react";
import {
  BgColorsOutlined,
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
  CloseCircleTwoTone
} from '@ant-design/icons';
import { Button, Divider, Tooltip, Typography, Input, message, Spin } from "antd";
import { useApiContext } from "../providers/ApiProvider";

const { Paragraph } = Typography;
const colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#795548", "#607D8B"];

export default function TagDropDownLight({ task, content, reload }) {

  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiUpdateEntity, apiPostEntity, apiDeleteEntity } = apiDispatch;

  const [editTag, setEditTag] = useState(false);
  const [displayColor, setDisplayColor] = useState(false);
  const [tagInCache, setTagInCache] = useState({});

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);


  function handleVisible(visible) {
    if (!visible) return;
    fetchTags()
  }

  function fetchTags() {
    setLoading(true)

    apiFetchCollection("tags", {}, response => {
      setTags(response["hydra:member"]);
      setLoading(false)
    });
  }

  function toggleTag(iri) {
    let newValue = iri;
    if (task.status && iri === task.status["@id"])
      return;

    setLoading(true);
    apiUpdateEntity("tasks", task.id, { status: newValue }, () => {
      reload();
      setLoading(false);
    })
  }

  function onNameChange(value, tag) {
    if (!value || value.length < 2 || value === tag.name)
      return;

    apiUpdateEntity("tags", tag.id, { name: value }, () => {
      reloadAll();
    })
  }

  function whenViewColor(tag) {
    if (displayColor && tagInCache.id === tag.id) {
      setTagInCache({});
      setDisplayColor(false);
    } else {
      setTagInCache(tag);
      setDisplayColor(true);
    }
  }

  function onColorChange(color) {
    if (!color || tagInCache.color === color)
      return setDisplayColor(false);

    if (tagInCache.create) {
      tagInCache.color = color;
      setTagInCache({ ...tagInCache })
    } else {
      apiUpdateEntity("tags", tagInCache.id, { color: color }, response => {
        if (response["@type"] === "hydra:Error")
          return message.error("Ce tag existe déjà");

        reset();
      })
    }
  }

  function newTag() {
    if (!tagInCache.create) {
      setTagInCache({ create: true, name: "", color: colors[Math.floor(Math.random() * colors.length)] });
      setDisplayColor(true);
    }
  }

  function onTagChange(key, value) {
    tagInCache[key] = value;
    setTagInCache({ ...tagInCache });
  }

  function postTag() {
    if (!tagInCache.name || tagInCache.name.length < 2)
      return;

    setLoading(true)
    apiPostEntity("tags", tagInCache, response => {
      if (response["@type"] === "hydra:Error")
        return message.error("Ce tag existe déjà");

      reset();
    })
  }

  function removeTag(tag) {
    if (tag.favoriteCombinationKeyWords && tag.favoriteCombinationKeyWords.length)
      return message.error("Ce tag ne peut être supprimé");

    setLoading(true)
    apiDeleteEntity("tags", tag.id, () => {
      fetchTags();
    });
  }

  function reset() {
    reloadAll();
    resetLight();
  }

  function reloadAll() {
    reload();
    fetchTags();
  }

  function resetLight() {
    setTagInCache({});
    setDisplayColor(false);
  }


  const tagManager = (
    <div className="w-100per" onClick={e => e.stopPropagation()}>
      <Spin spinning={loading}>
        {!editTag ?
          <div>
            <div className="flex wrap">
              {tags.map(tag => (
                <div
                  key={tag.id}
                  className="my-tag cursor-p"
                  style={{ backgroundColor: tag.color }}
                  onClick={() => toggleTag(tag["@id"])}
                >
                  {tag.name}
                </div>
              ))}
            </div>
          </div>
          :
          <div className="w-100per">
            {tags.map(tag => (
              <div
                key={tag.id}
                className="flex my-tag tag-edit w-100per"
              >
                <div className="w-70per">
                  <Paragraph editable={{ onChange: (value) => onNameChange(value, tag) }}>
                    {tag.name}
                  </Paragraph>
                </div>
                <div
                  className="w-15per"
                  style={{ backgroundColor: tag.color }}
                >
                  <BgColorsOutlined onClick={() => whenViewColor(tag)} />
                </div>
                <div className="w-15per">
                  <CloseCircleTwoTone twoToneColor="#eb2f96" onClick={() => removeTag(tag)} />
                </div>
              </div>
            ))}
            <div>
              <Button icon={<PlusOutlined />} onClick={newTag}>Créer tag</Button>
              {tagInCache.create ?
                <div className="flex">
                  <Input
                    placeholder="Nom de votre tag"
                    size="small"
                    style={{ width: 150 }}
                    value={tagInCache.name}
                    onChange={(e) => onTagChange("name", e.target.value)}
                  />
                  <Button type="primary" onClick={postTag} icon={<CheckOutlined />} />
                  <Button type="danger" icon={<CloseOutlined />} onClick={resetLight} />
                </div>
                : null}
            </div>
            {displayColor ?
              <div className="flex wrap">
                {colors.map(color => (
                  <div
                    key={color}
                    className="select-color cursor-p"
                    style={{ backgroundColor: color, border: tagInCache.color === color ? "1px solid blue" : null }}
                    onClick={() => onColorChange(color)}
                  />
                ))}
              </div> : null}
          </div>}
        <Divider style={{ margin: "20px 0 10px 0" }} />
        <div>
          <Button
            type="link"
            onClick={() => setEditTag(!editTag)}
          >
            {!editTag ? "Ajouter / modifier les tags" : "Choisir un tag"}
          </Button>
        </div>
      </Spin>
    </div>
  )

  return (
    <Tooltip overlayStyle={{ width: 300 }} color="#fff" title={tagManager} placement="bottom" trigger="click" onVisibleChange={handleVisible}>
      {content}
    </Tooltip>
  )
}

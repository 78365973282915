import React, {useState} from "react";
import {Button, Drawer, DatePicker, Space, Switch} from "antd";
import {PauseOutlined, CheckOutlined} from "@ant-design/icons";
import moment from "moment";
import {useApiContext} from "../../providers/ApiProvider";
import {stringDateMY} from "../../Helpers";

const {RangePicker} = DatePicker;

export default function DrawerPause({etb, reload}) {

  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity} = apiDispatch;

  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState([null, null]);
  const monthFormat = 'YYYY/MM';

  function onChange(dates, stringDates) {
    setDates([
      moment(stringDates[0], monthFormat),
      moment(stringDates[1], monthFormat)
    ])
  }

  function addPause() {
    const data = {
      beginAt: dates[0]['_i'],
      finishAt: dates[1]['_i'],
      establishment: etb['@id']
    }

    apiPostEntity("pauses", data, () => reload());
  }

  function toggleActive(checked, id) {
    apiUpdateEntity('pauses', id, {isActive: checked}, () => reload());
  }

  function stop() {
    const data = {
      isStopped: true,
      stoppedAt: new Date()
    }

    apiUpdateEntity('establishments', etb.id, data, response => {
      //console.log(response)
      reload();
    })
  }

  function restart() {
    const dataUpdate = {
      isStopped: false,
      stoppedAt: null
    }

    const dataPost = {
      beginAt: etb.stoppedAt,
      finishAt: new Date(),
      establishment: etb['@id']
    }

    apiUpdateEntity('establishments', etb.id, dataUpdate, () => {
      reload();
    })

    apiPostEntity("stops", dataPost, () => {
    });
  }

  return (
    <div>
      <Button
        type="primary"
        icon={<PauseOutlined/>}
        onClick={() => setVisible(true)}
      >
        Mettre etablissement en pause
      </Button>

      <Drawer
        title="Pause"
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={visible}
        width={500}
      >
        <div className="flex column space-between h-100per">
          <div>
            <Space size="large" direction="vertical">
              <Space direction="vertical">
                {etb.pauses.map(pause => (
                  <Space key={pause.id}>
                    <div>En pause de {stringDateMY(pause.beginAt)} à {stringDateMY(pause.finishAt)}</div>
                    <div>
                      <Switch
                        size="small"
                        defaultChecked={pause.isActive}
                        onClick={(checked) => toggleActive(checked, pause.id)}
                      />
                    </div>
                  </Space>
                ))}
              </Space>

              <div>
                <p><strong>Mettre en pause du :</strong></p>
                <Space>
                  <RangePicker value={dates} picker="month" onChange={onChange}/>
                  <Button type="primary" icon={<CheckOutlined/>} onClick={addPause}/>
                </Space>
              </div>
            </Space>
          </div>

          <div>
            <Space size="large" direction="vertical">
              <Space direction="vertical">
                {etb.stops.map(stop => (
                  <div key={stop.id}>Contrat arreté
                    de {stringDateMY(stop.beginAt)} à {stringDateMY(stop.finishAt)}</div>
                ))}
              </Space>

              <div>
                {etb.isStopped ? <Button type="primary" onClick={restart}>Reprendre Le contrat</Button> :
                  <Button type="danger" onClick={stop}>Arrêter le contrat</Button>}
              </div>
            </Space>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

import React, {useEffect, useState} from "react"
import {useApiContext} from '../providers/ApiProvider.js';
import {useParams} from "react-router-dom";
import StepOne from "../components/Redirection/StepOne";
import StepTwo from "../components/Redirection/StepTwo";
import StepThree from "../components/Redirection/StepThree";
import {Button, message, Modal, Steps} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useAccountContext} from "../providers/AccountProvider";

const {Step} = Steps;
const {confirm} = Modal;

export default function Redirection() {

  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource, apiPostEntity, apiUpdateEntity, apiFetchEntity, apiDeleteEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {accountSet} = accountDispatch;
  const params = useParams();

  const [etb, setEtb] = useState({})
  const [loading, setLoading] = useState(true)
  const [redirId, setRedirId] = useState(0)
  const [current, setCurrent] = useState(0)
  const [valueOld, setValueOld] = useState("")
  const [valueNew, setValueNew] = useState("")
  const [oldSort, setOldSort] = useState([])
  const [newSort, setNewSort] = useState([])
  const [result, setResult] = useState([])
  const [finalResult, setFinalResult] = useState("")
  const [languages, setLanguages] = useState([])
  const [oldLanguages, setOldLanguages] = useState([])
  const [devDomain, setDevDomain] = useState([])

  useEffect(() => {
    fetch()
    fetchURL()
  }, [])

  function resetState() {
    setRedirId(0)
    setCurrent(0)
    setValueOld("")
    setValueNew("")
    setOldSort([])
    setNewSort([])
    setResult([])
    setFinalResult("")
    setLanguages([])
    setOldLanguages([])
  }

  function fetchURL() {
    apiFetchEntity("establishments", params.id, response => {
      accountSet(response)
      setEtb(response)
    })
  }

  function fetch() {
    apiFetchSubResource("establishments", {id: params.id}, "redirection", response => {
      if (response['@type'] === 'hydra:Error') {
        message.info({
          content: "Pas de redirection dans la base de données, veuillez les importer",
          style: {
            marginTop: "5vh"
          }
        })

        setLoading(false)
        return
      }

      response.newSort = response.newSort?.split("\n").map(v => v.trim()).join("\n");
      response.newValue = response.newValue?.split("\n").map(v => v.trim()).join("\n");
      response.oldSort = response.oldSort?.split("\n").map(v => v.trim()).join("\n");
      response.oldValue = response.oldValue?.split("\n").map(v => v.trim()).join("\n");
      response.result = response.result?.split("\n").map(v => v.trim()).join("\n");

      setRedirId(response.id)
      setDevDomain(response.devDomain)

      if (response.oldValue) {
        setOldLanguages(getLanguages(response.oldValue))
        setValueOld(response.oldValue)
      }
      if (response.newValue) {
        setLanguages(getLanguages(response.newValue))
        setValueNew(response.newValue)
      }
      if (response.oldSort) {
        setOldSort(response.oldSort.split("\n"));
      }
      if (response.newSort) {
        setNewSort(response.newSort.split("\n"));
      }
      if (response.result) {
        setFinalResult(response.result);
      }

      setLoading(false)
    })
  }

  function getLanguages(values) {
    let arr = []
    values.split('\n').map(v => {
      let lng = v.split("//")[1] ? v.split("//")[1].split('/')?.[1] : undefined
      if (lng?.length === 2 && !arr.includes(lng)) {
        arr.push(lng)
      }
    })
    return arr
  }


  function saveUpdate(vo, os, ns) {
    const data = {
      valueOld: vo,
      oldSort: os.join('\n'),
      newSort: ns.join('\n')
    }
    apiUpdateEntity("redirections", redirId, data, response => {
    })
  }

  const steps = [
    {
      title: 'Telechargement des URL',
      content: (<StepOne
        setCurrent={setCurrent}
        valueOld={valueOld}
        setValueOld={setValueOld}
        valueNew={valueNew}
        setValueNew={setValueNew}
        setLanguages={setLanguages}
        setOldLanguages={setOldLanguages}
        goStepTwo={goStepTwo}
        url={etb.url}
        oldLanguages={oldLanguages}
        redirId={redirId}
        setRedirId={setRedirId}
        devDomain={devDomain}
        setDevDomain={setDevDomain}
      />),
    },
    {
      title: 'Trie',
      content: (<StepTwo
        setCurrent={setCurrent}
        valueOld={valueOld}
        setValueOld={setValueOld}
        valueNew={valueNew}
        setValueNew={setValueNew}
        result={result}
        setResult={setResult}
        oldSort={oldSort}
        setOldSort={setOldSort}
        newSort={newSort}
        setNewSort={setNewSort}
        languages={languages}
        oldLanguages={oldLanguages}
        setOldLanguages={setOldLanguages}
        redirId={redirId}
        goStep={goStep}
        apiPostEntity={apiPostEntity}
        saveUpdate={saveUpdate}
        devDomain={devDomain}
      />),
      disabled: !valueOld || !valueNew,
    },
    {
      title: 'Resultat',
      content: (<StepThree
        setCurrent={setCurrent}
        result={result}
        finalResult={finalResult}
        setFinalResult={setFinalResult}
        redirId={redirId}
        apiUpdateEntity={apiUpdateEntity}
      />),
      disabled: !valueOld || !valueNew || (result.length === 0 && !finalResult),
    },
  ];

  function goStepTwo() {
    let VO = true
    let VN = true

    let _valueOld = valueOld.split('\n').filter(v => v).join('\n');
    setValueOld(_valueOld)
    _valueOld.split("\n").map(o => {
      if (o && !(/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/).test(o)) {
        VO = false;
      }
    })

    valueNew.split("\n").map(n => {
      if (!(/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/).test(n)) {
        VN = false;
      }
    })

    if (!VO) {
      message.error("Veuillez rentrer uniquement des url (ex : http://test.fr). Verifier tableau anciens url")
    }
    if (!VN) {
      message.error("Veuillez rentrer uniquement des url (ex : http://test.fr). Verifier tableau nouveaux url")
    }

    if (VN && VO) {
      if (!redirId) {
        const data = {
          oldValue: _valueOld,
          newValue: valueNew,
          establishment: "/api/establishments/" + params.id
        }
        apiPostEntity("redirections", data, response => {
          setRedirId(response.id)
        })
      }

      if (!oldLanguages.length)
        setOldLanguages(getLanguages(_valueOld))

      if (redirId) {
        const data = {
          oldValue: _valueOld,
          newValue: valueNew
        }
        apiUpdateEntity("redirections", redirId, data, response => {
        })
      }

      setCurrent(1)
    }
  }

  function goStep(number) {
    const data = {
      valueOld: valueOld,
      valueNew: valueNew,
      oldSort: oldSort.join('\n'),
      newSort: newSort.join('\n')
    }
    apiUpdateEntity("redirections", redirId, data, response => {
    })

    setCurrent(number)
  }

  function onChange(current) {
    switch (current) {
      case 0:
        goStep(0);
        break;
      case 1:
        goStepTwo();
        break;
      case 2:
        goStep(2);
        break;
    }
  }

  function showDeleteConfirm() {
    confirm({
      title: 'Etes vous sur de vouloir reset ces redirections ?',
      icon: <ExclamationCircleOutlined/>,
      content: 'Vous perderez toutes les données de redirection liées a ce compte.',
      okText: 'Confirmer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        reset();
        //console.log('OK');
      },
      onCancel() {
      },
    });
  }

  function reset() {
    setLoading(true)
    apiDeleteEntity("redirections", redirId, response => {
      //console.log(response)
      resetState()
      fetch()
    })
  }

  return (
    <div>
      <div className="contenu-tab" style={{overflow: "auto"}}>
        {redirId && !current ?
          <Button type="link" style={{float: "right", color: "red"}} onClick={showDeleteConfirm}>Reset</Button>
          : null}
        <Steps className="margin-10" current={current} onChange={onChange}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} disabled={item.disabled}/>
          ))}
        </Steps>
        {loading ? null : steps[current].content}
      </div>
    </div>
  )
}

import React, {useEffect, useState} from "react";
import {message, Modal, Tooltip, Spin, Input, Tag, Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalAddCombination({reload, language = 'fr'}) {

  const params = useParams();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource, apiPostEntity, apiDeleteEntity} = apiDispatch;

  const [myCombis, setMyCombis] = useState([])
  const [favorite, setFavorite] = useState([])
  const [loading, setLoading] = useState(false)

  const [visible, setVisible] = useState(false)
  const [needUpdate, setNeedUpdate] = useState(0)

  const [value, setValue] = useState("");
  const [manuallies, setManuallies] = useState([])

  useEffect(() => {
    if (visible) {
      setLoading(true)
      fetch();
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      fetchFavorite();
    }
  }, [visible, needUpdate])

  useEffect(() => {
    setManuallies([]);
  }, [language])

  function fetchFavorite() {
    const data = {id: params.id}
    apiFetchSubResource("establishments", data, "favorite_combination_key_words", response => {
      setFavorite(response["hydra:member"])
      setLoading(false)
    })
  }

  function fetch() {
    const data = {id: params.id}
    apiFetchSubResource("establishments", data, "combination_categories", response => {
      setMyCombis(response["hydra:member"])
    })
  }

  function close() {
    reload()
    setVisible(false)
  }

  function getNoteColor(note) {
    if (!note)
      return;

    if (note > 66)
      return {color: "green"};

    if (note > 33)
      return {color: "orange"};

    return {color: "red"};
  }

  function toggleFav(iri) {
    if (favorite.some(f => f.combination["@id"] === iri)) {
      const fav = favorite.filter(f => f.combination["@id"] === iri).shift()

      if (fav.page)
        return message.warning("Cette combinaison est liées a une page et ne peut etre supprimé");

      if (fav.status === "nextToUse")
        return message.warning("Cette combinaison est dans la liste des prochaines a utliser et ne peut etre supprimé");

      if (fav.status === "notUse")
        return message.warning("Cette combinaison est dans la liste ne pas utliser et ne peut etre supprimé");

      setLoading(true)
      apiDeleteEntity("favorite_combination_key_words", fav.id, response => {
        setNeedUpdate(needUpdate + 1)
      })
    } else {
      const data = {
        establishment: "/api/establishments/" + params.id,
        combination: iri,
        language: language
      }

      setLoading(true)
      apiPostEntity("favorite_combination_key_words", data, response => {
        setNeedUpdate(needUpdate + 1)
      })
    }
  }

  function addManually() {
    if (!value || value.length < 5)
      return message.warning("Combinaison trop courte");

    const data = {
      account: params.id,
      combination: value,
      language: language
    }

    apiPostEntity("add-many-favorites", data, response => {
      if (!response.success)
        return message.error(response.message);

      setManuallies(response.favorites);
      setValue("");
    })
  }

  function removeManually(id) {
    //console.log(id)
    apiDeleteEntity("favorite_combination_key_words", id, response => setManuallies(manuallies.filter(manually => manually.id !== id)))
  }

  return (
    <div>
      <div>
        <Tooltip title="Ajouter combinaison">
          <PlusOutlined onClick={() => setVisible(true)}/>
        </Tooltip>
      </div>
      <Modal
        title="Ajouter combinaison"
        visible={visible}
        onOk={close}
        onCancel={close}
        width={700}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <Input.TextArea
            value={value}
            placeholder="Hotel 5 etoiles Paris, Hotel Paris centre, Hotel de luxe Paris"
            onChange={e => setValue(e.target.value)}
            style={{width: 400}}
            rows={3}
          />
          <Button type={"primary"} onClick={addManually}>Ajouter</Button>
          <div className="padding-10">
            {manuallies.map(manually => (
              <Tag
                key={manually.id}
                closable
                onClose={() => removeManually(manually.id)}
              >
                {manually.combination}
              </Tag>
            ))}
          </div>
          {myCombis.map((cat, id) => (
            <div key={id}>
              <div className="flex space-between padding-topbot-10">
                <div className="w-40per"><strong>Combinaison</strong></div>
                <div className="w-60per flex space-between">
                  <div className="w-15per flex justify-center"><strong>Note</strong></div>
                  <div className="w-25per flex justify-center"><strong>Google search</strong></div>
                  <div className="w-25per flex justify-center"><strong>Concurrence</strong></div>
                  <div className="w-25per flex justify-center"><strong>CPC Adword</strong></div>
                </div>
              </div>
              {cat.combinationKeyWords.sort((a, b) => b.note - a.note || a.result.localeCompare(b.result)).map((comb, id) => (
                <div key={id} className="flex space-between">
                  <div
                    className="cursor-p url-redir w-40per"
                    style={favorite.some(f => f.combination["@id"] === comb["@id"]) ? {textDecoration: "line-through"} : null}
                    onClick={() => toggleFav(comb["@id"])}
                  >
                    {comb.result}&nbsp;
                  </div>
                  <div className="w-60per flex space-between">
                    <div className="w-15per flex justify-center" style={getNoteColor(comb.note)}>
                      {comb.note || "-"}
                    </div>
                    <div className="w-25per flex justify-center">{comb.googleSearch ? comb.googleSearch : "-"}</div>
                    <div className="w-25per flex justify-center">{comb.concurence ? comb.concurence : "-"}</div>
                    <div className="w-25per flex justify-center">{comb.cpcAdwords ? comb.cpcAdwords : "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Spin>
      </Modal>
    </div>
  )
}

import React, { useState } from 'react'
import { Modal, Input, Form, DatePicker, Select } from "antd"
import moment from "moment";
import { billingInfoList } from '../../Helpers';

const { TextArea } = Input;

export default function ModalEditField({ visible, setVisible, fieldType, fieldValue, fieldKey, updateForModal }) {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)

  async function onFinish() {
    setLoading(true)
    await updateForModal(form.getFieldsValue(), () => setLoading(false))
  }

  return (
    <Modal
      title="Editer"
      visible={visible}
      onOk={() => form.submit()}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        name="etb"
        initialValues={{
          [fieldKey]: fieldType === "date" && fieldValue ? moment(fieldValue) : fieldValue
        }}
        onFinish={onFinish}
      >
        {
          fieldType === "input" ?
            <Form.Item name={fieldKey}>
              <Input />
            </Form.Item>
            : null
        }
        {
          fieldType === "textarea" ?
            <Form.Item name={fieldKey}>
              <TextArea autoSize={{ minRows: 5, maxRows: 20 }} />
            </Form.Item>
            : null
        }
        {
          fieldType === "date" ?
            <Form.Item name={fieldKey}>
              <DatePicker autoSize={{ minRows: 5, maxRows: 20 }} />
            </Form.Item>
            : null
        }
        {
          fieldType === "billingInfos" ?
            <Form.Item name={fieldKey}>
              <Select style={{ minWidth: '300px' }} placeholder='Facturation'>
                {
                  billingInfoList.map(item => <Select.Option key={item} value={item}>{item}</Select.Option>)
                }
              </Select>
            </Form.Item>
            : null
        }
      </Form>
    </Modal>
  )
}

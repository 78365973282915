import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

const {RangePicker} = DatePicker;

const TaskFilters = ({search, needResetFields, filters}) => {

  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [descriptionSearchTimeout, setDescriptionSearchTimeout] = useState(null)
  const [form] = Form.useForm();

  const [tagsFilter, setTagsFilter] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    setLoading(true)
    const data = {
      filters: [...filters, {name: 'pagination', value: false}],
    }

    apiFetchCollection("tasks/tags/filters", data, response => {
      //console.log('response', response)
      // hydrate(response['hydra:member'])
      setTagsFilter(response['hydra:member'].map(item => item.name))
      setLoading(false)
    })
  }

  function searchByDescription(q) {
    clearTimeout(descriptionSearchTimeout);
    setDescriptionSearchTimeout(setTimeout(async () => {
      search("descr", "description", q.target.value)
    }, 500));
  }

  const [etbSearchTimeout, setEtbSearchTimeout] = useState(null)
  const [etbClusterSearchTimeout, setEtbClusterSearchTimeout] = useState(null)

  function searchByEtb(q) {
    clearTimeout(etbSearchTimeout);
    setEtbSearchTimeout(setTimeout(async () => {
      search("etb", "establishment.name", q.target.value)
    }, 500));
  }

  function searchByEtbCluster(q) {
    clearTimeout(etbClusterSearchTimeout);
    setEtbClusterSearchTimeout(setTimeout(async () => {
      search("etb", "establishment.cluster", q.target.value)
    }, 500));
  }

  useEffect(() => {
    form.resetFields()
    fetch()
  }, [needResetFields])

  return (
    <Form layout="inline" form={form} name="filter_form">
      <Form.Item name="description">
        <Input
          style={{width: 200}}
          placeholder="filtrer par description"
          onChange={searchByDescription}
          allowClear
        />
      </Form.Item>

      <Form.Item name="etb">
        <Input
          allowClear
          style={{width: 200}}
          onChange={searchByEtb}
          placeholder="Filtrer par établissement"
        />
      </Form.Item>
      <Form.Item name="etbCluster">
        <Input
          allowClear
          style={{width: 200}}
          onChange={searchByEtbCluster}
          placeholder="Filtrer par groupe d’établissement"
        />
      </Form.Item>

      <Form.Item name="tag">
        <Select
          showSearch
          loading={loading}
          allowClear
          style={{width: 200}}
          onChange={(value) => search("status", "statusOrChildStatus", value)}
          placeholder="Filtrer par tag"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {tagsFilter.map((tag, id) => (
            <Select.Option key={id} value={tag}>{tag}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="dates">
        <RangePicker
          style={{width: 250}}
          placeholder={["debut", "fin"]}
          onChange={(dates, dateStrings) => search("date", `plannedAt[after]=${dateStrings[0]}&plannedAt[before]`, dateStrings[1])}
        />
      </Form.Item>

    </Form>
  );
};

export default TaskFilters;

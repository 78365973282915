import React, {useEffect, useState} from 'react'
import {List, Empty, Modal, Spin, Input, message} from "antd"
import {useApiContext} from "../../providers/ApiProvider";
import {DeleteOutlined} from '@ant-design/icons';

export default function ModalRight({visible, setVisible, idEtb}) {

  const [apiDispatch] = useApiContext();
  const {apiFetchSubResource, apiPostEntity, apiDeleteEntity} = apiDispatch;

  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [needUpdate, setNeedUpdate] = useState(0)

  const [email, setEmail] = useState("")

  useEffect(() => {
    if (visible)
      fetch()
  }, [idEtb, needUpdate])

  function fetch() {
    setLoading(true)
    apiFetchSubResource("establishments", {id: idEtb}, "permissions", response => {
      setPermissions(response["hydra:member"]);
      setLoading(false);
    });
  }

  function onOk() {
    close()
  }

  function close() {
    setVisible(false)
  }

  function addPerm() {

    if (!validateEmail(email))
      return message.error("email non valide");

    const data = {email: email, idEtb: idEtb};
    apiPostEntity("new-perm-proccess", data, response => {
      setEmail("");
      setNeedUpdate(needUpdate + 1);
    })

  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function removePerm(id) {
    apiDeleteEntity("permissions", id, () => setNeedUpdate(needUpdate + 1));
  }

  return (
    <Modal
      visible={visible}
      title="Gerer les permissions"
      onCancel={close}
      onOk={onOk}
      destroyOnClose
    >
      <Input.Search
        placeholder="Ajouter une perm par email"
        enterButton="Ajouter"
        onSearch={addPerm}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Spin spinning={loading}>
        {permissions.length ?
          <List
            itemLayout="horizontal"
            dataSource={permissions}
            renderItem={perm => (
              <List.Item>
                <List.Item.Meta
                  avatar={null}
                  title={perm.user.username}
                  description={perm.user.email}
                />
                <div><DeleteOutlined onClick={() => removePerm(perm.id)}/></div>
              </List.Item>
            )}
          /> : <div className="empty"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>}
      </Spin>
    </Modal>
  )
}

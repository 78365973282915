import React, {useState} from 'react';
import {Checkbox, Divider} from "antd";

const CheckboxCombination = ({combinations, updateValidateData}) => {

  const defaultChecked = combinations.map(keyWords => keyWords.join(' '))
  const [checkedList, setCheckedList] = useState(defaultChecked);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const onChange = list => {
    setCheckedList(list);
    updateValidateData(list);
    setIndeterminate(!!list.length && list.length < defaultChecked.length);
    setCheckAll(list.length === defaultChecked.length);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? defaultChecked : []);
    updateValidateData(e.target.checked ? defaultChecked : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <Divider/>
      <Checkbox.Group
        value={checkedList} onChange={onChange}
      >
        {combinations.map((keyWords, index) => {
          const combination = keyWords.join(" ");
          return (
            <div key={index}>
              <Checkbox value={combination}>{combination}</Checkbox>
            </div>
          )
        })}
      </Checkbox.Group>
    </div>
  );
};

export default CheckboxCombination;

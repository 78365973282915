import React from 'react'
import {Modal, Table} from "antd"
import moment from "moment";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

export default function ModalHistoryCombination({visible, setVisible, history, setHistory}) {

  function handleClose() {
    setHistory({});
    setVisible(false);
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: date => (
        <span>
          {moment(date).format('MM-YYYY')}
        </span>
      )
    },
    {
      title: 'Trafic SEO',
      dataIndex: 'traficSeo',
      render: (traficSeo, record) => (
        <span>
          {traficSeo}&nbsp;
          <span style={{color: record.traficSeoDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.traficSeoDiff > 0 && <ArrowUpOutlined/>}
            {record.traficSeoDiff < 0 && <ArrowDownOutlined/>}
            {record.traficSeoDiff || null}
          </span>
        </span>
      )
    },
    {
      title: 'Position',
      dataIndex: 'position',
      render: (position, record) => (
        <span>
          {position}&nbsp;
          <span style={{color: record.positionDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.positionDiff > 0 && <ArrowUpOutlined/>}
            {record.positionDiff < 0 && <ArrowDownOutlined/>}
            {record.positionDiff || null}
          </span>
        </span>
      )
    },
    {
      title: 'Recherches Google',
      dataIndex: 'recherchesGoogle',
      render: (recherchesGoogle, record) => (
        <span>
          {recherchesGoogle}&nbsp;
          <span style={{color: record.recherchesGoogleDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.recherchesGoogleDiff > 0 && <ArrowUpOutlined/>}
            {record.recherchesGoogleDiff < 0 && <ArrowDownOutlined/>}
            {record.recherchesGoogleDiff || null}
          </span>
        </span>
      )
    },
    {
      title: 'Concurrence',
      dataIndex: 'concurrence',
      render: (concurrence, record) => (
        <span>
          {concurrence}&nbsp;
          <span style={{color: record.concurrenceDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.concurrenceDiff > 0 && <ArrowUpOutlined/>}
            {record.concurrenceDiff < 0 && <ArrowDownOutlined/>}
            {record.concurrenceDiff !== '0.00' ? record.concurrenceDiff : null}
          </span>
        </span>
      )
    },
    {
      title: 'CPC Adwords',
      dataIndex: 'cpcAdwords',
      render: (cpcAdwords, record) => (
        <span>
          {cpcAdwords}&nbsp;
          <span style={{color: record.cpcAdwordsDiff > 0 ? '#3f8600' : '#cf1322', fontSize: '0.8em'}}>
            {record.cpcAdwordsDiff > 0 && <ArrowUpOutlined/>}
            {record.cpcAdwordsDiff < 0 && <ArrowDownOutlined/>}
            {record.cpcAdwordsDiff !== '0.00' ? record.cpcAdwordsDiff : null}
          </span>
        </span>
      )
    }
  ];

  return (
    <Modal
      title={`Historique - ${history.expressionDeRecherche}`}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      destroyOnClose={true}
      width={800}
      footer={null}
    >
      <Table
        columns={columns} dataSource={history.history}
        rowKey={record => record.date} pagination={{defaultPageSize: 50}}
      />
    </Modal>
  )
}

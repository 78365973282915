import React, {useState} from "react";
import {Button, Drawer, Modal, Space} from "antd";
import {ExclamationCircleOutlined, InfoOutlined} from '@ant-design/icons';
import DivTinyEdit from "../DivTinyEdit";
import {useApiContext} from "../../providers/ApiProvider";
import {useHistory, useParams} from "react-router-dom";
import InfosEtb from "../Establishment/InfosEtb";
import {useAuthContext} from "../../providers/AuthProvider";

const {confirm} = Modal;

export default function DrawerDescription({etb, setEtb, reload}) {

  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity, apiDeleteEntity} = apiDispatch;
  const params = useParams();
  const [authState] = useAuthContext();
  const {auth} = authState;
  const isAdmin = auth.roles.includes("ROLE_ADMIN");
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  function setContent(value) {
    let _etb = {...etb}
    _etb.description = value;
    setEtb(_etb);
  }

  function update() {
    apiUpdateEntity("establishments", params.id, {description: etb.description}, () => {
    });
  }

  function showDeleteConfirm() {
    confirm({
      title: 'Êtes-vous sûre de vouloir supprimer ce copmpte ?',
      icon: <ExclamationCircleOutlined/>,
      content: 'Les données liées a ce compte seront perdu défénitivement.',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Retour',
      onOk() {
        setLoading(true);
        apiDeleteEntity('establishments', params.id, response => {
          setLoading(false);
          history.push(`/establishments`);
        })
      },
      onCancel() {
        //console.log('Retour');
      },
    });
  }

  function goToEdit() {
    history.push(`/edit-establishment/${etb.id}`)
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        icon={<InfoOutlined/>}
      >
        Information sur l'etablissement
      </Button>

      <Drawer
        title="Information sur l'etablissement"
        visible={visible}
        onClose={() => setVisible(false)}
        width={800}
      >
        <div className="flex column space-between h-100per">
          <Space direction="vertical" size="large">
            <div>
              <h3 style={{fontWeight: "bold"}}>Déscription</h3>
              <DivTinyEdit
                content={etb.description}
                setContent={setContent}
                update={update}
                cancel={reload}
                canCopy
              />
            </div>

            <InfosEtb etb={etb} reload={reload}/>
          </Space>

          <Space direction="vertical">
            {isAdmin && <Button className="w-100per" onClick={goToEdit} type="primary">Editer l'etablissement</Button>}
            {isAdmin &&
              <Button loading={loading} type="danger" className="w-100per" onClick={showDeleteConfirm}>Supprimer ce compte</Button>}
          </Space>
        </div>
      </Drawer>
    </>
  )
}

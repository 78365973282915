import React from "react";
import {Drawer} from "antd";
import DivTinyEdit from "../DivTinyEdit";
import {useApiContext} from "../../providers/ApiProvider";

export default function TaskDrawer({visible, setVisible, content, setContent, reload}) {

  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;

  function update() {
    apiUpdateEntity("tasks", content.id, {note: content.note}, () => {
      reload();
      content.initialValue = content.note;
      setContent({...content});
    });
  }

  function cancel() {
    content.note = content.initialValue;
    setContent({...content});
  }

  function  mySetContent(value) {
    content.note = value;
    setContent({...content})
  }

  return (
    <Drawer
      title="Note"
      placement="right"
      onClose={() => setVisible(false)}
      visible={visible}
      width={800}
    >
      <DivTinyEdit
        update={update}
        cancel={cancel}
        content={content.note}
        setContent={mySetContent}
      />
    </Drawer>
  )
}

import React, {useState} from "react"
import {Button, Input, message, Space, Upload} from "antd"
import {UploadOutlined} from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import {copy} from "../../Helpers";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import ModalOldRemove from "./ModalOldRemove";
import {useAccountContext} from "../../providers/AccountProvider";

export default function StepOne({
                                  valueOld,
                                  setValueOld,
                                  valueNew,
                                  setValueNew,
                                  setLanguages,
                                  oldLanguages,
                                  setOldLanguages,
                                  goStepTwo,
                                  url,
                                  redirId,
                                  setRedirId,
                                  setDevDomain
                                }) {

  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity} = apiDispatch;
  const params = useParams();
  const [accountState] = useAccountContext();
  const {account} = accountState;

  const [domain, setDomain] = useState("");
  const [loadingSaveOld, setLoadingSaveOld] = useState(false);
  const [filterOld, setFilterOld] = useState("");

  function onOldChange(e) {
    setValueOld(e.target.value)
  }

  function onNewChange(e) {
    setValueNew(e.target.value)
  }

  function replaceDomain() {
    if (domain.length < 3) {
      return message.error("domaine non valide")
    }

    let arr = valueNew.split("\n")

    arr = arr.map(a => {
      let oldDomain = a.split("//")[1].split("/")[0]
      return a.replace(oldDomain, domain)
    })

    setValueNew(arr.join("\n"))
  }

  function replaceDomainLight(olds, newDomain) {
    return olds.map(a => {
      let oldDomain = a.split("//")[1].split("/")[0]
      return a.replace(oldDomain, newDomain)
    })
  }

  function beforeUploadOld(file) {
    const reader = new FileReader();

    reader.onload = e => {
      let values = e.target.result.match(/<loc>(.*)<\/loc>/gm).map(val => val.replace(/<\/?loc>/g, "")).sort((a, b) => a.localeCompare(b) || b.length - a.length)
      let arr = []
      values.map(v => {
        let lng = v.split("//")[1].split('/')[1]
        if (lng.length === 2 && !arr.includes(lng)) {
          arr.push(lng)
        }
      })

      values = values.map(value => value.slice(-1) === '/' ? value.slice(0, -1) : value)

      arr = arr.concat(oldLanguages)
      setOldLanguages([...new Set(arr)])
      values = values.concat(valueOld.split('\n')).filter(item => item)
      setValueOld([...new Set(values)].join('\n'))
    };
    reader.readAsText(file);

    return false;
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  function beforeUploadNew(file) {
    xlsxParser
      .onFileSelection(file)
      .then(data => {
        let parsedData = data.Worksheet;
        let response = [];
        let arr = []

        for (let i = 0; i < parsedData.length; i++) {
          for (const [key, value] of Object.entries(parsedData[i])) {
            if ((/^Link/).test(key)) {
              if (!value.includes('/_files/') && value.includes('http'))
                response.push(value)

              let lng = value.split("//")[1] ? value.split("//")[1].split('/')?.[1] : undefined
              if (lng?.length === 2 && !arr.includes(lng)) {
                arr.push(lng)
              }
            }
          }
        }

        response = adjustNewData(response, arr);

        setLanguages(arr)
        const _devDomain = extractHostname(response[0]);
        saveDevDomain(_devDomain)

        let oldDomain = null;
        if (valueOld) {
          oldDomain = extractHostname(valueOld.split('\n').shift())
        } else if (account.url) {
          oldDomain = extractHostname(account.url)
        }

        response = replaceDomainLight(response, oldDomain);

        setValueNew(response.sort((a, b) => a.localeCompare(b) || b.length - a.length).join("\n"))
      });

    return false;
  }

  function saveDevDomain(devDomain) {
    let data = {devDomain: devDomain};
    setDevDomain(devDomain)
    if (!redirId) {
      data.establishment = "/api/establishments/" + params.id;
      apiPostEntity("redirections", data, response => {
        setRedirId(response.id)
      })
    } else {
      apiUpdateEntity("redirections", redirId, data, response => {
      })
    }
  }

  function extractHostname(url) {
    const a = document.createElement('a');
    a.href = url;
    return a.hostname;
  }

  function adjustNewData(data, arrLng) {
    let dataAdjusting = data.filter(r => !r.includes('api.hapidam.com'));

    let domain = dataAdjusting[getRandomInt(0, dataAdjusting.length)]
    let a = document.createElement('a');
    a.href = domain;

    arrLng.map(lng => {
      dataAdjusting.push(`https://${a.hostname}/${lng}/`);
    })

    return [...new Set(dataAdjusting)];
  }

  function gotoSitemap() {
    copy(url)
    window.open("https://www.xml-sitemaps.com/", "_blank")
  }

  async function saveOld() {
    let VO = true
    valueOld.split("\n").map(o => {
      if (o && !(/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/).test(o)) {
        VO = false;
      }
    })


    if (!VO) {
      message.error("Veuillez rentrer uniquement des url (ex : http://test.fr). Verifier tableau anciens url")
    }

    if (VO) {
      setLoadingSaveOld(true)
      const old = valueOld.split('\n').filter(v => {
        if (filterOld && v.toLowerCase().includes(filterOld.toLowerCase()))
          return false;

        return v;
      }).join('\n');

      if (!redirId) {
        const data = {
          oldValue: old,
          establishment: "/api/establishments/" + params.id
        }
        apiPostEntity("redirections", data, response => {
          setRedirId(response.id)
          setLoadingSaveOld(false)
          message.success('Enregistré !')
          setFilterOld("")
          setValueOld(old)
        })
      } else {
        const data = {
          oldValue: old,
        }
        apiUpdateEntity("redirections", redirId, data, response => {
          setLoadingSaveOld(false)
          message.success('Enregistré !')
          setFilterOld("")
          setValueOld(old)
        })
      }
    }
  }

  return (
    <div>
      <div className="flex end">
        <Button disabled={!valueOld || !valueNew} type="primary" onClick={goStepTwo}>Etape
          suivante</Button>
      </div>
      <div className="margin-10">
        <div className="flex space-between">
          <div className="margin-10">
            Anciennes URL :&nbsp;
            <Upload
              accept=".xml"
              showUploadList={false}
              beforeUpload={beforeUploadOld}
            >
              <Button size="small" type="primary">
                <UploadOutlined/> Upload
              </Button>
            </Upload>&nbsp;
            (XML from <Button type="link" onClick={gotoSitemap}>site map</Button>)
          </div>

          <Space>
            <Input
              value={filterOld}
              onChange={e => setFilterOld(e.target.value)}
              placeholder="Exclure: Ex .pdf"
            />
            <Button
              type="primary"
              onClick={saveOld}
              loading={loadingSaveOld}
            >
              Save
            </Button>

            {filterOld && <div>
              {valueOld.split('\n').filter(v => v.toLowerCase().includes(filterOld.toLowerCase())).length} url
              exclues <ModalOldRemove
              deleted={valueOld.split('\n').filter(v => v.toLowerCase().includes(filterOld.toLowerCase()))}
              saveOld={saveOld}/>
            </div>}
          </Space>
        </div>

        <Input.TextArea
          value={valueOld.split('\n').filter(v => {
            if (filterOld && v.toLowerCase().includes(filterOld.toLowerCase()))
              return false;

            return true;
          }).join('\n')}
          onChange={onOldChange}
          autoSize={{minRows: 25, maxRows: 25}}
          allowClear
        />
      </div>
      <div className="margin-10">
        <div className="margin-10">
          Nouvelles URL :&nbsp;
          <Upload
            accept=".xlsx"
            showUploadList={false}
            beforeUpload={beforeUploadNew}
          >
            <Button size="small" type="primary">
              <UploadOutlined/> Upload
            </Button>
          </Upload>&nbsp;
          (XLSX from <a href="http://hapi.mmcreation.com/admin" target="_blank">Hapi v2</a>) ||&nbsp;
          Remplacer le nom de domaine par&nbsp;
          <Input
            placeholder="example.com / www.example.com"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            style={{width: 250}}
            allowClear
          />&nbsp;
          <Button onClick={replaceDomain}>Remplacer</Button>
        </div>
        <Input.TextArea
          allowClear
          value={valueNew}
          onChange={onNewChange}
          autoSize={{minRows: 25, maxRows: 25}}
        />
      </div>
    </div>
  )
}
